import React from 'react';
import _ from 'lodash';
import { bool, func, string } from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Button } from '../../button/Button';

import styles from './SummaryItem.module.scss';

export const SummaryItem = ({
  bold = false,
  headerText = '',
  headerTextValue = '',
  onEdit,
  priceLabel = '',
}) => {
  const { t } = useTranslation('summary');
  return (
    <div className={styles.container}>
      <div className={cn(styles.itemHeader, bold && styles.bold)}>
        <p>
          <strong>{`${headerText}: `}</strong>
          {`${_.startCase(headerTextValue)}`.replace(' K', 'K')}
        </p>
        <p>{`${priceLabel}`}</p>
      </div>
      {onEdit && (
        <Button className={styles.edit} variant='text' onClick={onEdit}>
          {t('edit')}
        </Button>
      )}
    </div>
  );
};

SummaryItem.propTypes = {
  bold: bool,
  headerText: string,
  headerTextValue: string,
  onEdit: func,
  priceLabel: string,
};

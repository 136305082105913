import { loadSettings } from '../configuration/ConfigurationSettings';

const settings = loadSettings(window.location);
export const WapperFetchPromise = async (methodName, params) => {
  const body = new URLSearchParams();
  const { signal, abort } = new AbortController();
  body.append('response_format', 'json');
  body.append('suppress_response_codes', 'true');

  for (const key of Object.getOwnPropertyNames(params)) {
    if (params[key]) body.append(key, params[key]);
  }

  const fetchOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
      'x-ibm-client-id': settings.wrapperClientId,
    },
    signal,
    body,
  };

  const url = `${settings.wrapperUrl}/${methodName}`;

  const response = await fetch(url.toString(), fetchOptions);
  response.cancel = () => abort();
  const d = await response.json();
  return d[Object.keys(d)[0]];
};

/** mutationFn */
export const updateCompany = async (options = {}) =>
  await WapperFetchPromise('updateLocalCompany', options);

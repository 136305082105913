import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SelectField } from '../../../../components/forms';

export const GenderField = (props) => {
  const { t } = useTranslation();

  const options = [
    { label: t('screens.personal_info.male'), value: 'Male' },
    { label: t('screens.personal_info.female'), value: 'Female' },
  ];

  return (
    <SelectField
      {...props}
      name='gender'
      aria-label='Gender'
      label={t('screens.personal_info.gender')}
      placeholder='Select'
      required
      options={options}
    />
  );
};

GenderField.propTypes = {
  className: string,
};

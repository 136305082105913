import React from 'react';
import { array, element, func } from 'prop-types';

import styles from './ColumnList.module.scss';

export const ColumnList = ({
  data,
  header,
  listEmptyComponent,
  pagination,
  renderItem,
}) => {
  /** determine column width by looping over the header's cells */
  const columnWidths = [];
  if (header) {
    const { children: headerColumns } = header && header.props;
    const columnCount = React.Children.count(headerColumns);
    if (headerColumns) {
      React.Children.forEach(headerColumns, (column) => {
        if (column) {
          const { width } = column.props;
          columnWidths.push(
            width === undefined ? `${100 / columnCount}%` : width
          );
        }
      });
    }
  }

  /** Apply width to each child */
  const applyColumnWidths = (row) => {
    let index = 0;
    return React.cloneElement(row, {
      children: React.Children.map(row.props.children, (cell) => {
        const width = columnWidths[index];
        index += 1;
        if (cell) {
          return React.cloneElement(cell, { style: { width: width || '30%' } });
        }
      }),
    });
  };

  const renderItemWithColumnWidths = (args) => {
    const row = renderItem(args);
    return React.cloneElement(applyColumnWidths(row), {
      className: styles.listItem,
    });
  };

  /** List pagination when data exists */
  const renderPagination = (args) =>
    React.cloneElement(args, { className: styles.pagination });

  /** List Empty component when data is null/undefined */
  const renderListEmpty = () => {
    const hasChildren = React.Children.count(listEmptyComponent) > 0;
    return (
      <div className={styles.emptyContainer}>
        {hasChildren ? listEmptyComponent : <strong>No Results Found.</strong>}
      </div>
    );
  };

  return (
    <>
      {data && (
        <>
          {data.length > 0 && (
            <>
              {pagination && renderPagination(pagination)}
              {applyColumnWidths(header)}
              {data.map(renderItemWithColumnWidths)}
            </>
          )}
          {data.length === 0 && renderListEmpty()}
        </>
      )}
    </>
  );
};

ColumnList.propTypes = {
  data: array,
  header: element,
  listEmptyComponent: element,
  pagination: element,
  renderItem: func,
};

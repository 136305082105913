import React, { useEffect } from 'react';
import { array, object, string } from 'prop-types';
import { Overlay, Fieldset, SelectField } from '../../../../components';
import { singletShirt, singletSizeChart } from '../../../../assets';
import { camelCase, useRegistrationDataProvider } from '../../../../utils';

import styles from './SingletTechField.module.scss';

export const SingletTechField = ({
  mainQuestion = [],
  secondaryQuestion = [],
  overlayCommands,
}) => {
  const [selected, setSelected] = React.useState();
  const {
    data: { personalFormData },
  } = useRegistrationDataProvider();

  const handleChange = (e) => setSelected(e.target.value);

  useEffect(() => {
    const primaryQuestionValue = document.getElementById('singletTech').value;
    if (primaryQuestionValue) setSelected(primaryQuestionValue);
  }, [selected]);

  const showQuestions = (questions, ...rest) =>
    questions.map((question) => (
      <Fieldset className={styles.container} key={question.questionId}>
        {/* TODO: use label field from fieldset component */}
        {question.key === 'Singlet_Tech' ? (
          <label className='label' htmlFor={question.questionText}>
            <span>
              {
                "Once you raise $250, we'll send you either a St. Jude Heroes race day "
              }
            </span>
            {/* TODO: change the place holder */}
            <Overlay
              placeholder='singlet or tech shirt'
              title='Singlet and Tech Shirt'
              overlayCommands={overlayCommands}
              text='Singlet or Tech Shirt Information'
              image={singletShirt}
            />
            <span>
              . Select your preference and size below
              <br />
            </span>
            <span>Singlet or Tech shirt</span>
          </label>
        ) : (
          <label className='label' htmlFor={question.questionText}>
            <span>{'Singlet or Tech Shirt Size '}</span>
            {/* TODO: change the place holder */}
            <Overlay
              placeholder='See sizing'
              title='Singlet and Tech Shirt Sizing'
              overlayCommands={overlayCommands}
              text='Singlet or Tech Shirt Sizing'
              image={singletSizeChart}
            />
          </label>
        )}
        <>
          <SelectField
            {...rest}
            data-testid={question.questionText}
            defaultValue={
              personalFormData && personalFormData[camelCase(question.key)]
            }
            name={camelCase(question.key)}
            onChange={handleChange}
            options={
              question.questionTypeData.surveyQuestionData.availableAnswer
            }
            placeholder='Select'
            required
          />
        </>
      </Fieldset>
    ));

  return !selected ||
    selected === 'I prefer not to receive a singlet or tech shirt.'
    ? showQuestions(mainQuestion)
    : showQuestions([...mainQuestion, ...secondaryQuestion]);
};

SingletTechField.propTypes = {
  overlayCommands: object,
  secondaryQuestion: array,
  mainQuestion: array,
  primaryQuestionKey: string,
};

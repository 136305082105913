import React from 'react';

import { node, number, oneOfType, string } from 'prop-types';

import { useAccordionContext } from './accordion-context';

const AccordionPanel = ({ children, id }) => {
  /** context */
  const { activeTab } = useAccordionContext();
  /** constants */
  const isActive = activeTab === id;
  return <>{isActive && children}</>;
};

AccordionPanel.propTypes = {
  children: node,
  id: oneOfType([number, string]).isRequired,
};

export default AccordionPanel;

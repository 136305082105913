import React from 'react';
import { useHistory } from 'react-router';
import { node } from 'prop-types';
import { useQuery as reactUseQuery } from 'react-query';
import { QueryLoading } from '@frx/frx-components';
import { AppDataContext } from './AppDataContext';
import { ErrorMessage } from '../screens';
import { useQueryString } from './QueryStrings';
import {
  getParticipationDataObject,
  getPartTypeFromCode,
  isEqual,
  stringReplaceAll,
} from './utils';
import { getEventInfo } from './services';
import { useParticipationTypes, useQuery } from './queries';
import { useConfiguration } from './configuration/ConfigurationContext';
import { LOADING_LABEL, ROUTE_KEYS } from '../app.constants';
import { useSessionStorage } from './SessionStorage';

const getParticipationLimits = async (attendance, fr_id, type, url) =>
  await fetch(
    `${url}/SPageServer?pagename=sjmmw_participation_limits&pgwrap=n&reg_type=${type}&fr_id=${fr_id}&attendance=${attendance}`
  )
    .then((res) => res.text())
    .then((results) => {
      if (results) {
        const parsedRes = JSON.parse(results);
        if (parsedRes.errorResponse) {
          return parsedRes.errorResponse;
        }
        return parsedRes.successResponse;
      }
    });

export const AppDataProvider = ({ children }) => {
  const [activeTab, setActiveTab] = React.useState();
  const [kickStartAmount, setKickStartAmount] =
    useSessionStorage('kickStartAmount');
  const [kickStartFieldValue, setKickStartFieldValue] = useSessionStorage(
    'kickStartFieldValue'
  );
  const [discountAmount, setDiscountAmount] =
    useSessionStorage('discountAmount');
  const [discountCode, setDiscountCode] = useSessionStorage('discountCode');
  const [fundraisingGoal, setFundraisingGoal] =
    useSessionStorage('fundraisingGoal');
  const [upsells, setUpsells] = useSessionStorage([]);
  const [transactionId, setTransactionId] = useSessionStorage('transactionId');
  const [teamsFormData, setTeamsFormData] = useSessionStorage('teamsFormData');
  const [joinTeamId, setJoinTeamId] = useSessionStorage('joinTeamId');
  const [bringTeamId, setBringTeamId] = useSessionStorage('bringTeamId');
  const [personalFormData, setPersonalFormData] =
    useSessionStorage('personalFormData');
  const [waiverOption, setWaiverOption] = useSessionStorage('waiverOption');
  const [paymentFormData, setPaymentFormData] =
    useSessionStorage('paymentFormData');
  const [ageConfig, setAgeConfig] = useSessionStorage('ageConfig');
  const { buildPath } = useQueryString();
  const { queryString } = useQueryString();
  const { code, fr_id, reg_type } = queryString;
  const { push } = useHistory();
  const partType = getPartTypeFromCode(code);
  const commitment = queryString.commitment || partType.commitment;
  const attendance = queryString.attendance || partType.attendance;
  const {
    settings: {
      luminate: { url },
    },
  } = useConfiguration();
  const { data, error, loading } = useParticipationTypes({});
  const supporterPartTypeData = useQuery(
    'CRTeamraiserAPI/getParticipationTypes',
    {
      fr_id,
      promotion_code: 'supporter',
    }
  );
  const companyListData = useQuery('CRTeamraiserAPI/getCompanyList', { fr_id });
  const participationLimits = reactUseQuery(
    ['limits', fr_id],
    async () => await getParticipationLimits(attendance, fr_id, reg_type, url),
    reg_type ? { enabled: true } : { enabled: !!attendance }
  );

  const eventInfoData = reactUseQuery(['eventInfo'], () => getEventInfo());
  const queries = [supporterPartTypeData];
  const isQueryLoading = [...queries].some(
    ({ queryResponse }) => queryResponse.loading
  );
  const isQueryError = [...queries].find(
    ({ queryResponse }) => queryResponse.error
  );

  if (loading || isQueryLoading) return <QueryLoading label={LOADING_LABEL} />;
  if ((!data && !supporterPartTypeData.data) || error || isQueryError)
    return <ErrorMessage {...{ error }} />;

  const suporterParticipationType =
    isEqual(code, 'supporter') &&
    supporterPartTypeData.queryResponse.data.participationType;
  const newSupporterParticipationType =
    suporterParticipationType &&
    Object.assign(suporterParticipationType, {
      attendance: '',
      distance: '',
      commitment: '',
    });
  const {
    data: { distanceData, participationData },
  } = getParticipationDataObject(participationLimits, data);

  const selectedParticipationType =
    (commitment &&
      participationData.find((d) =>
        isEqual(d.commitment, stringReplaceAll(commitment, '-', ' '))
      )) ||
    newSupporterParticipationType;

  const companyList =
    companyListData.queryResponse &&
    companyListData.queryResponse.data &&
    companyListData.queryResponse.data.companyItem;

  const eventInfo = eventInfoData.data?.teamraiser;
  const handleSelection = (selection) =>
    push(buildPath(ROUTE_KEYS.PARTICIPATION, selection));

  const resetStorage = () => {
    sessionStorage.clear();
  };

  const context = {
    commands: {
      handleSelection,
      resetStorage,
      setActiveTab,
      setAgeConfig,
      setBringTeamId,
      setDiscountAmount,
      setDiscountCode,
      setFundraisingGoal,
      setJoinTeamId,
      setKickStartAmount,
      setKickStartFieldValue,
      setPaymentFormData,
      setPersonalFormData,
      setTeamsFormData,
      setUpsells,
      setTransactionId,
      setWaiverOption,
    },
    data: {
      activeTab,
      ageConfig,
      bringTeamId,
      companyList,
      discountAmount,
      discountCode,
      distanceData,
      fundraisingGoal,
      eventInfo,
      joinTeamId,
      kickStartAmount,
      kickStartFieldValue,
      participationData,
      participationLimits,
      paymentFormData,
      personalFormData,
      selectedParticipationType,
      teamsFormData,
      upsells,
      transactionId,
      waiverOption,
    },
  };

  return (
    <AppDataContext.Provider value={context}>
      {children}
    </AppDataContext.Provider>
  );
};

AppDataProvider.propTypes = {
  children: node,
};

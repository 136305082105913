import React, { useEffect } from 'react';
import {
  Redirect,
  Route as ReactRoute,
  Switch,
  useLocation,
} from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { Footer, Header } from './components';
import {
  Company,
  Confirmation,
  Contact,
  ContactConfirmation,
  Event,
  Login,
  Options,
  Participation,
  Payment,
  Personal,
  PersonalInfo,
  Search,
  Summary,
  Team,
  TeamOptions,
  Teams,
  Waiver,
} from './screens';
import {
  CompanyPageProvider,
  ContactPageProvider,
  EventPageProvider,
  getPartTypeFromCode,
  isEqual,
  PersonalPageProvider,
  setEvent,
  setPageFromLocation,
  TeamPageProvider,
  useQueryString,
  useRegistrationDataProvider,
} from './utils';
import SecureRoute from './utils/security/SecureRoute';
import { REG_TYPES, ROUTE_KEYS } from './app.constants';
import { getRegistrationStep } from './utils/analytics/ManageAuditValues';

import styles from './App.module.scss';

const Route = Sentry.withSentryRouting(ReactRoute);

const App = () => {
  const { queryString, buildPath, buildCleanPath } = useQueryString();
  const { pagename, fr_id, pg, reg_type, code } = queryString;
  const { commitment } = getPartTypeFromCode(code);
  const location = useLocation();
  const registrationData = useRegistrationDataProvider();

  useEffect(() => {
    setPageFromLocation(location, queryString, registrationData);
  }, [location, queryString, registrationData]);

  useEffect(() => {
    setEvent({ eventName: 'spa-change' });
    setEvent({
      eventName: 'event-registration',
      eventAction: getRegistrationStep(),
    });
  }, [location]);

  if (code) {
    const hasHeroes = isEqual(reg_type, REG_TYPES.HEROES);
    const hasGen = isEqual(commitment, REG_TYPES.GENERAL);
    if (!hasHeroes && !hasGen) {
      return (
        <Redirect
          to={buildCleanPath(`/${ROUTE_KEYS.PARTICIPATION}`, {
            pagename,
            fr_id,
            reg_type: REG_TYPES.HEROES,
          })}
        />
      );
    }
    if (hasHeroes && hasGen) {
      return (
        <Redirect
          to={buildCleanPath(`/${ROUTE_KEYS.PARTICIPATION}`, {
            pagename,
            fr_id,
          })}
        />
      );
    }
  }

  return (
    <div className={styles.container}>
      <Header />
      <Switch>
        <Route path={`/${ROUTE_KEYS.PARTICIPATION}`}>
          <Participation showSidebar={false} />
        </Route>
        <Route path={`/${ROUTE_KEYS.LOGIN}`}>
          <Login />
        </Route>
        <SecureRoute path={`/${ROUTE_KEYS.TEAM}`}>
          <Teams showSidebar />
        </SecureRoute>
        <SecureRoute path={`/${ROUTE_KEYS.TEAM_OPTIONS}`}>
          <TeamOptions showSidebar />
        </SecureRoute>
        <SecureRoute path={`/${ROUTE_KEYS.OPTIONS}`}>
          <Options showSidebar />
        </SecureRoute>
        <SecureRoute path={`/${ROUTE_KEYS.PERSONAL}`}>
          <PersonalInfo showSidebar />
        </SecureRoute>
        <SecureRoute path={`/${ROUTE_KEYS.WAIVER}`}>
          <Waiver showSidebar />
        </SecureRoute>
        <SecureRoute path={`/${ROUTE_KEYS.SUMMARY}`}>
          <Summary showSidebar />
        </SecureRoute>
        <SecureRoute path={`/${ROUTE_KEYS.PAYMENT}`}>
          <Payment showSidebar />
        </SecureRoute>
        <Route path={`/${ROUTE_KEYS.SEARCH}`}>
          <Search />
        </Route>
        {isEqual(pg, 'personal') && (
          <PersonalPageProvider>
            <Personal />
          </PersonalPageProvider>
        )}

        {isEqual(pg, 'team') && (
          <TeamPageProvider>
            <Team />
          </TeamPageProvider>
        )}

        {isEqual(pg, 'company') && (
          <CompanyPageProvider>
            <Company />
          </CompanyPageProvider>
        )}

        {isEqual(pg, 'entry') && (
          <EventPageProvider>
            <Event />
          </EventPageProvider>
        )}

        {isEqual(pg, 'rthanks') && (
          <SecureRoute>
            <Confirmation />
          </SecureRoute>
        )}

        <ContactPageProvider>
          <Route path='/contact'>
            <Contact />
          </Route>
          <Route path='/contact-confirmation'>
            <ContactConfirmation />
          </Route>
        </ContactPageProvider>
        <Route path='/'>
          <Redirect to={buildPath(`/${ROUTE_KEYS.PARTICIPATION}`)} />
        </Route>
      </Switch>
      <Footer />
    </div>
  );
};

export default App;

import React from 'react';
import { bool, node, oneOfType, string } from 'prop-types';
import cn from 'classnames';
import { Icon } from '@frx/frx-components';
import styles from './CheckboxField.module.scss';
import { colors } from '../../styles';

export const CheckboxField = ({
  className,
  defaultChecked,
  description,
  header,
  label,
  name,
  ...rest
}) => (
  <div className={cn(styles.container, className)}>
    {header && (
      <label className={styles.header} htmlFor={name}>
        {header}
      </label>
    )}
    {name === 'qkeyCpaAge' && (
      <Icon color={colors.additional.errorRed} name='asterisk' size={8} />
    )}
    <input
      {...rest}
      {...{ defaultChecked, id: name, name, type: 'checkbox' }}
    />

    {label && <label htmlFor={name}>{label}</label>}

    {description && <p>{description}</p>}
  </div>
);

CheckboxField.propTypes = {
  className: string,
  defaultChecked: bool,
  description: string,
  header: string,
  label: oneOfType([string, node]),
  name: string,
};

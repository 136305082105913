import React, { useCallback, useEffect } from 'react';
import { node } from 'prop-types';
import _ from 'lodash';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { SecurityContext } from './SecurityContext';
import { useConfiguration } from '../configuration/ConfigurationContext';
import { useLocalStorage } from '../SessionStorage';
import { formatPhoneNumber, getAddressObject, getDateOfBirth } from '../utils';
import { useInterval } from '../hooks';
import { setEvent } from '../analytics';

const CRCONSAPI = 'CRConsAPI';
const CRCONTENTAPI = 'CRContentAPI';
const CRTEAMRAISERAPI = 'CRTeamraiserAPI';

/** TODO: move/optimize all the logic to respected files */
export const createApiFetcher = (configuration) => async (query, params) => {
  const [queryName, methodName] = query.split('/');

  const body = new URLSearchParams();
  body.append('v', '1.0');
  body.append('api_key', 'alsacdev');
  body.append('response_format', 'json');
  body.append(
    's_locale',
    configuration.settings.luminate.properties.user.locale
  );
  body.append('method', methodName);

  for (const key of Object.getOwnPropertyNames(params)) {
    body.append(key, params[key]);
  }

  const options = {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    credentials: 'include',
    body,
  };

  const url = `${configuration.settings.luminate.url}/${queryName}`;

  const r = await fetch(url, options);
  const d = await r.json();
  return d[Object.keys(d)[0]];
};

export const SecurityProvider = (props) => {
  const [user, setUser] = useLocalStorage('sjmmw-user');
  const [loginErrorMessage, setLoginErrorMessage] = React.useState();
  const [signupErrorMessage, setSignupErrorMessage] = React.useState();
  const [tokenizeInfo, setTokenizeInfo] = React.useState({
    authToken: '',
    jSessionId: '',
    routingId: '',
  });

  const configuration = useConfiguration();
  const { t } = useTranslation(['login']);
  const { fr_id } = configuration.settings.appSearchCriteria;
  const {
    properties: {
      user: { locale },
    },
  } = configuration.settings.luminate;
  const fetchApi = createApiFetcher(configuration);

  const loginUrl = React.useCallback(
    async () =>
      await fetchApi(`${CRCONSAPI}/getLoginUrl`, {
        withCredentials: true,
      }),
    [fetchApi]
  );

  const loginTest = React.useCallback(
    async () =>
      await fetchApi(`${CRCONSAPI}/loginTest`, {
        suppress_response_codes: true,
      }),
    [fetchApi]
  );

  const getUser = React.useCallback(
    async (consId, token, fields) =>
      await fetchApi(`${CRCONSAPI}/getUser`, {
        cons_id: consId,
        auth: token,
        fields,
      }),
    [fetchApi]
  );

  const getSession = useCallback(async () => {
    try {
      /** getting the token stuff */
      const loginUrlRes = await loginUrl();
      const { token } = loginUrlRes;
      /** checking logging persists */
      const loginTestRes = await loginTest();

      /** getting user info of logged in user */
      if (loginTestRes.cons_id) {
        const getUserRes = await getUser(
          loginTestRes.cons_id,
          token,
          'name.first, name.last, user_name, birth_date, gender, primary_address.street1, primary_address.street2, primary_address.street3, primary_address.city, primary_address.state, primary_address.zip, primary_address.country, email, home_phone'
        );
        const {
          birth_date,
          email,
          gender,
          home_phone,
          name,
          primary_address,
          user_name,
        } = getUserRes;

        setUser((prevState) => ({
          ...prevState,
          consId: loginTestRes.cons_id,
          dateOfBirth: getDateOfBirth(
            birth_date || '',
            locale.replace('_', '-')
          ),
          gender,
          address: getAddressObject(primary_address),
          firstName: name.first,
          lastName: name.last,
          userName: user_name,
          email: email.primary_address,
          phone: formatPhoneNumber(home_phone || ''),
        }));
      }
    } catch (error) {
      console.error(error);
    }
  }, [getUser, locale, loginTest, loginUrl, setUser]);

  const updateUser = async (newEmail) => {
    const updateConsResponse = await fetchApi(`${CRCONSAPI}/update`, {
      auth: user?.token,
      cons_id: user?.consId,
      primary_email: newEmail,
    });

    if (updateConsResponse.cons_id) {
      setUser((prevState) => ({
        ...prevState,
        email: newEmail,
      }));
    }

    return updateConsResponse;
  };

  useEffect(() => {
    getSession();
    // TODO: can't add dependencies, since only need this to be on first render
    // eslint-disable-next-line
  }, []);

  useInterval(() => loginUrl(), 10 * 60 * 1000);

  const forgotLoginInfo = async (recoverEmail) => {
    try {
      return await fetchApi(`${CRCONSAPI}/login`, {
        send_user_name: true,
        email: recoverEmail,
      });
    } catch (err) {
      console.error(err);
    }
  };

  const login = async (userName, password) => {
    let registered;
    let currentTeamId;
    let currentTeamName;
    try {
      const loginResponse = await fetchApi(`${CRCONSAPI}/login`, {
        user_name: userName,
        password,
      });

      /** getting the token stuff */
      const loginUrlRes = await loginUrl();

      if (!loginResponse.code) {
        setEvent({ eventName: 'login', eventAction: 'success' });

        const { cons_id, JSESSIONID, routing_id } = loginResponse;
        const { token } = loginUrlRes;

        const getRegistration = await fetchApi(
          `${CRTEAMRAISERAPI}/getRegistration`,
          {
            fr_id,
            auth: token,
          }
        );

        if (!getRegistration.code) {
          registered = true;
        }

        const getUserResponse = await fetchApi(`${CRCONSAPI}/getUser`, {
          cons_id,
          auth: token,
          fields:
            'name.first, name.last, user_name, birth_date, gender, primary_address.street1, primary_address.street2, primary_address.street3, primary_address.city, primary_address.state, primary_address.zip, primary_address.country, email, home_phone',
        });

        const {
          birth_date,
          email,
          gender,
          home_phone,
          name,
          primary_address,
          user_name,
        } = getUserResponse;

        const getTagInfoResponse = await fetchApi(
          `${CRCONTENTAPI}/getTagInfo`,
          {
            content: JSON.stringify({
              teamId: `[[E48:[[S42:${fr_id}:prev-fr-id]]:team-id]]`,
              teamName: `[[E43:[[S42:${fr_id}:prev-fr-id]]:name]]`,
            }),
            auth: token,
          }
        );

        const { preview } = getTagInfoResponse;
        const parsePreview = JSON.parse(preview);

        const getCurrentTeamInfo =
          parsePreview.teamId &&
          (await fetchApi(`${CRTEAMRAISERAPI}/getTeamsByInfo`, {
            fr_id,
            list_filter_column: 'previous_team_id',
            list_filter_text: parsePreview.teamId,
          }));

        if (!_.isEmpty(getCurrentTeamInfo)) {
          const { team, totalNumberResults } = getCurrentTeamInfo;
          if (Number(totalNumberResults) > 0) {
            if (Number(team.captainConsId) > 0) {
              currentTeamId = team.id;
              currentTeamName = team.name;
            }
          }
        }

        const authenticatedUser = {
          address: getAddressObject(primary_address),
          consId: cons_id,
          currentTeamInfo: { currentTeamId, currentTeamName },
          dateOfBirth: getDateOfBirth(
            birth_date || '',
            locale.replace('_', '-')
          ),
          email: email.primary_address,
          firstName: name.first,
          gender,
          lastName: name.last,
          phone: formatPhoneNumber(home_phone || ''),
          prevTeamInfo: parsePreview,
          registered,
          token,
          userName: user_name,
        };
        setUser(authenticatedUser);
        setTokenizeInfo({
          authToken: token,
          jSessionId: JSESSIONID,
          routingId: routing_id,
        });
      } else {
        const path = `login:login.errors.${loginResponse.code}`;
        setLoginErrorMessage(
          i18n.exists(path) ? t(path) : t('login:login.errors.generic')
        );
        setEvent({
          eventName: 'login',
          eventAction: `failure - ${loginResponse.message}`,
        });
      }
      return loginResponse;
    } catch (error) {
      console.error(error);
      setEvent({ eventName: 'login', eventAction: 'failure' });
    }
  };

  const logout = () =>
    fetchApi(`${CRCONSAPI}/logout`, {})
      .then((response) => {
        console.log(response.message);
        sessionStorage.clear();
        localStorage.clear();
      })
      .catch((e) => console.error(e));

  const signup = async (data) => {
    const userData = {
      'name.first': data.firstName,
      'name.last': data.lastName,
      user_password: data.password,
      primary_email: data.email,
      user_name: data.userName,
      'primary_address.country': data.country,
      'email.accepts_email': data.emailOptIn,
    };

    try {
      const loginTestRes = await loginTest();
      if (loginTestRes.cons_id)
        setSignupErrorMessage(t('login:signup.errors.already_logged_in'));

      const isUserNameAvailableResponse = await fetchApi(
        `${CRCONSAPI}/isUserNameAvailable`,
        {
          user_name: data.userName,
        }
      );

      if (
        !isUserNameAvailableResponse.available &&
        isUserNameAvailableResponse.code
      ) {
        setSignupErrorMessage(
          t(`login:signup.errors.${isUserNameAvailableResponse.code}`)
        );
        setEvent({
          eventName: 'create account',
          eventAction: 'failure - Username invalid',
        });
      }

      if (isUserNameAvailableResponse.available === 'false') {
        setSignupErrorMessage(t('login:signup.errors.username_exists'));
        setEvent({
          eventName: 'create account',
          eventAction: 'failure - Username already exists',
        });
      }

      if (isUserNameAvailableResponse.available === 'true') {
        const signupResponse = await fetchApi(`${CRCONSAPI}/create`, userData);

        if (signupResponse.code === '11') {
          setSignupErrorMessage(t('login:signup.errors.email_exists'));
          setEvent({
            eventName: 'create account',
            eventAction: 'failure - Email already exists',
          });
        }

        if (signupResponse.code && signupResponse.code !== '11') {
          setSignupErrorMessage(t('login:signup.errors.generic'));
          setEvent({
            eventName: 'create account',
            eventAction: `failure - ${signupResponse.message}`,
          });
        }

        if (!signupResponse.code && signupResponse.cons_id) {
          setEvent({ eventName: 'create account', eventAction: 'success' });
          return true;
        }
        setEvent({ eventName: 'create account', eventAction: 'failure' });
        return false;
      }
    } catch (err) {
      console.error(err);
      setEvent({ eventName: 'create account', eventAction: 'failure' });
    }
  };

  const context = {
    fetchApi,
    forgotLoginInfo,
    login,
    loginErrorMessage,
    logout,
    setLoginErrorMessage,
    setSignupErrorMessage,
    signup,
    signupErrorMessage,
    tokenizeInfo,
    updateUser,
    user,
  };

  return (
    <SecurityContext.Provider value={context}>
      {props.children}
    </SecurityContext.Provider>
  );
};

SecurityProvider.propTypes = {
  children: node,
};

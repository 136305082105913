import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { InputField } from '../../../components';

import styles from './NameAppearance.module.scss';

export const NameAppearance = ({ constituentName = '' }) => {
  const [hidden, setHidden] = useState(false);

  return (
    <div className={styles.container}>
      <p>
        Select your preference for what name appears on your webpage and in
        search results.
      </p>
      <label>
        <input
          type='radio'
          name='nameAppearance'
          defaultValue='default'
          onClick={() => setHidden(false)}
        />
        <span>I would like my name to appear as</span>
        <span className={styles.constituentName}>
          {constituentName || 'Joe Smith'}
        </span>
      </label>
      <label>
        <input
          type='radio'
          name='nameAppearance'
          defaultValue='personalized'
          onClick={() => setHidden(true)}
        />
        <span>I would like to use a different name</span>
      </label>
      {hidden && (
        <InputField
          type='text'
          name='nameAppearanceAlternate'
          label='Alternate Name'
          required
          minLength='2'
          maxLength='50'
          size='20'
        />
      )}
    </div>
  );
};

NameAppearance.propTypes = {
  constituentName: PropTypes.string,
};

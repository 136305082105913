import React, { useState } from 'react';
import { bool } from 'prop-types';
import { isEqual } from 'lodash';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  AddressField,
  DateOfBirthField,
  GenderField,
  PhoneField,
} from './questions';
import {
  Button,
  ButtonsBar,
  ErrorMessage,
  Form,
  InputField,
  RegistrationProgress,
  Screen,
  StepHeader,
} from '../../components';
import { ROUTE_KEYS } from '../../app.constants';
import {
  camelCase,
  getIndexForMonth,
  isCountryUSA,
  useAuth,
  useConfiguration,
  useQueryString,
  useRegistrationDataProvider,
  useSubmitRegistration,
} from '../../utils';

import styles from './PersonalInfo.module.scss';
import { RegQuestions } from './questions/RegQuestions';
import { QUESTION_KEYS } from './questions/questions';

export const PersonalInfo = ({ showSidebar }) => {
  const [, setHasFormErrors] = useState(true);
  const [resErrorIndex, setResErrorIndex] = useState();
  const [errorMessage, setErrorMessage] = useState();
  const [errText, setErrorText] = useState();

  const {
    queryString: { code, fr_id },
    redirect,
  } = useQueryString();
  const {
    settings: {
      luminate: {
        properties: { questionPrefix },
      },
    },
  } = useConfiguration();
  const { postStep } = useSubmitRegistration();
  const {
    commands: { setPersonalFormData },
    data: {
      ageConfig,
      selectedParticipationType,
      personalFormData,
      transactionId,
    },
  } = useRegistrationDataProvider();

  const { goBack } = useHistory();
  const {
    user: { email, firstName, gender, lastName, phone },
    logout,
  } = useAuth();
  const { t } = useTranslation();

  const handleFormChange = (fields, hasErrors) => {
    setPersonalFormData(fields);
    setHasFormErrors(hasErrors);
  };

  const handleFormSubmit = async () => {
    const questionsData = selectedParticipationType?.surveyQuestion
      .filter(({ key }) =>
        Object.keys(personalFormData).some((formKey) =>
          isEqual(camelCase(key), formKey)
        )
      )
      .reduce(
        (acc, item) => ({
          ...acc,
          [`${questionPrefix}_${item.surveyInstanceId}_${item.questionOrderNumber}_${item.questionId}`]:
            /* [`${questionPrefix}_${item.questionOrderNumber}_${item.questionId}`]: */
            personalFormData[camelCase(item.key)],
        }),
        {}
      );

    /* We are using a TR question of type Multiple Choice/ Multiple response to represent a single checkbox
     * By default a checkbox has a true/false value, we want to replace this with the verbiage that is next to the checkbox
     */

    const CPA_AGE = selectedParticipationType.surveyQuestion.find(
      (q) => q.key === QUESTION_KEYS.AGE
    );

    if (CPA_AGE) {
      questionsData[
        `${questionPrefix}_${CPA_AGE.surveyInstanceId}_${CPA_AGE.questionOrderNumber}_${CPA_AGE.questionId}`
      ] = CPA_AGE.questionTypeData.surveyQuestionData.availableAnswer.label;
    }

    await postStep(transactionId, {
      pg: 'reg',
      fr_id,
      fr_cstm_reg: 't',
      cons_info_component: 't',
      cons_first_name: firstName,
      cons_last_name: lastName,
      cons_birth_date_MONTH: getIndexForMonth(personalFormData.month),
      cons_birth_date_DAY: personalFormData.day,
      cons_birth_date_YEAR: personalFormData.year,
      cons_gender: personalFormData.gender,
      cons_street1: personalFormData.address1,
      cons_street2: personalFormData.address2 || '',
      cons_street3: personalFormData.address3 || '',
      cons_city: personalFormData.city,
      cons_state: personalFormData.state,
      cons_zip_code: personalFormData.zip,
      cons_country: personalFormData.country,
      cons_phone: personalFormData.homePhone,
      cons_email: personalFormData.email,
      fr_emergency_name: personalFormData.emergencyContactName,
      fr_emergency_phone: personalFormData.emergencyContactPhone,
      fr_email_opt_in: 'on',
      cons_mail_opt_in: 't',
      cons_email_format_requested: 'true',
      cons_email_format: '1',
      fr_privacy_options_radio: 'fr_privacy_options_radio_0',
      fr_privacy_options_radiosubmit: 'true',
      skip_login_page: true,
      ...questionsData,
      'next_step.x': 'Next Step',
      denySubmit: '',
    }).then((res) => {
      const parseText = new DOMParser().parseFromString(res, 'text/html');
      const errors = parseText.querySelector('.ErrorMessage.page-error');
      if (errors?.textContent) {
        const errorIndex = parseText.querySelectorAll(
          '.survey-question-container.form-error .survey-question-number'
        );
        if (errorIndex.length >= 1) {
          let finalErrorText = '';
          errorIndex.forEach((error) => {
            let errorQuestion = error.innerText;
            errorQuestion = errorQuestion.replace(/(\r\n|\n|\r)/gm, '');
            if (
              errorQuestion !== null ||
              (typeof errorQuestion === 'string' &&
                errorQuestion.trim().length !== 0)
            ) {
              const txt = `Error: Please correct the errors highlighted below.`;
              finalErrorText =
                finalErrorText !== '' ? `${finalErrorText} - ${txt}` : `${txt}`;
              console.log(
                `Error: Please correct the errors highlighted below-Q${errorQuestion}`
              );
            } else {
              const txt = `Sorry, there was a problem submitting your registration. Please log out and log back in to resolve this issue.`;
              finalErrorText =
                finalErrorText !== '' ? `${finalErrorText} - ${txt}` : `${txt}`;
            }
          });
          setErrorText(finalErrorText);
        }
        setResErrorIndex(
          [...errorIndex].map((e) => e.textContent.replace('.', ''))
        );
        setErrorMessage(errors);
        scroll(0, 0);
      } else {
        redirect(`${ROUTE_KEYS.WAIVER}`);
      }
    });
  };

  if (code && code === 'invalid_code') {
    return <h1>Invalid promotion code</h1>;
  }

  const questions = selectedParticipationType.surveyQuestion.sort(
    (a, b) => a.questionOrderNumber - b.questionOrderNumber
  );

  const handleLogout = () =>
    logout().then(() => {
      setPersonalFormData();
      location.reload();
    });

  const countryUSA = isCountryUSA(personalFormData && personalFormData.country);

  return (
    <Screen className={styles.container} showSidebar={showSidebar}>
      <StepHeader />

      <RegistrationProgress value={99} />

      <h2>{t('screens.personal_info.provide_personal_info')}:</h2>

      <section className={styles.userInfo}>
        <div>
          {firstName} {lastName}
        </div>
        <div className='is-flex'>
          {t('screens.personal_info.is_your_name')}{' '}
          <Button className='p-0' variant='text' onClick={handleLogout}>
            {t('screens.personal_info.log_out')}
          </Button>
        </div>
      </section>

      {errorMessage && <ErrorMessage errorText={errText} />}

      <Form onChange={handleFormChange} onSubmit={handleFormSubmit}>
        <DateOfBirthField className={styles.dob} />
        <GenderField
          className={styles.gender}
          defaultValue={(personalFormData && personalFormData.gender) || gender}
        />
        <AddressField className={styles.general} formData={personalFormData} />
        <InputField
          label='Email'
          name='email'
          type='email'
          defaultValue={(personalFormData && personalFormData.email) || email}
          className={styles.email}
          required
        />
        <PhoneField
          className={styles.phone}
          countryUSA={countryUSA}
          defaultValue={
            (personalFormData && personalFormData.homePhone) || phone
          }
          label={t('screens.personal_info.phone')}
          name='homePhone'
          required
        />
        {/* Remove hardcoded emergency fields and use LO survey ones */}
        {/* <InputField
          label={t('screens.personal_info.emerg_contact_name')}
          name='emergencyContactName'
          defaultValue={
            personalFormData && personalFormData.emergencyContactName
          }
          className={styles.email}
          required
        />
        <PhoneField
          {...{
            className: styles.phone,
            countryUSA,
            defaultValue:
              personalFormData && personalFormData.emergencyContactPhone,
            label: t('screens.personal_info.emerg_contact_phone'),
            name: 'emergencyContactPhone',
          }}
          required
        /> */}
        <RegQuestions
          regQuestions={questions}
          errorIndex={resErrorIndex}
          personalFormData={personalFormData}
        />
        <div className={styles.actions}>
          <ButtonsBar
            disabled={ageConfig && !ageConfig.hasValidBirthDate}
            handleSecondary={() => goBack()}
          />
        </div>
      </Form>
    </Screen>
  );
};

PersonalInfo.propTypes = {
  showSidebar: bool,
};

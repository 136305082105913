import { createContext, useContext } from 'react';

const defaultContext = {
  user: {
    address: {
      city: undefined,
      country: undefined,
      state: undefined,
      street1: undefined,
      zip: undefined,
    },
    currentTeamInfo: {
      currentTeamId: undefined,
      currentTeamName: undefined,
    },
    dateOfBirth: {
      day: undefined,
      month: undefined,
      year: undefined,
    },
    email: undefined,
    firstName: undefined,
    gender: undefined,
    lastName: undefined,
    phone: undefined,
    prevTeamInfo: {
      teamId: undefined,
      teamName: undefined,
    },
    registered: undefined,
    userName: undefined,
  },
  authToken: undefined,
  forgotLoginInfo: () => undefined,
  jsessionId: undefined,
  login: () => undefined,
  routingId: undefined,
  tokenizeInfo: {
    authToken: '',
    jSessionId: '',
    routingId: '',
  },
  contentInfo: undefined,
  updateUser: () => undefined,
};

export const SecurityContext = createContext(defaultContext);

export function useAuth() {
  const context = useContext(SecurityContext);

  if (!context) throw new Error('useAuth must be within a SecurityProvider');

  return context;
}

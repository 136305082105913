import React from 'react';
import cn from 'classnames';
import { instanceOf, node, string } from 'prop-types';

import styles from './ColumnList.module.scss';

export const ListRow = ({
  children,
  className,
  variant,
  cellcolor,
  ...rest
}) => {
  const applyVariant = (cells) => {
    if (cells === undefined) return cells;
    return React.Children.map(cells, (cell) =>
      React.cloneElement(cell, { variant, cellcolor })
    );
  };

  return (
    <div
      className={cn(
        styles.row,
        variant === 'header' && styles.header,
        className
      )}
      {...rest}
    >
      {applyVariant(children)}
    </div>
  );
};

ListRow.propTypes = {
  ...instanceOf(HTMLDivElement).isRequired,
  children: node,
  className: string,
  variant: string,
};

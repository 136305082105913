import { camelCase } from '../../../utils';

const upsells = {
  itab: require('./personalize-medal.jpg'),
  '20thAnniversaryPack': require('./anniversary-pack.jpg'),
};

const upsellLabels = {
  itab: 'Personaliza tu medalla con un iTab:',
  '20thAnniversaryPack': 'Paquete temático del 20 Aniversario:',
};

const upsellDescriptions = {
  itab: 'Un iTab te permite personalizar tu medalla con tu nombre y tu tiempo. La especial y personalizada placa se coloca en la parte trasera de tu medalla para conmemorar tu experiencia en la carrera',
  '20thAnniversaryPack':
    'Incluye una camiseta soft-style, broches de fácil instalación y calcomanía de vinil',
};

const upsellNames = {
  itab: 'iTab',
  '20thAnniversaryPack': 'Paquete temático del 20 Aniversario',
};

export const getUpsellImage = (upsellName) => {
  const upsellImage = upsells[camelCase(upsellName)];
  return upsellImage;
};

export const getUpsellLabel = (upsellName) => {
  const upsellLabel = upsellLabels[camelCase(upsellName)];
  return upsellLabel;
};

export const getUpsellDescription = (upsellName) => {
  const upsellDescription = upsellDescriptions[camelCase(upsellName)];
  return upsellDescription;
};

export const getUpsellName = (upsellName) => {
  const upsellSpanishName = upsellNames[camelCase(upsellName)];
  return upsellSpanishName;
};

import React from 'react';
import { string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SelectField } from '../../../../../components';

const states = [
  { value: 'AK', label: 'AK' },
  { value: 'AL', label: 'AL' },
  { value: 'AR', label: 'AR' },
  { value: 'AZ', label: 'AZ' },
  { value: 'CA', label: 'CA' },
  { value: 'CO', label: 'CO' },
  { value: 'CT', label: 'CT' },
  { value: 'DC', label: 'DC' },
  { value: 'DE', label: 'DE' },
  { value: 'FL', label: 'FL' },
  { value: 'GA', label: 'GA' },
  { value: 'HI', label: 'HI' },
  { value: 'IA', label: 'IA' },
  { value: 'ID', label: 'ID' },
  { value: 'IL', label: 'IL' },
  { value: 'IN', label: 'IN' },
  { value: 'KS', label: 'KS' },
  { value: 'KY', label: 'KY' },
  { value: 'LA', label: 'LA' },
  { value: 'MA', label: 'MA' },
  { value: 'MD', label: 'MD' },
  { value: 'ME', label: 'ME' },
  { value: 'MI', label: 'MI' },
  { value: 'MN', label: 'MN' },
  { value: 'MO', label: 'MO' },
  { value: 'MS', label: 'MS' },
  { value: 'MT', label: 'MT' },
  { value: 'NC', label: 'NC' },
  { value: 'ND', label: 'ND' },
  { value: 'NE', label: 'NE' },
  { value: 'NH', label: 'NH' },
  { value: 'NJ', label: 'NJ' },
  { value: 'NM', label: 'NM' },
  { value: 'NV', label: 'NV' },
  { value: 'NY', label: 'NY' },
  { value: 'OH', label: 'OH' },
  { value: 'OK', label: 'OK' },
  { value: 'OR', label: 'OR' },
  { value: 'PA', label: 'PA' },
  { value: 'RI', label: 'RI' },
  { value: 'SC', label: 'SC' },
  { value: 'SD', label: 'SD' },
  { value: 'TN', label: 'TN' },
  { value: 'TX', label: 'TX' },
  { value: 'UT', label: 'UT' },
  { value: 'VA', label: 'VA' },
  { value: 'VT', label: 'VT' },
  { value: 'WA', label: 'WA' },
  { value: 'WI', label: 'WI' },
  { value: 'WV', label: 'WV' },
  { value: 'WY', label: 'WY' },
  { value: 'AS', label: 'AS' },
  { value: 'FM', label: 'FM' },
  { value: 'GU', label: 'GU' },
  { value: 'MH', label: 'MH' },
  { value: 'MP', label: 'MP' },
  { value: 'PR', label: 'PR' },
  { value: 'PW', label: 'PW' },
  { value: 'VI', label: 'VI' },
  { value: 'AA', label: 'AA' },
  { value: 'AE', label: 'AE' },
  { value: 'AP', label: 'AP' },
  { value: 'AB', label: 'AB' },
  { value: 'BC', label: 'BC' },
  { value: 'MB', label: 'MB' },
  { value: 'NB', label: 'NB' },
  { value: 'NL', label: 'NL' },
  { value: 'NS', label: 'NS' },
  { value: 'NT', label: 'NT' },
  { value: 'NU', label: 'NU' },
  { value: 'ON', label: 'ON' },
  { value: 'PE', label: 'PE' },
  { value: 'QC', label: 'QC' },
  { value: 'SK', label: 'SK' },
  { value: 'YT', label: 'YT' },
  { value: 'None', label: 'None' },
];

export const AddressStateField = (props) => {
  const { t } = useTranslation();
  return (
    <SelectField
      {...props}
      defaultValue={props.defaultValue}
      label={t('screens.personal_info.state')}
      options={states}
      placeholder={t('screens.personal_info.select')}
    />
  );
};

AddressStateField.propTypes = {
  className: string,
  defaultValue: string,
  name: string,
};

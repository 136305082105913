import React from 'react';

const defaultContext = {
  commands: {
    handleChangeHeroImageIndex: () => undefined,
    handleGoalCancel: () => undefined,
    handleGoalModal: () => undefined,
    handleHeroImageCancel: () => undefined,
    handleHeroImageModalShow: () => undefined,
    handleImageCancel: () => undefined,
    handleImageModal: () => undefined,
    handleRichTextCancel: () => undefined,
    handleRichTextModal: () => undefined,
    handleSetHeroImageModal: () => undefined,
    handleUpdateGoal: () => undefined,
    handleUpdateImage: () => undefined,
    handleUpdateRichText: () => undefined,
    setHeroImageIndex: () => undefined,
  },
  data: {
    achievements: [],
    editGoalModal: false,
    editHeroImageModal: false,
    editImageModal: false,
    editRichTextModal: false,
    eventDetails: {
      eventAddress: undefined,
      eventDate: undefined,
    },
    eventInfo: undefined,
    heroImageBackground: undefined,
    heroImageIndex: undefined,
    heroImagesArr: [],
    ownPage: false,
    participant: {},
    participantProgress: {},
    personalPageInfo: {},
    profileImage: {},
    topDonors: {},
    updateError: undefined,
    donationArrayData: {
      donationArray: [],
      minimumAmount: undefined,
    },
  },
};
export const PersonalPageContext = React.createContext(defaultContext);

export const usePersonalPageContext = () => {
  const context = React.useContext(PersonalPageContext);

  if (context === undefined) {
    throw new Error(
      'usePersonalPageContext must be within an CacheQueryProvider'
    );
  }

  return context;
};

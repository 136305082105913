import React from 'react';
import PropTypes from 'prop-types';

const ConfigurationContext = React.createContext(undefined);

class Configuration {
  constructor(settings) {
    this.settings = settings;
  }

  hasFeature(name) {
    return (this.settings.features && this.settings.features[name]) || false;
  }

  getValue(name) {
    return this.settings[name] || undefined;
  }
}

export const ConfigurationProvider = (props) => {
  const configuration = new Configuration(props.settings || {});

  return (
    <ConfigurationContext.Provider value={configuration}>
      {props.children}
    </ConfigurationContext.Provider>
  );
};

ConfigurationProvider.propTypes = {
  children: PropTypes.node,
  settings: PropTypes.object,
};

export const useConfiguration = function () {
  const context = React.useContext(ConfigurationContext);

  if (context === undefined)
    throw new Error(
      'useConfiguration must be with in an ConfigurationProvider'
    );

  return context;
};

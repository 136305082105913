/* istanbul ignore file: these are tested end-to-end */
/* eslint-disable no-undef */

import { LANGUAGE_CODES } from '../../app.constants';
import { gen_promocodes, heroes_promocodes } from '../queries/promocodes';
import { decodeQueryString } from '../QueryStrings';
import { isLocalHost } from '../utils';
import { stjude } from '../analytics';

export const loadSettings = () => {
  const { fr_id, pagename } = decodeQueryString(location.search);
  const sessionUser = localStorage.getItem('sjmmw-user');
  const user =
    sessionUser !== 'undefined' &&
    typeof sessionUser === 'string' &&
    JSON.parse(sessionUser);

  const settings = {
    appSearchCriteria: { fr_id, pagename },
    luminate: {
      apiKey: 'alsacdev',
    },
    baseUrl: '/',
    features: {
      'login-v2': false,
    },
    gen_promocodes,
    heroes_promocodes,
  };
  if (isLocalHost) {
    // Keep this set to /site so the proxy will route local requests
    window.stjude = stjude;
    settings.luminate.url = '/site';
    settings.devMode = true;
    settings.environment = 'local';
    settings.appSearchCriteria.pagename = 'sjmmw';
    settings.luminate.properties = {
      fivePlusDonations: 'false',
      formId: '3089',
      halfwayToGoal: 'false',
      kickStartDonor: 'false',
      questionPrefix: '954',
      entryPage: {
        amountRaised: '$1,442,999.69',
        fundraisingGoal: '$9,000,000.00',
      },
      user: {
        ...user,
        locale: LANGUAGE_CODES.EN,
      },
      teamPage: {
        isOnTeam: 'false',
      },
    };
  } else if (location.hostname === 'secure2.convio.net') {
    // TODO: remove or fix the dev server configuration
    settings.environment = 'dev';
    settings.baseUrl = '/stjdev/site/TR';
    settings.wrapperUrl =
      'https://servicesdev.qa.stjude.org/apic/teamraiser/v1/';
    settings.wrapperClientId = 'cdfdfb7c-5d4b-4c0d-9bbe-80ab3f68f44e';
    settings.luminate.url = 'https://secure2.convio.net/stjdev/site';
    settings.appSearchCriteria.pagename = 'sjmmw_registration';
    settings.luminate.properties = luminateProperties;
    settings.eventScheduleLink =
      'https://fundraising.qa.stjude.org/get-involved/fitness-fundraisers/memphis-marathon/event-information/schedule.html';
  } else if (location.hostname === 'fundraising.qa.stjude.org') {
    settings.environment = 'qa';
    settings.wrapperUrl = 'https://services.qa.stjude.org/apic/teamraiser/v1/';
    settings.wrapperClientId = '5b52bc68-d018-4048-83a5-508e6fcff980';
    settings.luminate.url = 'https://fundraising.qa.stjude.org/site';
    settings.baseUrl = '/site/SPageServer';
    settings.appSearchCriteria.pagename = 'sjmmw_registration';
    settings.luminate.properties = {
      ...luminateProperties,
      questionPrefix: '954',
    };
    settings.eventScheduleLink =
      'https://www.stjude.org/get-involved/fitness-fundraisers/memphis-marathon/event-information/schedule.html';
  } else if (location.hostname === 'fundraising.stjude.org') {
    settings.environment = 'prod';
    settings.wrapperUrl = 'https://services.stjude.org/apic/teamraiser/v1/';
    settings.wrapperClientId = '2910c3fd-e9a7-4fc9-9885-db4827aec66c';
    settings.luminate.url = 'https://fundraising.stjude.org/site';
    settings.baseUrl = '/site/SPageServer';
    settings.appSearchCriteria.pagename = 'sjmmw_registration';
    settings.luminate.properties = {
      ...luminateProperties,
      questionPrefix: '1252',
    };
    settings.eventScheduleLink =
      'https://www.stjude.org/get-involved/fitness-fundraisers/memphis-marathon/event-information/schedule.html';
  }

  return settings;
};

export const getRegistrationStep = () => {
  const url = location.href;

  if (url.includes('attendance')) {
    return 'registration-step-choose_distance';
  }

  if (url.includes('/team') && url.includes('code')) {
    return 'registration-step-choose_team_or_individual';
  }

  if (url.includes('team=') && url.includes('options')) {
    return 'registration-step-options';
  }

  if (
    (url.includes('/personal') || url.includes('/team')) &&
    url.includes('team=')
  ) {
    return 'registration-step-form';
  }

  if (url.includes('waiver')) {
    return 'registration-step-waiver';
  }

  if (url.includes('summary')) {
    return 'registration-step-summary';
  }

  if (url.includes('payment')) {
    return 'registration-step-payment';
  }

  if (url.includes('rthanks')) {
    return 'registration-step-thanks';
  }

  if (url.includes('/search')) {
    return 'navigation-search-participant';
  }

  if (url.includes('/contact')) {
    return 'navigation-contactus';
  }
  return 'registration-landing-page-attendance';
};

import React from 'react';
import { number, oneOfType, string } from 'prop-types';

export const ErrorMessage = ({ error }) => {
  if (error) {
    console.log({ error });
  }

  return (
    <div>
      <h2>Error Screen</h2>
      <p>You are missing some required query string params</p>
      <a href='/participation?pagename=sjmmw&fr_id=30123'>Try This</a>
    </div>
  );
};

ErrorMessage.propTypes = {
  error: oneOfType([number, string]),
};

import React from 'react';
import { bool } from 'prop-types';

import { InputField } from '../../../../components';

export const PhoneField = ({ countryUSA, ...rest }) => (
  <InputField
    {...rest}
    errorText={() =>
      `Enter valid phone number ${countryUSA ? 'eg: (xxx) xxx-xxxx' : ''}`
    }
    label={rest.label}
    mask={countryUSA && 'phone'}
    maxLength={15}
    minLength={10}
    name={rest.name}
    pattern={
      countryUSA
        ? '^(?=.*?[1-9]).{13,}[0-9 ()+-]+$'
        : '^(?=.*?[1-9])[0-9 ()+-]+$'
    }
    type='tel'
  />
);

PhoneField.propTypes = {
  countryUSA: bool,
};

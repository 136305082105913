import { camelCase } from '../../../utils';

const images = {
  heroImage1: require('./hero-image-1.jpg'),
  heroImage2: require('./hero-image-2.jpg'),
  heroImage3: require('./hero-image-3.jpg'),
  heroImage4: require('./hero-image-4.jpg'),
  heroImage5: require('./hero-image-5.jpg'),
  heroImage6: require('./hero-image-6.jpg'),
  heroImage7: require('./hero-image-7.jpg'),
};

export const getHeroImage = (heroImageName) => {
  const heroImageSrc = images[camelCase(heroImageName)];
  return heroImageSrc;
};

import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { ROUTE_KEYS } from '../../app.constants';
import { Button, Screen, StepHeader, SummaryOptions } from '../../components';
import {
  useAuth,
  useQueryString,
  useRegistrationDataProvider,
  useRegTotalToPay,
  useSubmitRegistration,
} from '../../utils';
import styles from './Summary.module.scss';

export const Summary = ({ showSidebar }) => {
  const {
    user: { firstName, lastName, userName },
  } = useAuth();
  const {
    data: { personalFormData, transactionId },
  } = useRegistrationDataProvider();
  const { postStep } = useSubmitRegistration();
  const { total } = useRegTotalToPay();
  const {
    redirect,
    queryString: { fr_id },
  } = useQueryString();
  const { t } = useTranslation(['summary']);

  const handlePost = async () =>
    await postStep(transactionId, {
      pg: 'regsummary',
      fr_id,
      edit_reg: '-1',
      delete_reg: '-1',
      'previous_button.x': '',
      next_button: 'Complete Registration',
      denySubmit: '',
    });

  const handleFinishAndPay = async () => {
    if (Number(total) > 0) {
      await handlePost();
      redirect(`${ROUTE_KEYS.PAYMENT}`);
    } else {
      await handlePost();
    }
  };

  const handleEditDetails = () => redirect(`${ROUTE_KEYS.PERSONAL}`);

  let personalInfo;

  if (personalFormData)
    personalInfo = (
      <div>
        <div className={styles.spacing}>
          <li>
            <strong>{t('name')}: </strong>
            {firstName} {lastName}
          </li>
          <li>
            <strong>{t('address')}: </strong>
            {personalFormData.address1}
          </li>
          <li>
            <strong>{t('city')}: </strong>
            {personalFormData.city}
          </li>
          <li>
            <strong>{t('state')}: </strong>
            {personalFormData.state}
          </li>
          <li>
            <strong>{t('zip')}: </strong>
            {personalFormData.zip}
          </li>
          <li>
            <strong>{t('country')}: </strong>
            {personalFormData.country}
          </li>
        </div>
        <div className={styles.spacing}>
          <li>
            <strong>Email: </strong>
            {personalFormData.email}
          </li>
          <li>
            <strong>{t('phone')}: </strong>
            {personalFormData.homePhone}
          </li>
          <li>
            <strong>{t('username')}: </strong>
            {userName}
          </li>
        </div>
        <div className={styles.spacing}>
          {personalFormData.timesParticipatedSjmmw && (
            <li>
              <strong>{t('times_participated')}: </strong>
              {personalFormData.timesParticipatedSjmmw}
            </li>
          )}
          {personalFormData.singletTech && (
            <li>
              <strong>Singlet Tech: </strong>
              {personalFormData.singletTech}
            </li>
          )}
          {personalFormData.singlettechSize && (
            <li>
              <strong>Singlet Tech Size: </strong>
              {personalFormData.singlettechSize}
            </li>
          )}
          {personalFormData.qkeyDistanceone && (
            <li>
              <strong>{t('qkey_distance_one')}: </strong>
              {personalFormData.qkeyDistanceone}
            </li>
          )}
          {personalFormData.qkeyDistancetwo && (
            <li>
              <strong>{t('qkey_distance_two')}: </strong>
              {personalFormData.qkeyDistancetwo}
            </li>
          )}
          {personalFormData.qkeyDistancetwoVirtual && (
            <li>
              <strong>{t('qkey_distance_two')}: </strong>
              {personalFormData.qkeyDistancetwoVirtual}
            </li>
          )}
        </div>
        <div className={styles.spacing}>
          {personalFormData.isPatientFam && (
            <li>
              <strong>{t('patient_fam_member')}: </strong>
              {personalFormData.isPatientFam}
            </li>
          )}
          {personalFormData.militaryFirstResponder && (
            <li>
              <strong>{t('first_responder_military')}: </strong>
              {personalFormData.militaryFirstResponder}
            </li>
          )}
        </div>
        <Button
          className={styles.edit}
          variant='text'
          onClick={handleEditDetails}
        >
          {t('edit_details')}
        </Button>
      </div>
    );

  return (
    <Screen className={styles.container} showSidebar={showSidebar}>
      <StepHeader />
      <>
        <h4>{t('confirm_and_complete')}</h4>
        <ul>
          {personalInfo}
          <SummaryOptions />
        </ul>
      </>
      <Button isfullWidth onClick={handleFinishAndPay} variant='primary'>
        {t('finish_pay')}
      </Button>
    </Screen>
  );
};

Summary.propTypes = {
  showSidebar: bool,
};

import { Route, useHistory, useRouteMatch } from 'react-router';
import React, { useEffect } from 'react';
import { useQueryString } from '../QueryStrings';
import { useConfiguration } from '../configuration/ConfigurationContext';
import { useAuth } from './SecurityContext';

const SecureRoute = (props) => {
  const { user } = useAuth();
  const match = useRouteMatch(props);
  const history = useHistory();
  const { buildCleanPath, queryString } = useQueryString();
  const {
    settings: {
      luminate: {
        properties: {
          user: { consId },
        },
      },
    },
  } = useConfiguration();

  useEffect(() => {
    if (!match) {
      return;
    }

    if (!user && !consId) {
      history.replace(buildCleanPath('login', { ...queryString }));
    }
  }, [user, consId, match, queryString, buildCleanPath, history]);

  if (user || consId) {
    return <Route {...props} />;
  }

  return null;
};

export default SecureRoute;

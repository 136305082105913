import get from 'lodash/get';
// init stjude obj for local dev only so we have something to push to
export const stjude = {
  digitalData: {
    sysEnv: {
      siteCode: 'p2p',
      environment: 'dev',
    },
    page: {
      pageName: '',
      pageType: '',
      section: '',
      subsection: '',
      subsubsection: '',
      title: '',
      language: 'english',
      eventID: 26663,
      eventName: 'St. Jude Memphis Marathon Weekend',
      teamID: '',
      teamName: '',
      programName: 'Marathon',
      messages: [],
    },
    user: {},
    event: [],
  },
};

export const setEvent = ({ eventAction = null, eventName, type = null }) => {
  const eventObj = {
    category: {},
    eventInfo: {
      eventAction,
      eventName,
      type,
      timeStamp: new Date(),
    },
  };
  if (window.stjude && eventName) {
    window.stjude.digitalData.event.push(eventObj);
  } else {
    console.warn(
      'No stjude object found for adobe launch tracking, setEvent function is noop'
    );
  }
};

export const extractPath = ({ pathname }) => pathname.split('/')[1];

const setSection = (path) => {
  if (path === 'options') return 'choose options';
  if (path === 'team') return 'choose team';
  if (path === 'personal') return 'personal information';
  return path;
};

const setSubsection = ({ attendance, commitment, distance, team }) => {
  if (team) return team;
  if (attendance && !distance) return 'distance';
  if (attendance && distance && !commitment) return 'commitment';
  return '';
};

export const setPageFromLocation = (location, query, { data }) => {
  const attendanceType = get(
    query,
    'attendance',
    get(data, 'selectedParticipationType.attendance')
  );
  const teamType = get(query, 'team', '');
  const distanceType = get(data, 'selectedParticipationType.distance');
  const { pathname } = location;
  const path = pathname.split('/')[1];

  if (window.stjude) {
    const { page } = window.stjude.digitalData;
    const { eventID } = page;
    const { eventName } = page;
    const programName = 'heroes';
    const section = setSection(path, query);
    const subsection = setSubsection(query);

    const setPageName = () => {
      const baseStr = `p2p:${programName}:${eventName} - ${eventID}:`;
      const middleStr = () => {
        let result = '';
        switch (path) {
          case 'participation': {
            result = `${!attendanceType ? '' : `${attendanceType.toLowerCase()}:`}participation${
              !distanceType && attendanceType
                ? ':choose distance'
                : distanceType && `${distanceType.toLowerCase()}:registration`
            }`;
            break;
          }
          case 'team': {
            result = `${!attendanceType ? '' : `${attendanceType.toLowerCase()}:`}participation:${
              distanceType ? distanceType.toLowerCase() : ''
            }:choose team:registration`;
            break;
          }
          case 'login': {
            result =
              attendanceType && distanceType
                ? `${attendanceType.toLowerCase()}:participation:${distanceType ? distanceType.toLowerCase() : ''}:login:registration`
                : 'login';
            break;
          }
          default: {
            result = `${!attendanceType ? '' : `${attendanceType.toLowerCase()}:`}participation:${
              distanceType ? distanceType.toLowerCase() : ''
            }:${teamType}:${path}:registration`;
            break;
          }
        }
        if (result.includes('participationundefined')) {
          result = result.replace(
            'participationundefined',
            'participation-landingpage'
          );
        }
        return result;
      };

      return baseStr.concat(middleStr());
    };

    window.stjude.digitalData.page = {
      ...page,
      eventID,
      eventName,
      pageName: setPageName(),
      programName,
      section,
      subsection,
    };
  } else {
    console.error(
      'No stjude object found for adobe launch tracking, setPageFromLocation function is noop'
    );
  }
};

export const setPage = ({
  screen,
  section = '',
  subsection = '',
  subsubsection = '',
}) => {
  if (window.stjude) {
    const { page } = window.stjude.digitalData;
    window.stjude.digitalData.page = {
      ...page,
      pageName: screen ? page.pageName.replace('login', screen) : page.pageName,
      section,
      subsection,
      subsubsection,
    };
  } else {
    console.error(
      'No stjude object found for adobe launch tracking, setPage function is noop'
    );
  }
};

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Button, Figure } from '..';
import { colors } from '../../styles';

import styles from './Overlay.module.scss';

export const Overlay = ({
  image,
  placeholder,
  overlayCommands,
  text,
  title,
}) => {
  const { white } = colors.additional;
  const sideOverlay = React.useRef(null);

  const showOverlay = () => {
    if (!overlayCommands.activeOverlay) {
      overlayCommands.setActiveOverlay(sideOverlay);
      sideOverlay.current.style.transform = 'translateX(0)';
    }

    if (
      overlayCommands.activeOverlay &&
      overlayCommands.activeOverlay === sideOverlay
    ) {
      overlayCommands.setActiveOverlay(null);
      sideOverlay.current.style.transform = 'translateX(100%)';
    }

    if (
      overlayCommands.activeOverlay &&
      overlayCommands.activeOverlay !== sideOverlay
    ) {
      overlayCommands.activeOverlay.current.style.transform =
        'translateX(100%)';
      sideOverlay.current.style.transform = 'translateX(0)';
      overlayCommands.setActiveOverlay(sideOverlay);
    }
  };

  const hideOverlay = () => {
    overlayCommands.setActiveOverlay(null);
    sideOverlay.current.style.transform = 'translateX(100%)';
  };

  return (
    <>
      <button
        type='button'
        className={cn('button', 'is-link', 'is-inverted', styles.buttonLink)}
        onClick={showOverlay}
      >
        {placeholder}
      </button>

      <div ref={sideOverlay} className={cn(styles.container)}>
        <div className={cn('card', styles.card)}>
          <header className={cn('card-header', styles.cardHeader)}>
            {title && (
              <p className={cn('card-header-title', styles.cardHeaderTitle)}>
                {title}
              </p>
            )}

            <div className={cn('card-header-icon', styles.cardHeaderIcon)}>
              <Button
                onClick={hideOverlay}
                type='button'
                variant='text'
                iconProps={{
                  color: white,
                  name: 'close',
                  size: 16,
                }}
              />
            </div>
          </header>

          {image && (
            <div className={cn('card-image', styles.cardImage)}>
              <Figure src={image} alt={`${title} overlay`} />
            </div>
          )}

          <div className={cn('card-content', styles.cardContent)}>
            <div
              className={cn('content', styles.content)}
              dangerouslySetInnerHTML={{ __html: text }}
            />
          </div>
        </div>
      </div>
    </>
  );
};

Overlay.propTypes = {
  placeholder: PropTypes.string.isRequired,
  title: PropTypes.string,
  image: PropTypes.node,
  text: PropTypes.node.isRequired,
  overlayCommands: PropTypes.object.isRequired,
};

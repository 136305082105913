import React from 'react';
import Cropper from 'react-cropper';
import ReactQuill from 'react-quill';
import cn from 'classnames';
import {
  convertCentsToDollars,
  FundraisingProgressBar,
  Icon,
  OverflowText,
  PageTitle,
  ScreenBackground,
  SocialShare,
} from '@frx/frx-components';
import currency from 'currency.js';
import {
  Button,
  ColumnList,
  EditModal,
  ErrorMessage,
  Figure,
  InputField,
  Label,
} from '../../../components';
import {
  capitalize,
  isEqual,
  stringToBoolean,
  trimZeroDecimals,
  useConfiguration,
  useQuery,
  useQueryString,
  useTeamPageContext,
  useJoinTeam,
} from '../../../utils';
import {
  MARATHON_HOME_PAGE_URL,
  QUILL_MODULES,
  ROUTE_KEYS,
} from '../../../app.constants';

import { bgImg } from '../../../assets';
import { colors } from '../../../styles';

import 'cropperjs/dist/cropper.css';
import 'react-quill/dist/quill.snow.css';
import styles from './Team.module.scss';
import { ListCell, ListRow } from '../../../components/column-list';

const renderError = ({ updateError }) =>
  updateError && <ErrorMessage {...{ errorText: updateError }} />;

export const Team = () => {
  /** hooks */
  const [image, setImage] = React.useState();
  const [cropper, setCropper] = React.useState();
  const [richText, setRichText] = React.useState();
  const goalRef = React.useRef();
  const teamRoster = React.useRef();

  /** data */
  const {
    queryString: { fr_id, reg_type },
    redirect,
  } = useQueryString();
  const configuration = useConfiguration();
  const {
    appSearchCriteria: { pagename },
    luminate,
  } = configuration.settings;
  const { apiKey, url, properties } = luminate;
  const {
    user: { consId, token },
    teamPage: { isOnTeam },
  } = properties;

  const { joinTeamFromExternalLink } = useJoinTeam();
  const {
    commands: {
      handleUpdateGoal,
      handleUpdateRichText,
      handleGoalModal,
      handleGoalCancel,
      handleImageModal,
      handleImageCancel,
      handleRichTextModal,
      handleRichTextCancel,
      setUpdateError,
    },
    data: {
      editGoalModal,
      editImageModal,
      editRichTextModal,
      eventDetails: { eventDate, eventAddress },
      eventInfo,
      teamInfo,
      teamPageInfo,
      teamRosterData,
      updateError,
    },
  } = useTeamPageContext();

  const teamPhotoInfoData = useQuery('CRTeamraiserAPI/getTeamPhoto', {
    fr_id,
    cons_id: teamInfo.captainConsId,
  });

  const profileImage =
    teamPhotoInfoData.queryResponse.data &&
    teamPhotoInfoData.queryResponse.data.photoItem;

  React.useEffect(() => {
    if (profileImage) {
      setImage(profileImage.customUrl);
    }
    setRichText(teamPageInfo.richText);
  }, [profileImage, teamPageInfo]);

  /** team information * */
  const hasTeamCaptain = () =>
    teamInfo.captainConsId &&
    Number(teamInfo.captainConsId) > 0 &&
    teamInfo.isCaptainAnon &&
    !stringToBoolean(teamInfo.isCaptainAnon);

  const teamName = capitalize(teamInfo.name);
  const teamEventDetails = {
    text: teamInfo.eventName,
    url: MARATHON_HOME_PAGE_URL,
  };

  const isRegTypeHeroes = () => {
    if (reg_type === 'heroes') {
      return true;
    }
    return false;
  };

  const teamCaptain = hasTeamCaptain() && {
    text: `Team Captain: ${teamInfo.captainFirstName} ${teamInfo.captainLastName}`,
    url: isRegTypeHeroes(reg_type)
      ? `/site/TR/Heroes/SJMMW?fr_id=${fr_id}&pg=personal&px=${teamInfo.captainConsId}&reg_type=${reg_type}`
      : `/site/TR/Heroes/SJMMW?fr_id=${fr_id}&pg=personal&px=${teamInfo.captainConsId}`,
    /* html: `Team Captain: <a href="/site/TR/Heroes/SJMMW?fr_id=${fr_id}&pg=personal&px=${teamInfo.captainConsId}&reg_type=${reg_type}">${teamInfo.captainFirstName} ${teamInfo.captainLastName}</a>`, */
  };
  const teamParticipants = teamInfo.numMembers;
  const ownPage = isEqual(teamInfo.captainConsId, consId);

  /** functions */
  const handleChange = (e) => {
    const [file] = e.files;
    if (file) {
      const reader = new FileReader();
      reader.onload = (ev) => setImage(ev.target.result);
      reader.readAsDataURL(file);
    }
  };

  const handleUpdateImage = async (blob) => {
    const formData = new FormData();

    formData.append('v', '1.0');
    formData.append('api_key', apiKey);
    formData.append('response_format', 'json');
    formData.append('method', 'uploadTeamPhoto');

    formData.append('fr_id', fr_id);
    formData.append('graphic_upload_file', blob, 'photo.jpg');
    formData.append('graphic_upload_upload', true);
    formData.append('auth', token);
    formData.append('suppress_response_codes', true);

    const fetchOptions = {
      method: 'POST',
      credentials: 'include',
      body: formData,
    };

    const uploadTeamPhotoResponseText = await fetch(
      `${url}/CRTeamraiserAPI`,
      fetchOptions
    ).then((res) => res.text());

    let parsedResponse;
    try {
      parsedResponse = JSON.parse(uploadTeamPhotoResponseText);
    } catch (err) {
      parsedResponse = uploadTeamPhotoResponseText;
    }

    if (parsedResponse.errorResponse) {
      setUpdateError(parsedResponse.errorResponse.message);
    } else {
      handleImageCancel();
      teamPhotoInfoData.refetch();
    }
  };

  const handleProfileUpdate = () => {
    cropper
      .getCroppedCanvas({ maxHeight: 300, maxWidth: 300 })
      .toBlob((blob) => {
        handleUpdateImage(blob);
      }, 'image/jpeg');
  };

  const handleGoalUpdate = () =>
    handleUpdateGoal(Number(goalRef.current.value) * 100);

  const handleJoinTeam = () => {
    // Personal Page Join a team.
    if (ownPage) {
      location.href = `TRC?pg=center&fr_id=${fr_id}&reg_type=${reg_type}`;
    } else {
      joinTeamFromExternalLink('join-team', teamInfo.id, fr_id, pagename);
    }
  };

  const handleDonate = () => {
    teamRoster.current.scrollIntoView();
  };

  const handleRichTextChange = (html) => setRichText(html);

  const handleStoryUpdate = () => handleUpdateRichText(richText);

  const handleProfileCancel = () => {
    handleImageCancel();
    setImage(profileImage.customUrl);
  };

  const handleRichTextModalCancel = () => {
    handleRichTextCancel();
    setRichText(teamPageInfo.richText);
  };

  const handleSeeAllTeamMembers = () =>
    redirect(`/${ROUTE_KEYS.SEARCH}`, { pagename, fr_id });

  return (
    <main className='team-page'>
      <ScreenBackground className='hero' minimumHeight='30rem' bgHero={bgImg}>
        <section className={cn('section', styles.section)}>
          <div className='container'>
            <div className={cn(styles.pageColumns, 'columns')}>
              {/* left column */}
              <div
                className={cn(
                  styles.sideColumn,
                  'column',
                  'is-one-quarter-desktop'
                )}
              >
                <div className={cn('box', styles.box)}>
                  <div
                    className={cn(
                      styles.componentContainer,
                      styles.profileImageContainer,
                      'is-hidden-mobile'
                    )}
                  >
                    <Figure
                      alt='profile'
                      src={profileImage && profileImage.customUrl}
                    />
                    {ownPage && (
                      <EditModal
                        className={styles.editProfile}
                        handleModal={handleImageModal}
                        onPrimaryAction={handleProfileUpdate}
                        onSecondaryAction={handleProfileCancel}
                        title='Photo'
                        visible={editImageModal}
                      >
                        {renderError({ updateError })}
                        <InputField
                          accept='image/*'
                          label='Select an image to upload'
                          onChange={handleChange}
                          type='file'
                        />
                        <Cropper
                          autoCropArea={1}
                          background={false}
                          checkOrientation={false}
                          guides
                          initialAspectRatio={1}
                          minCanvasHeight={300}
                          minCanvasWidth={300}
                          minContainerHeight={300}
                          minContainerWidth={300}
                          minCropBoxHeight={10}
                          minCropBoxWidth={10}
                          onInitialized={(instance) => setCropper(instance)}
                          responsive
                          src={image}
                          viewMode={1}
                        />
                      </EditModal>
                    )}
                  </div>

                  <div
                    className={cn(
                      styles.componentContainer,
                      styles.eventDetailsContainer
                    )}
                  >
                    <h2>Event Details</h2>
                    <div className={styles.detailItem}>
                      <Icon
                        color={colors.secondary.swatch7}
                        name='calendar'
                        size={28}
                      />
                      <span>{eventDate}</span>
                    </div>
                    <div className={styles.detailItem}>
                      <Icon
                        color={colors.secondary.swatch7}
                        name='map-filled'
                        size={28}
                      />
                      <span>{eventAddress}</span>
                    </div>
                  </div>
                </div>
              </div>

              {/* right column */}
              <div
                className={cn(
                  styles.mainColumn,
                  'column',
                  'is-three-quarter-desktop'
                )}
              >
                <div
                  className={cn(
                    styles.componentContainer,
                    styles.profileImageContainer,
                    'is-hidden-tablet'
                  )}
                >
                  <Figure
                    alt='profile'
                    src={profileImage && profileImage.customUrl}
                  />
                  {ownPage && (
                    <EditModal
                      className={styles.editProfile}
                      handleModal={handleImageModal}
                      onPrimaryAction={handleProfileUpdate}
                      onSecondaryAction={handleProfileCancel}
                      title='Photo'
                      visible={editImageModal}
                    >
                      {renderError({ updateError })}
                      <InputField
                        accept='image/*'
                        label='Select an image to upload'
                        onChange={handleChange}
                        type='file'
                      />
                      <Cropper
                        autoCropArea={1}
                        background={false}
                        checkOrientation={false}
                        guides
                        initialAspectRatio={1}
                        minCanvasHeight={300}
                        minCanvasWidth={300}
                        minContainerHeight={300}
                        minContainerWidth={300}
                        minCropBoxHeight={10}
                        minCropBoxWidth={10}
                        onInitialized={(instance) => setCropper(instance)}
                        responsive
                        src={image}
                        viewMode={1}
                      />
                    </EditModal>
                  )}
                </div>

                <div
                  className={cn(
                    styles.componentContainer,
                    styles.titleContainer
                  )}
                >
                  <div>
                    {teamInfo && (
                      <PageTitle
                        title={teamName}
                        firstSubtitle={teamEventDetails}
                        secondSubtitle={teamCaptain}
                      />
                    )}
                  </div>
                  <SocialShare
                    description='Help support the kids of St. Jude'
                    title='Share'
                  />
                </div>

                <div
                  className={cn(
                    styles.componentContainer,
                    styles.teamStatsContainer
                  )}
                >
                  <ul className={cn(styles.teamStats)}>
                    <li>
                      {/* <span>logo</span> */}
                      <span className={cn(styles.statNumber)}>
                        {teamParticipants}
                      </span>
                      <span className={cn(styles.statLabel)}>Participants</span>
                    </li>
                  </ul>
                </div>

                {teamInfo && (
                  <>
                    <div
                      className={cn(
                        styles.componentContainer,
                        styles.progressContainer
                      )}
                    >
                      <FundraisingProgressBar
                        amountRaised={trimZeroDecimals(
                          currency(teamInfo.amountRaised, {
                            fromCents: true,
                          }).format()
                        )}
                        goal={trimZeroDecimals(
                          currency(teamInfo.goal, {
                            fromCents: true,
                          }).format()
                        )}
                        percentage={Math.min(
                          Math.floor(
                            teamInfo.goal !== 0
                              ? (Number(teamInfo.amountRaised) /
                                  Number(teamInfo.goal)) *
                                  100
                              : 100
                          ),
                          100
                        )}
                      >
                        {ownPage && (
                          <EditModal
                            className={styles.editGoal}
                            handleModal={handleGoalModal}
                            onPrimaryAction={handleGoalUpdate}
                            onSecondaryAction={handleGoalCancel}
                            title='Goal'
                            visible={editGoalModal}
                          >
                            {renderError({ updateError })}
                            <InputField
                              defaultValue={currency(teamInfo.goal, {
                                fromCents: true,
                              })}
                              errorText={() => 'Enter valid number'}
                              iconProps={{
                                name: 'dollar-sign',
                                placement: 'left',
                              }}
                              label='Goal'
                              max={999999999}
                              minLength={1}
                              ref={goalRef}
                              required
                              type='number'
                            />
                          </EditModal>
                        )}
                      </FundraisingProgressBar>
                    </div>
                  </>
                )}

                <div
                  className={cn(styles.componentContainer, styles.ctaButtons)}
                >
                  {eventInfo?.accepting_donations === 'true' && (
                    <div>
                      <Button xs variant='donate' onClick={handleDonate}>
                        Donate to a Team Member
                      </Button>
                    </div>
                  )}
                  {eventInfo?.accepting_registrations === 'true' && (
                    <div>
                      <Button
                        disabled={!ownPage && stringToBoolean(isOnTeam)}
                        xs
                        variant='primary'
                        onClick={handleJoinTeam}
                      >
                        Join this Team
                      </Button>
                    </div>
                  )}
                </div>

                <div className={cn(styles.teamRoster, 'box')} ref={teamRoster}>
                  <div className='container'>
                    <Label className={styles.label} text='Team Roster' />
                    <hr className='line is-hidden-touch' />
                    <div
                      className={cn(styles.rosterListContainer, 'container')}
                    >
                      <ColumnList
                        data={teamRosterData.data?.participant}
                        header={
                          <ListRow
                            className='is-hidden-mobile'
                            variant='header'
                          >
                            <ListCell>Participant name</ListCell>
                            <ListCell>Amount raised</ListCell>
                            <ListCell />
                          </ListRow>
                        }
                        renderItem={({
                          consId: id,
                          amountRaised,
                          donationUrl,
                          personalPageUrl,
                          name: { first, last },
                        }) => (
                          <ListRow className={styles.columnRowData} key={id}>
                            <ListCell>
                              <Button
                                className='p-0'
                                variant='text'
                                onClick={() =>
                                  (location.href = isRegTypeHeroes(reg_type)
                                    ? `${personalPageUrl}&reg_type=${reg_type}`
                                    : `${personalPageUrl}`)
                                }
                              >
                                {first} {last}
                              </Button>
                            </ListCell>
                            <ListCell>
                              <span className='is-hidden-desktop has-text-weight-bold'>
                                Raised:{' '}
                              </span>
                              {convertCentsToDollars(amountRaised, {
                                formatted: true,
                                rounded: true,
                                divider: true,
                              })}
                            </ListCell>
                            {eventInfo?.accepting_donations === 'true' ? (
                              <ListCell>
                                <Button
                                  variant='donate'
                                  xs
                                  onClick={() => (location.href = donationUrl)}
                                >
                                  Donate
                                </Button>
                              </ListCell>
                            ) : (
                              <></>
                            )}
                          </ListRow>
                        )}
                      />
                    </div>
                    <div className={styles.action}>
                      <Button
                        variant='secondary'
                        onClick={handleSeeAllTeamMembers}
                      >
                        See all team members
                      </Button>
                    </div>
                  </div>
                </div>

                <hr className='line double is-hidden-touch' />

                <div
                  className={cn(
                    styles.componentContainer,
                    styles.storyContainer
                  )}
                >
                  {ownPage && (
                    <EditModal
                      className={styles.editStory}
                      handleModal={handleRichTextModal}
                      onPrimaryAction={handleStoryUpdate}
                      onSecondaryAction={handleRichTextModalCancel}
                      title='Story'
                      visible={editRichTextModal}
                    >
                      {renderError({ updateError })}
                      <ReactQuill
                        id='richText'
                        modules={QUILL_MODULES}
                        name='richText'
                        onChange={handleRichTextChange}
                        theme='snow'
                        value={richText}
                      />
                    </EditModal>
                  )}

                  {teamPageInfo && <OverflowText content={richText} />}
                </div>
              </div>
            </div>
          </div>
        </section>
      </ScreenBackground>
    </main>
  );
};

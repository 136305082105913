import React from 'react';
import { array, func } from 'prop-types';
import i18n from 'i18next';
import { Figure } from '..';
import { CheckboxField } from '../forms';
import {
  getUpsellImage,
  getUpsellLabel,
  getUpsellDescription,
} from '../../assets';
import { LANGUAGE_CODES } from '../../app.constants';
import { useRegistrationDataProvider, isEqual } from '../../utils';

export const Upsells = ({ data, handleUpsellClick }) => {
  const {
    data: { upsells },
  } = useRegistrationDataProvider();

  return data.map((u) => {
    const convertNewPrice = `$${(u.price / 100).toFixed(2)}`;
    const upsellImage = getUpsellImage(u.name);
    const spanishLabel = getUpsellLabel(u.name);
    const spanishDescription = getUpsellDescription(u.name);

    const isSpanish = isEqual(i18n.language, LANGUAGE_CODES.ES);
    return (
      <Figure alt={u.name} key={u.id} src={upsellImage}>
        <CheckboxField
          name={u.name}
          defaultChecked={upsells?.some((tu) => tu.id === u.id)}
          label={
            <strong>{`${
              isSpanish ? spanishLabel : u.label
            } ${convertNewPrice}`}</strong>
          }
          description={isSpanish ? spanishDescription : u.description}
          onClick={(e) => handleUpsellClick(e, u.name, convertNewPrice, u.id)}
        />
      </Figure>
    );
  });
};

Upsells.propTypes = {
  data: array,
  handleUpsellClick: func,
};

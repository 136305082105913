import React from 'react';
import { number, string } from 'prop-types';
import { InputField } from '../../../../components';
import { useRegistrationDataProvider } from '../../../../utils';

export const ShortText = ({
  className,
  label,
  maxLength = 40,
  name,
  ...rest
}) => {
  const {
    data: { personalFormData },
  } = useRegistrationDataProvider();
  const defaultValue = personalFormData && personalFormData[name];
  return (
    <InputField
      {...{ className, defaultValue, label, maxLength, name }}
      {...rest}
    />
  );
};

ShortText.propTypes = {
  className: string,
  label: string,
  maxLength: number,
  name: string,
};

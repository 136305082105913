import React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '..';
import { useLanguage } from './layout';
import {
  isEnglish,
  isLocal,
  isQA,
  useAuth,
  useConfiguration,
  useQueryString,
  useRegistrationDataProvider,
} from '../../utils';
import {
  MARATHON_HOME_PAGE_URL,
  PRIVACY_OPT_OUT_RIGHTS,
  PRIVACY_OPT_OUT_RIGHTS_QA,
  PRIVACY_POLICY,
  ROUTE_KEYS,
} from '../../app.constants';

import styles from './Footer.module.scss';

const Footer = () => {
  const { user } = useAuth();
  const {
    settings: {
      appSearchCriteria: { pagename },
      luminate,
    },
  } = useConfiguration();
  const {
    user: { consId, registered, locale },
  } = luminate.properties;
  const { buildPath, buildCleanPath, queryString } = useQueryString();
  const {
    data: { eventInfo },
  } = useRegistrationDataProvider();
  const { fr_id, reg_type } = queryString;

  /* const { handleLanguage, handleLogout, isFCPage } = useLanguage(); */
  const { handleLogout } = useLanguage();
  const { t } = useTranslation();

  const handlePrivacyPolicy = isEnglish(locale)
    ? PRIVACY_POLICY.EN
    : PRIVACY_POLICY.ES;

  const handleOptOutPrivacyLinks = () => {
    if (isQA || isLocal)
      return isEnglish(locale)
        ? PRIVACY_OPT_OUT_RIGHTS_QA.EN
        : PRIVACY_OPT_OUT_RIGHTS_QA.ES;
    const optOutLink = isEnglish(locale)
      ? PRIVACY_OPT_OUT_RIGHTS.EN
      : PRIVACY_OPT_OUT_RIGHTS.ES;
    return optOutLink;
  };

  return (
    <>
      <footer className={cn('footer', 'has-text-centered', styles.container)}>
        <div className='container'>
          <div className='copyright content'>
            <p>&copy; {t('copyright_text')}.</p>
          </div>

          <nav id='footerNav' className={styles.footerNav} role='navigation'>
            <a href={MARATHON_HOME_PAGE_URL}>{t('footer.home_link_text')}</a>

            {/*  {!isFCPage() && (
              <Button
                variant='text'
                onClick={handleLanguage}
                className={cn('navbar-item', styles.navbarItem)}
              >
                {isEnglish(locale) ? 'Español' : 'English'}
              </Button>
            )} */}

            <Link
              to={() =>
                buildCleanPath(`/${ROUTE_KEYS.SEARCH}`, {
                  pagename,
                  fr_id,
                  reg_type,
                })
              }
            >
              {t('header.find_participant_link_text')}
            </Link>

            {
              // if logged in and registered
              !consId &&
                !registered &&
                eventInfo?.accepting_registrations === 'true' && (
                  <Link
                    to={() =>
                      buildCleanPath(`/${ROUTE_KEYS.PARTICIPATION}`, {
                        pagename,
                        fr_id,
                        reg_type,
                      })
                    }
                  >
                    {t('header.register_link_text')}
                  </Link>
                )
            }

            <Link
              to={() =>
                buildCleanPath(`/${ROUTE_KEYS.CONTACT}`, {
                  pagename,
                  fr_id,
                  reg_type,
                })
              }
            >
              {t('header.contact_us_link_text')}
            </Link>

            {(user || consId) && (
              <Button
                variant='text'
                onClick={handleLogout}
                className={styles.footerLink}
              >
                {t('header.log_out_link_text')}
              </Button>
            )}

            {!user && !consId && (
              <Link
                to={(location) =>
                  buildPath(`/${ROUTE_KEYS.LOGIN}`, {
                    redirect: location.pathname,
                  })
                }
              >
                {t('header.log_in_link_text')}
              </Link>
            )}

            <a href={handlePrivacyPolicy} target='_blank' rel='noreferrer'>
              {t('footer.privacy_notice_link_text')}
            </a>
            <a
              href={handleOptOutPrivacyLinks()}
              target='_blank'
              rel='noreferrer'
            >
              {t('footer.privacy_opt_out_rights')}
            </a>
          </nav>
        </div>
      </footer>
    </>
  );
};

export default Footer;

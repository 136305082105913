import React from 'react';
import { instanceOf, string } from 'prop-types';

import { Button } from '@frx/frx-components';
import styles from './ColumnList.module.scss';

export const ListCell = ({ children, variant, cellcolor, ...rest }) => {
  const sortIcon = rest.onClick && {
    name: 'sort',
    viewBox: '0 0 1792 1792',
    position: 'right',
    color: cellcolor,
  };

  return (
    <div
      {...rest}
      className={styles.cell}
      style={{
        ...rest.style,
        color: !cellcolor ? 'inherit' : cellcolor,
      }}
    >
      {rest.onClick ? (
        <Button
          {...{
            buttonColor: 'blue',
            children,
            className: styles.cellHeader,
            iconProps: sortIcon,
            variant: 'text',
          }}
        />
      ) : (
        <p {...{ children }} />
      )}
    </div>
  );
};

ListCell.propTypes = {
  ...instanceOf(HTMLParagraphElement).isRequired,
  variant: string,
};

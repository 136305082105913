import React from 'react';
import { number, string } from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './RegistrationProgress.module.scss';

export const RegistrationProgress = ({ styledClassNames, value }) => {
  const { t } = useTranslation();
  const className = cn(styles.container, styledClassNames);

  return (
    <section className={className}>
      <progress value={value} max={100} id='registration-progress'>
        {value}%
      </progress>
      <label htmlFor='registration-progress'>
        {value}% {t('screens.progress_completed_text')}
      </label>
    </section>
  );
};

RegistrationProgress.propTypes = {
  styledClassNames: string,
  value: number,
};

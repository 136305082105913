import React from 'react';

const defaultContext = Object.freeze({
  commands: {
    setCompanyId: () => undefined,
  },
  data: {
    eventInfo: undefined,
    teamsByCompany: undefined,
    topParticipants: undefined,
    topTeamsWithMembers: undefined,
  },
});

export const EventPageContext = React.createContext(defaultContext);

export const useEventPageProvider = () => {
  const context = React.useContext(EventPageContext);

  if (context === undefined) {
    throw new Error(
      'useEventPageProvider must be within an CacheQueryProvider'
    );
  }

  return context;
};

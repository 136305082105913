import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import styles from './Fieldset.module.scss';

const Fieldset = (props) => {
  const className = cn(styles.container, props.className);

  return (
    <fieldset className={className}>
      {props.label && <legend>{props.label}</legend>}
      {props.description && <p>{props.description}</p>}
      {props.children}
    </fieldset>
  );
};

Fieldset.propTypes = {
  ...PropTypes.instanceOf(HTMLFieldSetElement).isRequired,
  label: PropTypes.string,
  description: PropTypes.string,
};

export default Fieldset;

import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { isEqual, isNil } from 'lodash';
import { sortCollator } from './utils';
import { SORT_DIRECTIONS } from '../app.constants';

export const useInterval = (callback, delay) => {
  const savedCallback = useRef();

  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  useEffect(() => {
    const tick = () => {
      savedCallback.current();
    };

    if (delay !== null) {
      const id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
};

export const useSortColumns = (items, criteria = null) => {
  const [sortCriteria, setSortCriteria] = useState(criteria);
  const { ASC, DESC } = SORT_DIRECTIONS;

  const handleSort = (columnName) => {
    let direction = ASC;
    if (
      sortCriteria &&
      sortCriteria.columnName === columnName &&
      sortCriteria.direction === ASC
    ) {
      direction = DESC;
    }
    setSortCriteria({ columnName, direction });
  };

  const compareBy = useCallback(
    (a, b) => {
      if (isEqual(sortCriteria.direction, ASC))
        return sortCollator.compare(
          a[sortCriteria.columnName],
          b[sortCriteria.columnName]
        );
      if (isEqual(sortCriteria.direction, DESC))
        return sortCollator.compare(
          b[sortCriteria.columnName],
          a[sortCriteria.columnName]
        );
      return 0;
    },
    [ASC, DESC, sortCriteria]
  );

  const sortedItems = useMemo(() => {
    if (items) {
      const sortableItems = [...items];
      if (!isNil(sortCriteria)) {
        sortableItems.sort(compareBy);
      }
      return sortableItems;
    }
  }, [items, compareBy, sortCriteria]);

  return { items: sortedItems, handleSort };
};

export const usePagination = (initialPageIndex = -1) => {
  const [pageIndex, setPageIndex] = useState(initialPageIndex);

  const handlePrev = () =>
    setPageIndex((prevState) => Math.max(prevState - 1, 0));
  const handleNext = () => setPageIndex((prevState) => prevState + 1);

  return { pageIndex, setPageIndex, handleNext, handlePrev };
};

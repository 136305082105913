import { useCallback, useEffect, useRef, useState } from 'react';

import { useConfiguration } from '../../../configuration/ConfigurationContext';
import { useQueryString } from '../../../QueryStrings';
import { useFetcher } from '../../QueryContext';

export const useTopDonors = ({ pathUrl }) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { current: queryOptions } = useRef({ pathUrl });

  useEffect(() => {
    setLoading(true);
    fetch(queryOptions.pathUrl)
      .then((res) => res.text())
      .then((text) => {
        const removeSpace = text.trim().replace(/&nbsp;/g, ' ');
        return Array.from(
          new DOMParser()
            .parseFromString(removeSpace, 'text/xml')
            .querySelectorAll('li')
        ).map((item) => {
          const newText = item.textContent.split('-');
          return {
            donorAmount: (newText[1] || '').trim(),
            donorName: (newText[0] || '').trim(),
          };
        });
      })
      .then((results) => setData(results))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));
  }, [queryOptions]);

  return { data, error, loading };
};

export const useDonationArray = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);

  const {
    queryString: { fr_id, px: cons_id },
  } = useQueryString();
  const fetcher = useFetcher();
  const configuration = useConfiguration();
  const form_id = '3089';
  const { current: queryOptions } = useRef({ fr_id, cons_id, form_id });
  const fetchData = useCallback(async () => {
    setLoading(true);
    try {
      const getDonationFormInfo = await fetcher.get(
        'CRDonationAPI/getDonationFormInfo',
        {
          form_id: queryOptions.form_id,
          fr_id: queryOptions.fr_id,
        }
      );
      const { donationLevels } =
        getDonationFormInfo.getDonationFormInfoResponse;
      const userSpecifiedAmount = (
        (donationLevels || {}).donationLevel || []
      ).filter((d) => d.userSpecified === 'true');
      const minimumAmount =
        userSpecifiedAmount.length &&
        Number(userSpecifiedAmount[0].amount.formatted.replace('$', ''));
      const donationArray = ((donationLevels || {}).donationLevel || []).map(
        (d) => ({
          name: d.name,
          value:
            d.userSpecified !== 'true'
              ? d.amount.formatted.replace('.00', '')
              : 'Other',
          link: `${configuration.settings.luminate.url}/Donation2?df_id=${queryOptions.form_id}&${queryOptions.form_id}.donation=form1&PROXY_ID=${queryOptions.cons_id}&PROXY_TYPE=20&FR_ID=${queryOptions.fr_id}&set.DonationLevel=${d.level_id}`,
        })
      );
      setData({ donationArray, minimumAmount });
    } catch (err) {
      setError(err);
    } finally {
      setLoading(false);
    }
  }, [fetcher, queryOptions, configuration.settings.luminate.url]);

  useEffect(() => {
    fetchData();
  }, [fetchData]);

  return { queryResponse: { data, error, loading } };
};

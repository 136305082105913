import React from 'react';
import { bool, func, node, number, oneOf, shape, string } from 'prop-types';
import cn from 'classnames';
import { Icon, QueryLoading } from '@frx/frx-components';

import styles from './Button.module.scss';

export const Button = ({
  children,
  className,
  disabled,
  iconProps,
  isfullWidth,
  isLoading = false,
  onClick,
  selected,
  variant,
  xs = false,
  ...rest
}) => {
  const buttonType = {
    [styles.disabledButton]: disabled,
    [styles.fullWidth]: isfullWidth,
    [styles.primaryButton]: variant === 'primary' || selected,
    [styles.secondaryButton]: variant === 'secondary',
    [styles.donateButton]: variant === 'donate',
    [styles.textVariant]: variant === 'text',
    [styles.xs]: xs,
  };

  const buttonStyles = cn(
    styles.button,
    buttonType,
    className,
    styles[iconProps && iconProps.position]
  );

  const hasChildren = Boolean(React.Children.count(children));

  return (
    <button
      {...rest}
      {...{ className: buttonStyles, disabled: disabled || isLoading, onClick }}
      aria-label='a-button'
    >
      {iconProps && (
        <Icon
          className={styles.iconStyles}
          color={iconProps.color}
          name={iconProps.name}
          size={iconProps.size}
          viewBox={iconProps.viewBox}
        />
      )}
      {hasChildren && (
        <div>
          {isLoading ? <QueryLoading className={styles.loader} /> : children}
        </div>
      )}
    </button>
  );
};

Button.propTypes = {
  children: node,
  className: string,
  disabled: bool,
  iconProps: shape({
    color: string,
    name: string,
    position: oneOf(['bottom', 'left', 'right', 'top']),
    size: number,
    viewBox: string,
  }),
  isfullWidth: bool,
  isLoading: bool,
  onClick: func,
  selected: bool,
  variant: oneOf(['donate', 'primary', 'secondary', 'text']),
  xs: bool,
};

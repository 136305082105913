import React from 'react';
import { array, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Overlay } from '../../../components';
import {
  MultiSingle,
  MultiTimeField,
  PhoneField,
  ShortText,
  SingletTechField,
} from '.';
import {
  camelCase,
  getPartTypeFromCode,
  isCountryUSA,
  isEqual,
  useAuth,
  useQueryString,
  useRegistrationDataProvider,
} from '../../../utils';

import styles from './questions.module.scss';

/** question keys */
export const QUESTION_KEYS = Object.freeze({
  ALSAC_EMPLOYEE: 'alsac_employee',
  BIB_NAME: 'Bib_Name',
  BOSTON_MARATHON_GOAL: 'BOSTON_MARATHON_GOAL',
  CHALLENGE_TRANSFER: 'Challenge_Transfer',
  COUNTRY_REPRESENTED: 'Country_Represented',
  DD_ALL_IN: 'dd_all_in_for_StJude',
  EMERGENCY_RELATIONSHIP: 'Emergency_Relationship',
  EMPLOYER_NAME: 'EMPLOYER_NAME',
  FINISH_TIME_HOUR: 'FINISH_TIME_HOUR',
  FINISH_TIME_MINUTES: 'FINISH_TIME_MINUTES',
  INPERSON_FOUR_CHALLENGE: 'InPerson_Distance_FourChallenge',
  MILITARY_RESPONDER: 'Military_First_Responder',
  MOBILE_PHONE: 'MOBILE_PHONE',
  EMERGENCY_PHONE: 'Emergency_Phone',
  PATIENT_FAM: 'Is_Patient_Fam',
  PLATINUM_PREMIER_WAIVER: 'PLATINUM_PREMIER_WAIVER',
  QKEY_BOSTON: 'QKEY_Boston',
  QKEY_DISTANCEONE: 'QKEY_DISTANCEONE',
  QKEY_DISTANCETWO: 'QKEY_DISTANCETWO',
  QKEY_DISTANCETWO_VIRTUAL: 'QKEY_DISTANCETWO_VIRTUAL',
  QKEY_WAITLIST: 'QKEY_Waitlist',
  RACE_DISTANCE: 'Race_Distance',
  SHIRT_SIZE: 'SHIRT SIZE',
  SINGLET_TECH_SHIRT: 'Singlet_Tech_Shirt',
  SINGLET_TECH: 'Singlet_Tech',
  TIMES_PARTICIPATED: 'Times_Participated_SJMMW',
  VIRTUAL_ACKNOWLEDGE: 'Virtual_Acknowledge',
  YN_ALL_IN: 'yn_all_in_for_StJude',
  AGE: 'QKEY_CPA_AGE',
});

const RegularQuestionToComponent = ({
  question = {},
  className,
  overlayCommands,
}) => {
  const {
    user: { firstName, lastName },
  } = useAuth();
  const {
    queryString: { code },
  } = useQueryString();
  const {
    data: { personalFormData },
  } = useRegistrationDataProvider();
  const {
    key: questionKey,
    questionId,
    questionRequired,
    questionText,
    questionTypeData,
  } = question;
  const { commitment } = getPartTypeFromCode(code);
  const { t } = useTranslation();
  const required = questionRequired === 'true';
  const options =
    questionTypeData &&
    questionTypeData.surveyQuestionData &&
    questionTypeData.surveyQuestionData.availableAnswer;
  const {
    ALSAC_EMPLOYEE,
    BIB_NAME,
    BOSTON_MARATHON_GOAL,
    CHALLENGE_TRANSFER,
    COUNTRY_REPRESENTED,
    EMERGENCY_RELATIONSHIP,
    EMPLOYER_NAME,
    INPERSON_FOUR_CHALLENGE,
    MILITARY_RESPONDER,
    MOBILE_PHONE,
    PATIENT_FAM,
    QKEY_BOSTON,
    QKEY_DISTANCEONE,
    QKEY_DISTANCETWO,
    QKEY_DISTANCETWO_VIRTUAL,
    QKEY_WAITLIST,
    RACE_DISTANCE,
    TIMES_PARTICIPATED,
    VIRTUAL_ACKNOWLEDGE,
    YN_ALL_IN,
    DD_ALL_IN,
    SHIRT_SIZE,
    PLATINUM_PREMIER_WAIVER,
  } = QUESTION_KEYS;

  const countryUSA = isCountryUSA(personalFormData && personalFormData.country);

  if (isEqual(questionKey, MOBILE_PHONE)) {
    return (
      <>
        <PhoneField
          {...{
            className,
            countryUSA,
            key: questionId,
            label: questionText,
            name: camelCase(questionKey),
          }}
        />
        <div className={styles.caption}>
          {t('screens.personal_info.mobile_caption')}
        </div>
      </>
    );
  }

  if (
    isEqual(questionKey, COUNTRY_REPRESENTED) ||
    isEqual(questionKey, PATIENT_FAM) ||
    isEqual(questionKey, EMERGENCY_RELATIONSHIP) ||
    isEqual(questionKey, TIMES_PARTICIPATED) ||
    isEqual(questionKey, QKEY_WAITLIST) ||
    isEqual(questionKey, BOSTON_MARATHON_GOAL) ||
    isEqual(questionKey, QKEY_BOSTON) ||
    isEqual(questionKey, SHIRT_SIZE) ||
    isEqual(questionKey, INPERSON_FOUR_CHALLENGE) ||
    isEqual(questionKey, ALSAC_EMPLOYEE)
  )
    return (
      <MultiSingle
        {...{
          className,
          key: questionId,
          label: questionText,
          name: camelCase(questionKey),
          options,
          required,
        }}
      />
    );

  if (isEqual(questionKey, QKEY_DISTANCEONE))
    return (
      <MultiSingle
        {...{
          className,
          key: questionId,
          label: questionText,
          name: camelCase(questionKey),
          options: options.filter(
            ({ label }) =>
              !isEqual(
                label,
                personalFormData && personalFormData.qkeyDistancetwo
              )
          ),
          required,
        }}
      />
    );

  if (isEqual(questionKey, QKEY_DISTANCETWO))
    return (
      <MultiSingle
        {...{
          className,
          key: questionId,
          label: questionText,
          name: camelCase(questionKey),
          options: options.filter(
            ({ label }) =>
              !isEqual(
                label,
                personalFormData && personalFormData.qkeyDistanceone
              )
          ),
          required,
        }}
      />
    );

  if (isEqual(questionKey, QKEY_DISTANCETWO_VIRTUAL))
    return (
      <MultiSingle
        {...{
          className,
          key: questionId,
          label: questionText,
          name: camelCase(questionKey),
          options: options.filter(
            ({ label }) =>
              !isEqual(
                label,
                personalFormData && personalFormData.qkeyDistanceone
              )
          ),
          required,
        }}
      />
    );

  if (questionKey === BIB_NAME)
    return (
      <ShortText
        {...{
          className,
          errorText: () => `${t('screens.personal_info.bib_error')}`,
          key: questionId,
          label: questionText,
          maxLength: 10,
          name: camelCase(questionKey),
          pattern: '[a-zA-Z\u00C0-\u024F]+',
          defaultValue: firstName?.substring(0, 10),
          required,
          autoComplete: 'off',
        }}
      />
    );

  if (questionKey === EMPLOYER_NAME)
    return (
      <ShortText
        {...{
          className,
          key: questionId,
          label: questionText,
          maxLength: 80,
          name: camelCase(questionKey),
          autoComplete: 'off',
        }}
      />
    );

  if (
    (commitment && questionKey === `${commitment.toUpperCase()}_WAIVER`) ||
    isEqual(questionKey, PLATINUM_PREMIER_WAIVER) ||
    isEqual(questionKey, VIRTUAL_ACKNOWLEDGE)
  )
    return (
      <ShortText
        {...{
          className,
          errorText: () =>
            `${t('screens.personal_info.enter_valid_text')} ${
              firstName.toUpperCase()[0]
            }${lastName.toUpperCase()[0]}`,
          key: questionId,
          label: questionText,
          maxLength: 4,
          minLength: 1,
          name: camelCase(questionKey),
          pattern: '[A-Z]+',
          placeholder: t('screens.personal_info.enter_init'),
          required,
        }}
      />
    );

  if (questionKey === RACE_DISTANCE)
    return (
      <ShortText
        {...{
          className,
          key: questionId,
          label: questionText,
          name: camelCase(questionKey),
          required,
        }}
      />
    );

  // TODO: Merge both MILATARY && CHALLENGE TRANSFER to be one condition once the final content for overlay done
  if (questionKey === MILITARY_RESPONDER)
    return (
      <MultiSingle
        {...{
          className,
          name: camelCase(questionKey),
          label: questionText,
          required,
          options,
          questionId,
        }}
        overlay={
          <Overlay
            placeholder={t('screens.personal_info.why_ask_this')}
            overlayCommands={overlayCommands}
            text={t('screens.personal_info.we_recognize_text')}
          />
        }
      />
    );

  if (questionKey === CHALLENGE_TRANSFER)
    return (
      <MultiSingle
        {...{
          className,
          name: camelCase(questionKey),
          label: questionText,
          required,
          options,
          questionId,
        }}
        overlay={
          <Overlay
            placeholder="What's this?"
            text='Lorem Ispsum'
            overlayCommands={overlayCommands}
          />
        }
      />
    );

  if (isEqual(questionKey, YN_ALL_IN))
    return (
      <MultiSingle
        {...{
          className,
          name: camelCase(questionKey),
          label: questionText,
          required,
          options,
          questionId,
        }}
        overlay={
          <Overlay
            placeholder="What's this?"
            text={`Be All In #forStJude! Join one of four national Heroes teams lead by one of our Heroes coaches. 
      From March through June, use your St. Jude Memphis Marathon Weekend St. Jude Hero fundraising page and participate, 
      compete and fundraise in monthly challenges to win awesome St. Jude prizes. Stay fit while you train for your race in December!`}
            overlayCommands={overlayCommands}
          />
        }
      />
    );

  if (
    isEqual(questionKey, DD_ALL_IN) &&
    personalFormData &&
    personalFormData.ynAllInForStjude === 'Yes'
  )
    return (
      <MultiSingle
        {...{
          className,
          key: questionId,
          label: questionText,
          name: camelCase(questionKey),
          options,
          required,
        }}
      />
    );

  return null;
};

RegularQuestionToComponent.propTypes = {
  question: object,
  className: string,
  overlayCommands: object,
};

export const Questions = ({ className, questions, overlayCommands }) => {
  const {
    FINISH_TIME_HOUR,
    FINISH_TIME_MINUTES,
    SINGLET_TECH,
    SINGLET_TECH_SHIRT,
  } = QUESTION_KEYS;
  const timeQuestionsKeys = new Set([FINISH_TIME_HOUR, FINISH_TIME_MINUTES]);
  const timeQuestions = questions.filter(({ key }) =>
    timeQuestionsKeys.has(key)
  );

  const singletTechKeys = new Set([SINGLET_TECH, SINGLET_TECH_SHIRT]);
  const singletTechQuestions = questions.filter(({ key }) =>
    singletTechKeys.has(key)
  );
  const mainQuestion = singletTechQuestions.filter(
    ({ key }) => key === SINGLET_TECH
  );
  const secondaryQuestion = singletTechQuestions.filter(
    ({ key }) => key === SINGLET_TECH_SHIRT
  );

  return questions.map((question) => (
    <React.Fragment key={question.questionId}>
      {timeQuestions.length === timeQuestionsKeys.size &&
        question.key === timeQuestions[1].key && (
          <MultiTimeField
            {...{ timeQuestions }}
            finishTimeHourKey={FINISH_TIME_HOUR}
          />
        )}
      {singletTechQuestions.length === singletTechKeys.size &&
        question.key === singletTechQuestions[1].key && (
          <SingletTechField
            {...{ mainQuestion, secondaryQuestion }}
            overlayCommands={overlayCommands}
          />
        )}
      <RegularQuestionToComponent
        {...{ className, question }}
        overlayCommands={overlayCommands}
      />
    </React.Fragment>
  ));
};

Questions.propTypes = {
  className: string,
  questions: array,
  overlayCommands: object,
};

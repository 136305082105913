import React from 'react';
import { node } from 'prop-types';
import { useIsFetching, useMutation, useQueries, useQuery } from 'react-query';
import { QueryLoading } from '@frx/frx-components';
import { useQueryString } from '../../../QueryStrings';
import { CompanyPageContext } from './CompanyContext';
import { useConfiguration } from '../../../configuration/ConfigurationContext';
import {
  getCompaniesByInfo,
  getCompanyPageInfo,
  getParticipants,
  getTeamsByInfo,
  updateCompany,
  updateCompanyRichText,
} from '../../../services';
import { LOADING_LABEL } from '../../../../app.constants';

export const CompanyPageProvider = ({ children }) => {
  /** State */
  const [goalModal, setGoalModal] = React.useState(false);
  const [richTextModal, setRichTextModal] = React.useState(false);
  const [titleModal, setTitleModal] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState();

  /** hooks */
  const isFetching = useIsFetching();
  const {
    queryString: { fr_id, company_id },
  } = useQueryString();
  const {
    settings: {
      luminate: {
        properties: { user },
      },
    },
  } = useConfiguration();

  /** constants */
  const defaultMutationOptions = {
    fr_id,
    company_id,
    auth: user?.token,
  };

  /** queries */
  const companyInfo = useQuery(['companiesByInfo', { company_id }], () =>
    getCompaniesByInfo({ company_id })
  );
  const teamsByInfo = useQuery(['teamsByInfo'], () =>
    getTeamsByInfo({
      team_company_id: company_id,
      list_sort_column: 'total',
      list_ascending: false,
    })
  );
  const coordinatorId = companyInfo.data?.company?.coordinatorId;
  const existingTeams = teamsByInfo.data?.team;
  const companyPageInfo = useQuery(
    ['companyPageInfo', { coordinatorId }],
    () => getCompanyPageInfo({ company_id, cons_id: coordinatorId }),
    { enabled: !!coordinatorId }
  );
  const companyParticipants = useQueries(
    (existingTeams || []).map((et) => ({
      queryKey: ['participant', et.id],
      queryFn: () =>
        getParticipants({
          first_name: '%%%',
          list_filter_column: 'reg.team_id',
          list_filter_text: et.id,
        }),
      enabled: !!existingTeams,
    }))
  );

  /** mutations */
  const { mutate: mutateCompanyTitle } = useMutation((title) =>
    updateCompany({ ...defaultMutationOptions, company_name: title })
  );
  const { mutate: mutateCompanyGoal } = useMutation((goal) =>
    updateCompany({
      ...defaultMutationOptions,
      company_fundraising_goal: goal,
    })
  );
  const { mutate: mutateCompanyRichText } = useMutation((richText) =>
    updateCompanyRichText({ ...defaultMutationOptions, rich_text: richText })
  );

  if (isFetching) return <QueryLoading label={LOADING_LABEL} />;

  /** functions */
  const handleGoalModal = () => setGoalModal((prevState) => !prevState);
  const handleGoalModalCancel = () => {
    handleGoalModal();
    setErrorMessage();
  };
  const handleUpdateGoal = (goal) =>
    mutateCompanyGoal(goal, {
      onSettled: (data) => {
        if (!data.code) {
          companyInfo.refetch();
          handleGoalModal();
        } else {
          setErrorMessage(data?.message);
        }
      },
    });

  const handleRichTextModal = () => setRichTextModal((prevState) => !prevState);
  const handleRichTextModalCancel = () => {
    handleRichTextModal();
    setErrorMessage();
  };
  const handleUpdateRichText = (richText) =>
    mutateCompanyRichText(richText, {
      onSettled: (data) => {
        if (!data.code) {
          companyPageInfo.refetch();
          handleRichTextModal();
        } else {
          setErrorMessage(data?.message);
        }
      },
    });

  const handleTitleModal = () => setTitleModal((prevState) => !prevState);
  const handleTitleModalCancel = () => {
    handleTitleModal();
    setErrorMessage();
  };
  const handleUpdateCompanyTitle = (title) =>
    mutateCompanyTitle(title, {
      onSettled: (data) => {
        if (!data.code) {
          companyInfo.refetch();
          handleTitleModal();
        } else {
          setErrorMessage(data?.message);
        }
      },
    });

  const context = {
    commands: {
      handleGoalModal,
      handleGoalModalCancel,
      handleRichTextModal,
      handleRichTextModalCancel,
      handleTitleModal,
      handleTitleModalCancel,
      handleUpdateCompanyTitle,
      handleUpdateGoal,
      handleUpdateRichText,
    },
    data: {
      companyInfo: companyInfo.data?.company,
      companyPageInfo: companyPageInfo.data?.companyPage,
      companyParticipants,
      errorMessage,
      goalModal,
      richTextModal,
      teamsByInfo: existingTeams,
      titleModal,
    },
  };

  return (
    <CompanyPageContext.Provider value={context}>
      {children}
    </CompanyPageContext.Provider>
  );
};

CompanyPageProvider.propTypes = {
  children: node,
};

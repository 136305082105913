import React from 'react';
import { bool } from 'prop-types';
import { isEqual, useQueryString } from '../../../utils';
import { JoinTeam } from './JoinTeam';
import { CreateTeam } from './CreateTeam';

export const TeamOptions = ({ showSidebar }) => {
  const {
    queryString: { team },
  } = useQueryString();
  if (isEqual(team, 'create-team'))
    return <CreateTeam showSidebar={showSidebar} />;
  if (isEqual(team, 'join-team')) return <JoinTeam showSidebar={showSidebar} />;
};

TeamOptions.propTypes = {
  showSidebar: bool,
};

import React from 'react';
import { useRegistrationDataProvider } from '../../AppDataContext';
import { useConfiguration } from '../../configuration/ConfigurationContext';
import { useQueryString } from '../../QueryStrings';
import { useAuth } from '../../security/SecurityContext';
import { getIndexForMonth, isEqual } from '../../utils';
import { useRegTotalToPay } from './PaymentHooks';
import { ROUTE_KEYS } from '../../../app.constants';

const PAYMENT_TYPES = {
  CREDIT: 'credit',
  PAYPAL: 'paypal',
};

const fetcherReg = async (url, stepData) => {
  const body = new URLSearchParams();

  for (const key of Object.getOwnPropertyNames(stepData)) {
    body.append(key, stepData[key]);
  }

  const options = {
    credentials: 'include',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded;charset=UTF-8',
      Accept:
        'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,*/*;q=0.8',
      'Accept-Language': 'en-US,en;q=0.5',
      'Upgrade-Insecure-Requests': '1',
      Pragma: 'no-cache',
      'Cache-Control': 'no-cache',
    },
    mode: 'cors',
    method: 'POST',
    body,
  };

  return await fetch(url, options);
};

export const useSubmitRegistration = () => {
  const [refreshToggle, setRefreshToggle] = React.useState(false);
  const { settings } = useConfiguration();
  const {
    queryString: { code, fr_id },
  } = useQueryString();
  const {
    commands: { resetStorage },
  } = useRegistrationDataProvider();

  const refresh = () => setRefreshToggle((prevState) => !prevState);

  const startTransaction = (team = '', stepData = {}) => {
    const teamOptionValue = isEqual(team, 'individual')
      ? 'fr_tm_opt=none'
      : isEqual(team, 'create-team')
        ? 'fr_tm_opt=new'
        : '';

    return fetcherReg(
      `${settings.luminate.url}/TRR/Heroes/Heroes?${teamOptionValue}&pg=tfind&fr_id=${fr_id}&s_promoCode=${code}&skip_login_page=true`,
      stepData
    );
  };

  const postStep = async (transactionId, stepData = {}) =>
    await fetcherReg(
      `${settings.luminate.url}/TRR/${transactionId}`,
      stepData
    ).then((res) => {
      /** TODO: this conditation is redudant. We need to wait until
       * figure out the best way for thank you page on login */
      if (res) {
        const pgValue = new URL(res.url).searchParams.get('pg');
        if (isEqual('rthanks', pgValue)) {
          resetStorage();
          return (window.location.href = `${res.url}`);
        }
        return res.text();
      }
    });

  return { postStep, refresh, refreshToggle, startTransaction };
};

export const useJoinTeam = () => {
  const {
    commands: { setTransactionId, setJoinTeamId },
  } = useRegistrationDataProvider();
  const { startTransaction, postStep } = useSubmitRegistration();
  const { redirect } = useQueryString();

  const joinTeamFromExternalLink = async (team, teamId, fr_id, pagename) => {
    await startTransaction(team)
      .then((res) => {
        if (res) {
          const pgValue = new URL(res.url).searchParams.get('pg');
          if (isEqual('rthanks', pgValue)) {
            return (window.location.href = `${res.url}`);
          }
          return res.text();
        }
      })
      .then((data) => {
        const urlFromString = new DOMParser()
          .parseFromString(data, 'text/html')
          .querySelector('form');
        const newUrl = urlFromString.action.split('/');
        const uniqId = newUrl[newUrl.length - 1];
        setTransactionId(uniqId);
        return postStep(uniqId, {
          pg: 'tfind',
          fr_id,
          fr_tjoin: `${teamId}`,
          skip_login_page: 'false',
        });
      })
      .then((response) => {
        setJoinTeamId(teamId);
        redirect(`/${ROUTE_KEYS.PARTICIPATION}`, {
          pagename,
          fr_id,
          fr_tjoin: `${teamId}`,
          teamId,
        });
      });
  };

  return { joinTeamFromExternalLink };
};

export const usePayment = () => {
  const [creditErrorMessage, setCreditErrorMessage] = React.useState();
  const [isLoading, setIsLoading] = React.useState(false);
  const {
    queryString: { fr_id },
  } = useQueryString();
  const {
    user: { firstName, lastName },
  } = useAuth();
  const {
    data: { personalFormData, transactionId },
  } = useRegistrationDataProvider();
  const { postStep } = useSubmitRegistration();
  const { total } = useRegTotalToPay();

  const handlePayment = async (paymentInfo = {}) => {
    if (Number(total) > 0) {
      if (paymentInfo.isPaypalType) {
        if (paymentInfo.hiddenFormInput.current)
          return paymentInfo.hiddenFormInput.current.submit();
      }

      if (paymentInfo.paymentFormData && paymentInfo.isCreditType) {
        setIsLoading(true);
        await postStep(transactionId, {
          pg: 'paymentForm',
          fr_id,
          responsive_payment_typepay_typeradio: PAYMENT_TYPES.CREDIT,
          responsive_payment_typepay_typeradiosubmit: 'true',
          responsive_payment_typesubmit: 'true',
          billing_title: '',
          billing_titlesubmit: 'true',
          responsive_payment_typecc_typesubmit: 'true',
          responsive_payment_typecc_numbername:
            paymentInfo.paymentFormData.cardNumber,
          responsive_payment_typecc_numbersubmit: 'true',
          responsive_payment_typecc_exp_date_MONTH: `${getIndexForMonth(
            paymentInfo.paymentFormData.month
          )}`,
          responsive_payment_typecc_exp_date_YEAR:
            paymentInfo.paymentFormData.year,
          responsive_payment_typecc_exp_date_DAY: '1',
          responsive_payment_typecc_exp_datesubmit: 'true',
          responsive_payment_typecc_cvvname:
            paymentInfo.paymentFormData.cvvNumber,
          responsive_payment_typecc_cvvsubmit: 'true',
          billing_first_namename:
            paymentInfo.paymentFormData.billingFirstName || firstName,
          billing_first_namesubmit: 'true',
          billing_last_namename:
            paymentInfo.paymentFormData.billingLastName || lastName,
          billing_last_namesubmit: 'true',
          billing_suffix: '',
          billing_suffixsubmit: 'true',
          billing_addr_street1name:
            paymentInfo.paymentFormData.address1 || personalFormData.address1,
          billing_addr_street1submit: 'true',
          billing_addr_street2name: '',
          billing_addr_street2submit: 'true',
          billing_addr_cityname:
            paymentInfo.paymentFormData.city || personalFormData.city,
          billing_addr_citysubmit: 'true',
          billing_addr_state:
            paymentInfo.paymentFormData.state || personalFormData.state,
          billing_addr_statesubmit: 'true',
          billing_addr_zipname:
            paymentInfo.paymentFormData.zip || personalFormData.zip,
          billing_addr_zipsubmit: 'true',
          billing_addr_country:
            paymentInfo.paymentFormData.country || personalFormData.country,
          billing_addr_countrysubmit: 'true',
          btn_next: 'Process Payment',
        }).then((res) => {
          setIsLoading(false);
          /** This is to get the error message for credit card */
          const formElement = new DOMParser()
            .parseFromString(res, 'text/html')
            .querySelector('.field-error-text');
          const errorMessage = formElement && formElement.textContent;
          setCreditErrorMessage(errorMessage);
        });
      }
    }
  };

  return { creditErrorMessage, handlePayment, isLoading };
};

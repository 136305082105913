import React from 'react';
import { func } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { Overlay, Screen, StepButton, StepHeader } from '../../components';
import styles from './Attendance.module.scss';
import { useQueryString } from '../../utils';

export const Attendance = ({ onSelection }) => {
  const handleButtonClick = (attendance) => () => onSelection({ attendance });

  const [activeOverlay, setActiveOverlay] = React.useState();
  const overlayCommands = { activeOverlay, setActiveOverlay };
  const {
    queryString: { reg_type },
  } = useQueryString();
  const { t } = useTranslation();

  return (
    <Screen className={styles.container}>
      <StepHeader />
      <h2 className={styles.attendanceHeader}>
        {t('screens.attendance.choose_participate_text')}:
      </h2>
      <div className={styles.buttons}>
        <StepButton
          onClick={handleButtonClick('InPerson')}
          title={t('screens.attendance.participate_inperson_text')}
          subtitle={t('screens.attendance.participate_inperson_desc_text')}
          data-tracking='participation-in-person'
        />
        <StepButton
          onClick={handleButtonClick('Virtual')}
          title={t('screens.attendance.participate_virtual_text')}
          subtitle={t('screens.attendance.participate_virtual_desc_text')}
          data-tracking='participation-virtual'
        />
      </div>

      <p>
        <Overlay
          placeholder={t('screens.cancellation_policy_text')}
          title={t('screens.cancellation_policy_text')}
          text={t(
            reg_type?.toUpperCase()?.includes('HEROES')
              ? 'screens.personal_info.cancellation_policy_heroes'
              : 'screens.personal_info.cancellation_policy_general'
          )}
          overlayCommands={overlayCommands}
        />
      </p>
    </Screen>
  );
};

Attendance.propTypes = {
  onSelection: func,
};

import React from 'react';
import { string } from 'prop-types';
import DayJS from 'dayjs';
import i18n from 'i18next';
import { useTranslation } from 'react-i18next';
import customParseFormat from 'dayjs/plugin/customParseFormat';
import {
  getAgeFromRequirements,
  getDateOfBirthOptions,
  getPartTypeFromCode,
  isEqual,
  useAuth,
  useQueryString,
  useRegistrationDataProvider,
} from '../../../../utils';
import { DateInput } from '../../../../components';
import 'dayjs/locale/es';

export const DateOfBirthField = ({ className }) => {
  const {
    user: { dateOfBirth },
  } = useAuth();
  const {
    queryString: { code },
  } = useQueryString();
  const {
    commands: { setAgeConfig },
    data: { personalFormData, selectedParticipationType },
  } = useRegistrationDataProvider();
  const { day, month, year } = dateOfBirth || { day: '', month: '', year: '' };
  const { t } = useTranslation();
  const lang = i18n.language.slice(0, -3);

  const dayValue = (personalFormData && personalFormData.day) || day;
  const monthValue = (personalFormData && personalFormData.month) || month;
  const yearValue = (personalFormData && personalFormData.year) || year;

  function isValiAgeForRace(distanceType, ageOnRaceDay) {
    if (distanceType) {
      const minimumRequiredAge = getAgeFromRequirements(distanceType);
      return ageOnRaceDay >= minimumRequiredAge;
    }
    return true;
  }

  /**
   * @description This is not included in form
   * Minimum age requirements logic */
  const raceRequirementAge = getAgeFromRequirements(
    selectedParticipationType.distance
  );
  React.useEffect(() => {
    if (dayValue && monthValue && yearValue) {
      DayJS.extend(customParseFormat);
      DayJS.locale(lang);
      let dayJsmonth = '';
      if (isEqual(lang, 'es') && monthValue.length > 3) {
        dayJsmonth = monthValue.slice(0, monthValue.length - 1);
      } else {
        dayJsmonth = monthValue;
      }
      /* const dayJsmonth = isEqual(lang, 'es')
        ? monthValue.slice(0, monthValue.length - 1)
        : monthValue; */
      const birthDate = DayJS(
        `${dayJsmonth} ${dayValue} ${yearValue}`,
        'MMM DD YYYY',
        lang
      );
      const eventDate = DayJS('Dec 07 2024');
      const dateToCheck = eventDate.subtract(raceRequirementAge, 'year');
      const dateDifference = dateToCheck.diff(birthDate, 'day');
      const ageOnRaceDay = eventDate.diff(birthDate, 'year');
      const isBirthdayBeforeEvent = eventDate.diff(birthDate) >= 0;

      if (!raceRequirementAge) {
        const { distance: distanceFromUrl } = getPartTypeFromCode(code);
        setAgeConfig({
          ageOnRaceDay: isBirthdayBeforeEvent ? Math.floor(ageOnRaceDay) : -1,
          hasValidBirthDate: isBirthdayBeforeEvent && dateDifference >= 0,
          requiredAge: Math.floor(raceRequirementAge),
        });
        if (
          isEqual(distanceFromUrl, '2-Race-Challenge') ||
          isEqual(distanceFromUrl, '4-Race-Challenge')
        ) {
          if (personalFormData) {
            const isValidAgeForRaceOne = isValiAgeForRace(
              personalFormData.qkeyDistanceone,
              ageOnRaceDay
            );
            const isValidaAgeForRaceTwo = isValiAgeForRace(
              personalFormData.qkeyDistancetwo,
              ageOnRaceDay
            );
            const minAgeForRaceOne = Math.floor(
              getAgeFromRequirements(personalFormData.qkeyDistanceone)
            );
            const minAgeForRaceTwo = Math.floor(
              getAgeFromRequirements(personalFormData.qkeyDistancetwo)
            );
            setAgeConfig({
              ageOnRaceDay: isBirthdayBeforeEvent
                ? Math.floor(ageOnRaceDay)
                : -1,
              hasValidBirthDate:
                isBirthdayBeforeEvent &&
                isValidAgeForRaceOne &&
                isValidaAgeForRaceTwo,
              requiredAge: Math.max(minAgeForRaceOne, minAgeForRaceTwo),
            });
          }
        }
      } else {
        setAgeConfig({
          ageOnRaceDay: isBirthdayBeforeEvent ? Math.floor(ageOnRaceDay) : -1,
          hasValidBirthDate: isBirthdayBeforeEvent && dateDifference >= 0,
          requiredAge: Math.floor(raceRequirementAge),
        });
      }
    }
  }, [
    code,
    dayValue,
    lang,
    monthValue,
    personalFormData,
    raceRequirementAge,
    setAgeConfig,
    yearValue,
  ]);

  return (
    <DateInput
      className={className}
      columnConfig={getDateOfBirthOptions()}
      day={String(dayValue)}
      label={t('screens.personal_info.date_of_birth')}
      month={monthValue}
      required
      year={yearValue}
    />
  );
};

DateOfBirthField.propTypes = {
  className: string,
};

import React from 'react';

const defaultContext = Object.freeze({
  commands: {
    handleSelection: () => undefined,
    resetStorage: () => undefined,
    setAgeConfig: () => undefined,
    setBringTeamId: () => undefined,
    setDiscountAmount: () => undefined,
    setDiscountCode: () => undefined,
    setFundraisingGoal: () => undefined,
    setJoinTeamId: () => undefined,
    setKickStartAmount: () => undefined,
    setKickStartFieldValue: () => undefined,
    setPersonalFormData: () => undefined,
    setTeamsFormData: () => undefined,
    setUpsells: () => undefined,
    setPaymentFormData: () => undefined,
    setTransactionId: () => undefined,
    setWaiverOption: () => undefined,
  },
  data: {
    activeTab: undefined,
    ageConfig: {},
    bringTeamId: undefined,
    companyList: [],
    discountAmount: undefined,
    discountCode: undefined,
    eventInfo: undefined,
    distanceData: undefined,
    fundraisingGoal: undefined,
    joinTeamId: undefined,
    kickStartAmount: undefined,
    kickStartFieldValue: undefined,
    participationData: [],
    participationLimits: {},
    paymentFormData: undefined,
    personalFormData: {},
    selectedParticipationType: {},
    teamRaiserConfig: undefined,
    teamsFormData: {},
    upsells: [],
    transactionId: undefined,
    waiverOption: undefined,
  },
});

export const AppDataContext = React.createContext(defaultContext);

export const useRegistrationDataProvider = () => {
  const context = React.useContext(AppDataContext);

  if (context === undefined) {
    throw new Error(
      'useRegistrationDataProvider should be in RegistrationDataProvider'
    );
  }

  return context;
};

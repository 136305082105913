import React from 'react';
import { bool, string, object } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { CheckboxField, Overlay } from '../../../../components';
import { useQueryString } from '../../../../utils';

import styles from './WaiverField.module.scss';

export const WaiverField = ({ waiver, overlayCommands, ...rest }) => {
  const {
    queryString: { reg_type },
  } = useQueryString();
  const { t } = useTranslation();
  const label = () => (
    <>
      <span>{t('screens.personal_info.i_agree_to')}</span>
      <Overlay
        placeholder={t('screens.personal_info.terms_and_cond')}
        title={t('screens.personal_info.terms_and_cond')}
        text={waiver}
        overlayCommands={overlayCommands}
      />
      <span>{t('screens.personal_info.have_read')}</span>
      <Overlay
        placeholder={t('screens.cancellation_policy_text')}
        title={t('screens.cancellation_policy_text')}
        text={t(
          reg_type?.toUpperCase()?.includes('HEROES')
            ? 'screens.personal_info.cancellation_policy_heroes'
            : 'screens.personal_info.cancellation_policy_general'
        )}
        overlayCommands={overlayCommands}
      />
    </>
  );

  return (
    <CheckboxField
      {...rest}
      className={styles.container}
      header={t('screens.personal_info.waiver_title')}
      name='waiver'
      label={label()}
      required
    />
  );
};

WaiverField.propTypes = {
  defaultValue: bool,
  waiver: string,
  overlayCommands: object,
};

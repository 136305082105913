import React from 'react';
import { array, string } from 'prop-types';
import { useTranslation } from 'react-i18next';
import { SelectField } from '../../../../components/forms';
import { camelCase, useRegistrationDataProvider } from '../../../../utils';
import { Label } from '../../../../components';

import styles from './MultiTimeField.module.scss';

export const MultiTimeField = ({ timeQuestions = [], finishTimeHourKey }) => {
  const {
    data: { personalFormData },
  } = useRegistrationDataProvider();
  const { t } = useTranslation();

  const hours = personalFormData && personalFormData.finishTimeHour;
  const isRequired =
    timeQuestions.length &&
    timeQuestions.some((tq) => tq.questionRequired === 'true');

  return (
    <div
      className={styles.multiTimeContainer}
      data-testid='MultiTimeField'
      key={timeQuestions[0].questionId}
    >
      <Label
        required={isRequired}
        text={t('screens.personal_info.est_fin_time')}
      >
        <div className={styles.hoursContainer}>
          {timeQuestions.map((q) => {
            const defaultValue =
              personalFormData && personalFormData[camelCase(q.key)];
            const dropdownItems =
              q.questionTypeData.surveyQuestionData.availableAnswer;
            const isHourDropdown = q.key === finishTimeHourKey;
            const isHourZero = hours === '00';

            return (
              <SelectField
                {...{ defaultValue }}
                aria-label={q.questionText}
                /* to do: spanish translation for this set */
                label={q.questionText}
                key={q.questionId}
                name={camelCase(q.key)}
                options={
                  !isHourDropdown && isHourZero
                    ? dropdownItems.filter(({ value }) => value !== '00')
                    : dropdownItems
                }
                placeholder={t('screens.personal_info.select')}
                required={isRequired}
              />
            );
          })}
        </div>
      </Label>
    </div>
  );
};

MultiTimeField.propTypes = {
  finishTimeHourKey: string,
  timeQuestions: array,
};

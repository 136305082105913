import React from 'react';
import PropTypes from 'prop-types';
import { useConfiguration } from '../configuration/ConfigurationContext';
import { QueryContext } from './QueryContext';

export const createSingleFetchPromise = async (
  configuration,
  queryPath,
  params,
  signal
) => {
  const [apiName, queryName] = queryPath.split('/');

  const body = new URLSearchParams();
  body.append('v', '1.0');
  body.append('api_key', configuration.settings.luminate.apiKey);
  body.append(
    's_locale',
    configuration.settings.luminate.properties.user.locale
  );
  body.append('response_format', 'json');
  body.append('method', queryName);

  for (const key of Object.getOwnPropertyNames(params)) {
    if (params[key]) body.append(key, params[key]);
  }

  const fetchOptions = {
    method: 'POST',
    headers: {
      'content-type': 'application/x-www-form-urlencoded; charset=UTF-8',
    },
    signal,
    body,
  };

  const url = `${configuration.settings.luminate.url}/${apiName}`;

  const response = await fetch(url.toString(), fetchOptions);
  return await response.json();
};

const createFetcher = (configuration) => ({
  get: (queryPath, params = {}) => {
    const controller = new AbortController();
    const promise = createSingleFetchPromise(
      configuration,
      queryPath,
      params,
      controller.signal
    );

    promise.cancel = () => {
      controller.abort();
    };

    return promise;
  },
  getAll: (queryPath, listOfParams = []) => {
    const controller = new AbortController();

    const listOfPromises = listOfParams.map((params) =>
      createSingleFetchPromise(
        configuration,
        queryPath,
        params,
        controller.signal
      )
    );

    const promise = Promise.all(listOfPromises);

    promise.cancel = () => controller.abort();

    promise.isCancelled = () => controller.signal.aborted;

    return promise;
  },
});

const FetchQueryProvider = (props) => {
  const configuration = useConfiguration();
  const fetcher = createFetcher(configuration);

  return (
    <QueryContext.Provider value={fetcher}>
      {props.children}
    </QueryContext.Provider>
  );
};

FetchQueryProvider.propTypes = {
  children: PropTypes.node,
};

export default FetchQueryProvider;

import React from 'react';

const defaultContext = {
  commands: {
    getSurveyId: () => undefined,
    getSurveyQuestions: () => undefined,
    submitSurvey: () => undefined,
  },
};
export const ContactPageContext = React.createContext(defaultContext);

export const useContactPageContext = () => {
  const context = React.useContext(ContactPageContext);

  if (context === undefined) {
    throw new Error(
      'useContactPageContext must be within an CacheQueryProvider'
    );
  }

  return context;
};

import { isEqual, trim } from 'lodash';
import { useTranslation } from 'react-i18next';

import { useRegistrationDataProvider } from '../../AppDataContext';
import { convertToNumber } from '../../utils';

export const useRegTotalToPay = () => {
  const {
    commands: { setDiscountAmount, setDiscountCode },
    data: {
      upsells,
      kickStartAmount,
      discountAmount,
      kickStartFieldValue,
      discountCode,
      selectedParticipationType: { fee },
    },
  } = useRegistrationDataProvider();
  const { t } = useTranslation(['options']);

  const clearDiscount = () => {
    if (discountAmount) setDiscountAmount();
    if (discountCode) setDiscountCode();
  };

  const discountAmountTotal = convertToNumber(discountAmount);

  const feeTotal =
    convertToNumber(fee) - discountAmountTotal <= 0
      ? 0
      : convertToNumber(fee) - convertToNumber(discountAmount);

  /* TODO: kick start amount conversion should be in top level */
  const newKickStartAmount = kickStartFieldValue
    ? convertToNumber(kickStartFieldValue)
    : isEqual(trim(kickStartAmount), `${t('other')}`) ||
        isEqual(trim(kickStartAmount), `${t('not_now')}`)
      ? 0
      : convertToNumber(kickStartAmount);

  const upsellsTotal = (upsells || []).reduce(
    (acc, u) => acc + convertToNumber(u.price),
    0
  );

  const total = (feeTotal + newKickStartAmount + upsellsTotal).toFixed(2);

  return {
    clearDiscount,
    discountAmountTotal,
    newKickStartAmount,
    total,
  };
};

import React from 'react';
import { array, bool, shape, string } from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { Label } from '../label/Label';
import { Fieldset, SelectField } from '../forms';
import { useRegistrationDataProvider } from '../../utils';

import styles from './DateInput.module.scss';

export const DateInput = ({
  className,
  columnConfig = {},
  hasDayField = true,
  day,
  label,
  month,
  year,
  ...rest
}) => {
  const {
    data: { ageConfig },
  } = useRegistrationDataProvider();
  const { monthNames, days, years } = columnConfig;
  const { t } = useTranslation();

  const dateInputContainer = cn(
    styles.container,
    !hasDayField && styles.hasNoDayField
  );

  let ageRaceElement;
  if (ageConfig) {
    const { ageOnRaceDay, hasValidBirthDate, requiredAge } = ageConfig;
    const hasNotValidAge = !hasValidBirthDate;
    const ageRaceContainer = cn(
      styles.ageRaceContainer,
      hasNotValidAge && styles.errorContainer
    );
    ageRaceElement = (
      <p className={ageRaceContainer}>
        {hasNotValidAge
          ? `${t('screens.personal_info.not_min_age1')} ${requiredAge} ${t(
              'screens.personal_info.not_min_age2'
            )}`
          : `${t('screens.personal_info.current_age')}: ${ageOnRaceDay}`}
      </p>
    );
  }

  return (
    <Fieldset {...{ className }} aria-label={label}>
      <Label
        {...{
          text: label,
          error: rest.isTouched && rest.isError,
          required: rest.required,
        }}
      >
        <div className={dateInputContainer}>
          <SelectField
            aria-label='Month'
            defaultValue={month}
            name='month'
            options={monthNames}
            placeholder={t('screens.personal_info.month')}
            label={t('screens.personal_info.month')}
            required
          />
          {hasDayField && (
            <SelectField
              aria-label='Day'
              defaultValue={day}
              name='day'
              options={days}
              placeholder={t('screens.personal_info.day')}
              label={t('screens.personal_info.day')}
              required
            />
          )}
          <SelectField
            aria-label='Year'
            defaultValue={year}
            name='year'
            options={years}
            placeholder={t('screens.personal_info.year')}
            label={t('screens.personal_info.year')}
            required
          />
        </div>
      </Label>
      {hasDayField && ageRaceElement}
    </Fieldset>
  );
};

DateInput.propTypes = {
  ...Fieldset.propTypes,
  columnConfig: shape({
    monthNames: array,
    days: array,
    years: array,
  }),
  day: string,
  hasDayField: bool,
  month: string,
  year: string,
};

const badges = {
  fivePlusDonations: {
    locked: require('./fivePlusDonationsLocked.svg'),
    unlocked: require('./fivePlusDonationsUnlocked.svg'),
  },
  halfwayToGoal: {
    locked: require('./halfwayToGoalLocked.svg'),
    unlocked: require('./halfwayToGoalUnlocked.svg'),
  },
  kickStartDonor: {
    locked: require('./kickStartDonorLocked.svg'),
    unlocked: require('./kickStartDonorUnlocked.svg'),
  },
};

export const getBadgeSrc = (badgeName, isUnlocked) => {
  const badge = badges[badgeName];
  return isUnlocked ? badge.unlocked.default : badge.locked.default;
};

import React from 'react';
import styles from './UnrevealError.module.scss';

const UnrevealError = () => (
  <div className={styles.alert} role='alert'>
    <p>
      An error has occurred. Our IT team has been notified and is working on
      resolving the issue.
    </p>

    <p>Steps to try: </p>

    <p>
      1.Clear your browser's cache: Instructions can be found{' '}
      <a href='https://support.google.com/accounts/answer/32050?hl=en&co=GENIE.Platform%3DDesktop'>
        here
      </a>
      <br /> 2.Try again later if the problem persists.
    </p>
  </div>
);

export default UnrevealError;

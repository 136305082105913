import React from 'react';
import { useIsFetching, useQuery } from 'react-query';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { isEmpty } from 'lodash';
import {
  Button,
  Hero,
  Pagination,
  QueryLoading,
  Tab,
  TabList,
  TabPanel,
  Tabs,
} from '@frx/frx-components';
import { ColumnList, Form, InputField } from '../../../components';
import { heroMarathonParticipantSearch } from '../../../assets';
import {
  convertCentsToDollars,
  getNumberOfPages,
  limit,
  useConfiguration,
  usePagination,
  useQueryString,
  useRegistrationDataProvider,
  useSortColumns,
  useJoinTeam,
  getDonationUrl,
} from '../../../utils';

import {
  getParticipants,
  getTeamsByInfo,
  getTeamMembers,
} from '../../../utils/services';
import {
  LOADING_LABEL,
  PAGINATION_BUTTON_LABELS,
} from '../../../app.constants';

import styles from './Search.module.scss';
import { ListCell, ListRow } from '../../../components/column-list';

const PER_PAGE = 25;
export const Search = () => {
  /* state */
  const [activeTab, setActiveTab] = React.useState(1);
  const [hasParticipantErrors, setParticipantErrors] = React.useState(true);
  const [hasTeamErrors, setTeamHasErrors] = React.useState(true);
  const [participantToSearch, setParticipantToSearch] = React.useState({
    firstName: '',
    lastName: '',
  });
  const [teamName, setTeamName] = React.useState('');
  const [isShowTeamMembers, setShowTeamMembers] = React.useState(true);
  const [isTypeAll, setType] = React.useState(false);
  const [isSeeAllFlag, setSeeAllFlag] = React.useState(true);

  /** hooks */
  const {
    pageIndex: partPageIndex,
    setPageIndex: setPartPageIndex,
    handleNext: handlePartNext,
    handlePrev: handlePartPrev,
  } = usePagination();
  const {
    pageIndex: teamPageIndex,
    setPageIndex: setTeamPageIndex,
    handleNext: handleTeamNext,
    handlePrev: handleTeamPrev,
  } = usePagination();
  const {
    queryString: { fr_id, team_id, type, reg_type },
  } = useQueryString();

  const {
    settings: {
      appSearchCriteria: { pagename },
    },
  } = useConfiguration();
  const {
    data: { eventInfo },
  } = useRegistrationDataProvider();
  const { joinTeamFromExternalLink } = useJoinTeam();

  const isAcceptingDonations = eventInfo?.accepting_donations === 'true';
  const isAcceptingRegistrations =
    eventInfo?.accepting_registrations === 'true';
  const { t } = useTranslation(['search']);

  /** queries */
  const isFetching = useIsFetching();
  const { data: participantSearchData } = useQuery(
    ['participantSearch', { partPageIndex, isTypeAll }],
    () =>
      getParticipants({
        first_name: isTypeAll ? '%%%' : participantToSearch.firstName,
        last_name: isTypeAll ? '%' : participantToSearch.lastName,
        list_ascending: true,
        list_page_offset: partPageIndex,
        list_sort_column: 'first_name',
      }).then((res) => {
        if (!res.code) {
          const { totalNumberResults } = res;
          return {
            ...res,
            participant: (res.participant || []).map((p) => ({
              ...p,
              participantFullName: `${p.name.first} ${p.name.last}`,
            })),
            hasMore:
              partPageIndex !==
              Math.ceil(Number(totalNumberResults) / PER_PAGE - 1),
          };
        }
        return res;
      }),
    { enabled: partPageIndex >= 0 && (!isShowTeamMembers || isTypeAll) }
  );

  const { data: teamMembersData } = useQuery(
    ['teamMembers'],
    () =>
      getTeamMembers({ fr_id, team_id }).then((res) => {
        if (res?.member?.length) {
          return {
            ...res,
            members: (res.member || []).map((p) => ({
              ...p,
              participantFullName: `${p.name.first} ${p.name.last}`,
            })),
            hasMore:
              partPageIndex !==
              Math.ceil(Number(res.member.length) / PER_PAGE - 1),
            totalNumberResults: res?.member?.length,
          };
        }
      }),
    { enabled: isShowTeamMembers }
  );

  const { data: teamSearchData } = useQuery(
    ['searchTeam', { teamPageIndex }],
    () =>
      getTeamsByInfo({
        list_page_offset: teamPageIndex,
        team_name: isTypeAll ? '%%%' : teamName,
      }).then((res) => {
        if (!res.code) {
          const { totalNumberResults } = res;
          return {
            ...res,
            team: (res.team || []).map((c) => ({
              ...c,
              captainName: `${c.captainFirstName} ${c.captainLastName}`,
            })),
            hasMore:
              teamPageIndex !==
              Math.ceil(Number(totalNumberResults) / PER_PAGE - 1),
          };
        }
        return res;
      }),
    { enabled: teamPageIndex >= 0 }
  );

  const { items: sortedParticipants, handleSort: handleParticipantSort } =
    useSortColumns(
      isShowTeamMembers && !isTypeAll
        ? teamMembersData?.members
        : participantSearchData?.participant
    );
  const { items: sortedTeams, handleSort: handleTeamSort } = useSortColumns(
    teamSearchData?.team
  );

  React.useEffect(() => {
    setType(isSeeAllFlag && (type === 'allp' || type === 'allt'));
    setActiveTab(isSeeAllFlag && type === 'allt' ? 2 : activeTab);
    setPartPageIndex(-1);
    setTeamPageIndex(-1);
  }, [
    activeTab,
    participantToSearch,
    setPartPageIndex,
    setTeamPageIndex,
    teamName,
    type,
    isSeeAllFlag,
  ]);

  /* functions */
  const handleTab = (tabIndex) => () => {
    setActiveTab(tabIndex);
    setSeeAllFlag(false);
  };
  const handlePartSearchChange = (fields, errors) => {
    setParticipantToSearch(fields);
    setParticipantErrors(errors);
    setSeeAllFlag(false);
  };

  const handlePartSearchSubmit = React.useCallback(() => {
    setShowTeamMembers(false);
    setType(false);
    setSeeAllFlag(false);
    setPartPageIndex(0);
  }, [setPartPageIndex]);

  React.useEffect(() => {
    if (isSeeAllFlag) {
      if (activeTab === 1) {
        setPartPageIndex(isShowTeamMembers || isTypeAll ? 0 : -1);
        if (!isTypeAll && !isShowTeamMembers) handlePartSearchSubmit();
      } else {
        setTeamPageIndex(isTypeAll ? 0 : -1);
      }
    }
  }, [
    isTypeAll,
    isShowTeamMembers,
    activeTab,
    isSeeAllFlag,
    setPartPageIndex,
    setTeamPageIndex,
    handlePartSearchSubmit,
  ]);

  const onPartNext = () => handlePartNext();
  const onPartPrev = () => handlePartPrev();
  const handleTeamSearchChange = (fields, errors) => {
    setTeamName(fields.teamName);
    setTeamHasErrors(errors);
    setSeeAllFlag(false);
  };
  const handleTeamSearchSubmit = () => setTeamPageIndex(0);
  const onTeamNext = () => handleTeamNext();
  const onTeamPrev = () => handleTeamPrev();
  const handleJoinTeam = (teamId) => () => {
    // Find a team / join a team
    joinTeamFromExternalLink('join-team', teamId, fr_id, pagename);
  };

  const { NEXT, PREVIOUS } = PAGINATION_BUTTON_LABELS;

  const hasFirstName = isEmpty(participantToSearch?.firstName);
  const hasLastName = isEmpty(participantToSearch?.lastName);
  const partSearchInputLength = (flag) => (flag ? 3 : 0);

  const renderPaginationText = (total, pageIndex) => {
    const numberOfPages = getNumberOfPages(total, PER_PAGE);
    const page = limit(pageIndex + 1, 0, numberOfPages) - 1;
    const from = limit(page * PER_PAGE + 1, 1, total);
    const to = limit(page * PER_PAGE + PER_PAGE, 1, total);
    return `Viewing ${from} - ${to} of ${total}`;
  };

  return (
    <main className='search-page'>
      <Hero
        className={styles.heroComponent}
        background={heroMarathonParticipantSearch}
        setHeight='45rem'
        backgroundPosition='bottom'
      >
        <div className={cn(styles.heroShadowOverlay)} />
        <div className={cn('container', styles.heroContainer)}>
          <div className='hero-body'>
            <p className={cn('title', 'is-size-1', styles.heroTitle)}>
              {t('find_participant')}
            </p>
          </div>
        </div>
      </Hero>
      <section className='section'>
        <div className='container'>
          <div className={cn('box', styles.box, styles.componentContainer)}>
            <Tabs {...{ activeTab }}>
              <TabList>
                <Tab
                  label={t('find_individual')}
                  id={1}
                  onClick={handleTab(1)}
                />
                <Tab label={t('find_team')} id={2} onClick={handleTab(2)} />
              </TabList>
              <div className={styles.tabPanelContainer}>
                <TabPanel className={styles.participantPanel} id={1}>
                  <Form
                    onSubmit={handlePartSearchSubmit}
                    onChange={handlePartSearchChange}
                  >
                    <div className={styles.searchParticipantContainer}>
                      <InputField
                        defaultValue={participantToSearch?.firstName}
                        errorText={() => t('errors.three_characters')}
                        label={t('first_name')}
                        minLength={partSearchInputLength(hasLastName)}
                        name='firstName'
                        required={hasLastName}
                        type='text'
                      />
                      <InputField
                        defaultValue={participantToSearch?.lastName}
                        errorText={() => t('errors.three_characters')}
                        label={t('last_name')}
                        minLength={partSearchInputLength(hasFirstName)}
                        name='lastName'
                        required={hasFirstName}
                        type='text'
                      />
                    </div>
                    <Button
                      type='submit'
                      buttonColor='blue'
                      disabled={hasParticipantErrors}
                      variant='primary'
                    >
                      {t('search')}
                    </Button>
                  </Form>
                  {isFetching ? (
                    <QueryLoading label={LOADING_LABEL} />
                  ) : (
                    <>
                      {isShowTeamMembers && !isTypeAll ? (
                        <ColumnList
                          data={sortedParticipants}
                          header={
                            <ListRow
                              key={`${activeTab}-${partPageIndex}`}
                              variant='header'
                            >
                              <ListCell
                                onClick={() =>
                                  handleParticipantSort('participantFullName')
                                }
                              >
                                {t('name')}
                              </ListCell>
                              <ListCell>{t('team_name')}</ListCell>
                              <ListCell />
                            </ListRow>
                          }
                          pagination={
                            <Pagination
                              buttonConfig={[
                                {
                                  key: `${partPageIndex}-${PREVIOUS}`,
                                  label: `${PREVIOUS}`,
                                  onClick: onPartPrev,
                                  disabled: partPageIndex <= 0,
                                },
                                {
                                  key: `${partPageIndex}-${NEXT}`,
                                  label: `${NEXT}`,
                                  onClick: onPartNext,
                                  disabled: !teamMembersData?.hasMore,
                                },
                              ]}
                              resultsText={renderPaginationText(
                                teamMembersData?.totalNumberResults,
                                partPageIndex
                              )}
                            />
                          }
                          renderItem={(participant) => (
                            <ListRow key={`${participant.consId}-${activeTab}`}>
                              <ListCell>
                                {reg_type !== undefined ? (
                                  <Button
                                    onClick={() =>
                                      (location.href = `${participant.personalPageUrl}&reg_type=${reg_type}`)
                                    }
                                    variant='text'
                                  >
                                    {`${participant.participantFullName}`}
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() =>
                                      (location.href = `${participant.personalPageUrl}`)
                                    }
                                    variant='text'
                                  >
                                    {`${participant.participantFullName}`}
                                  </Button>
                                )}
                              </ListCell>
                              <ListCell>{participant.teamName}</ListCell>
                              {isAcceptingDonations ? (
                                <ListCell>
                                  <Button
                                    onClick={() =>
                                      (location.href = getDonationUrl(
                                        `${participant.donationUrl}`
                                      ))
                                    }
                                    variant='primary'
                                    buttonColor='red'
                                  >
                                    {t('donate')}
                                  </Button>
                                </ListCell>
                              ) : (
                                <ListCell />
                              )}
                            </ListRow>
                          )}
                        />
                      ) : (
                        <ColumnList
                          data={sortedParticipants}
                          header={
                            <ListRow
                              key={`${activeTab}-${partPageIndex}`}
                              variant='header'
                            >
                              <ListCell
                                onClick={() =>
                                  handleParticipantSort('participantFullName')
                                }
                              >
                                {t('name')}
                              </ListCell>
                              <ListCell
                                onClick={() =>
                                  handleParticipantSort('eventName')
                                }
                              >
                                {t('event_name')}
                              </ListCell>
                              <ListCell
                                onClick={() =>
                                  handleParticipantSort('amountRaised')
                                }
                              >
                                {t('amount_raised')}
                              </ListCell>
                              <ListCell />
                            </ListRow>
                          }
                          pagination={
                            <Pagination
                              buttonConfig={[
                                {
                                  key: `${partPageIndex}-${PREVIOUS}`,
                                  label: `${PREVIOUS}`,
                                  onClick: onPartPrev,
                                  disabled: partPageIndex <= 0,
                                },
                                {
                                  key: `${partPageIndex}-${NEXT}`,
                                  label: `${NEXT}`,
                                  onClick: onPartNext,
                                  disabled: !participantSearchData?.hasMore,
                                },
                              ]}
                              resultsText={renderPaginationText(
                                participantSearchData?.totalNumberResults,
                                partPageIndex
                              )}
                            />
                          }
                          renderItem={(participant) => (
                            <ListRow key={`${participant.consId}-${activeTab}`}>
                              <ListCell>
                                {reg_type !== undefined ? (
                                  <Button
                                    onClick={() =>
                                      (location.href = `${participant.personalPageUrl}&reg_type=${reg_type}`)
                                    }
                                    variant='text'
                                  >
                                    {`${participant.participantFullName}`}
                                  </Button>
                                ) : (
                                  <Button
                                    onClick={() =>
                                      (location.href = `${participant.personalPageUrl}`)
                                    }
                                    variant='text'
                                  >
                                    {`${participant.participantFullName}`}
                                  </Button>
                                )}
                              </ListCell>
                              <ListCell>{participant.eventName}</ListCell>
                              <ListCell>
                                {convertCentsToDollars(
                                  participant.amountRaised,
                                  {
                                    formatted: true,
                                    rounded: true,
                                  }
                                )}
                              </ListCell>
                              {isAcceptingDonations ? (
                                <ListCell>
                                  <Button
                                    onClick={() =>
                                      (location.href = getDonationUrl(
                                        `${participant.donationUrl}`
                                      ))
                                    }
                                    variant='primary'
                                    buttonColor='red'
                                  >
                                    {t('donate')}
                                  </Button>
                                </ListCell>
                              ) : (
                                <ListCell />
                              )}
                            </ListRow>
                          )}
                        />
                      )}
                    </>
                  )}
                </TabPanel>
                <TabPanel className={styles.teamPanel} id={2}>
                  <Form
                    onChange={handleTeamSearchChange}
                    onSubmit={handleTeamSearchSubmit}
                  >
                    <InputField
                      defaultValue={teamName}
                      errorText={() => t('errors.team_name')}
                      label='Team name'
                      name='teamName'
                      required
                      type='text'
                    />
                    <Button
                      type='submit'
                      disabled={hasTeamErrors}
                      variant='primary'
                      buttonColor='blue'
                    >
                      {t('search')}
                    </Button>
                  </Form>
                  {isFetching ? (
                    <QueryLoading label={LOADING_LABEL} />
                  ) : (
                    <>
                      {!isEmpty(sortedTeams) && (
                        <ColumnList
                          data={sortedTeams}
                          header={
                            <ListRow
                              key={`${activeTab}-${teamPageIndex}`}
                              variant='header'
                            >
                              <ListCell onClick={() => handleTeamSort('name')}>
                                {t('name')}
                              </ListCell>
                              <ListCell
                                onClick={() => handleTeamSort('captainName')}
                              >
                                {t('captain_name')}
                              </ListCell>
                              <ListCell
                                onClick={() => handleTeamSort('amountRaised')}
                              >
                                {t('amount_raised')}
                              </ListCell>
                              <ListCell />
                            </ListRow>
                          }
                          pagination={
                            <Pagination
                              buttonConfig={[
                                {
                                  key: `${teamPageIndex}-${PREVIOUS}`,
                                  label: `${t('previous')}`,
                                  onClick: onTeamPrev,
                                  disabled: teamPageIndex <= 0,
                                },
                                {
                                  key: `${teamPageIndex}-${NEXT}`,
                                  label: `${t('next')}`,
                                  onClick: onTeamNext,
                                  disabled: !teamSearchData?.hasMore,
                                },
                              ]}
                              resultsText={renderPaginationText(
                                teamSearchData?.totalNumberResults,
                                teamPageIndex
                              )}
                            />
                          }
                          renderItem={({
                            amountRaised,
                            captainName,
                            id,
                            name,
                            teamPageURL,
                          }) => (
                            <ListRow key={`${id}-${activeTab}`}>
                              <ListCell>
                                {reg_type !== undefined ? (
                                  <Button
                                    variant='text'
                                    onClick={() =>
                                      (location.href = `${teamPageURL}&reg_type=${reg_type}`)
                                    }
                                  >
                                    {name}
                                  </Button>
                                ) : (
                                  <Button
                                    variant='text'
                                    onClick={() =>
                                      (location.href = `${teamPageURL}`)
                                    }
                                  >
                                    {name}
                                  </Button>
                                )}
                              </ListCell>
                              <ListCell>{captainName}</ListCell>
                              <ListCell>
                                {convertCentsToDollars(amountRaised, {
                                  formatted: true,
                                  rounded: true,
                                })}
                              </ListCell>
                              {isAcceptingRegistrations ? (
                                <ListCell>
                                  <Button
                                    onClick={handleJoinTeam(id)}
                                    variant='primary'
                                    buttonColor='blue'
                                  >
                                    {t('join_team')}
                                  </Button>
                                </ListCell>
                              ) : (
                                <></>
                              )}
                            </ListRow>
                          )}
                        />
                      )}
                    </>
                  )}
                </TabPanel>
              </div>
            </Tabs>
          </div>
        </div>
      </section>
    </main>
  );
};

import React from 'react';
import { node } from 'prop-types';
import { useQuery as reactUseQuery } from 'react-query';
import { QueryError, QueryLoading } from '@frx/frx-components';
import DayJS from 'dayjs';
import { TeamPageContext } from './TeamContext';
import { useQueryString } from '../../../QueryStrings';
import { useQuery } from '../../QueryHooks';
import { useFetcher } from '../../QueryContext';
import { useConfiguration } from '../../../configuration/ConfigurationContext';
import { getParticipants } from '../../../services';
import { LOADING_LABEL } from '../../../../app.constants';
import { useRegistrationDataProvider } from '../../../AppDataContext';

const TEAMRAISERAPI = 'CRTeamraiserAPI';
const CRDONATIONAPI = 'CRDonationAPI';

export const TeamPageProvider = React.memo(({ children }) => {
  const {
    queryString: { fr_id, team_id },
  } = useQueryString();
  const [editImageModal, setEditImageModal] = React.useState(false);
  const [editGoalModal, setEditGoalModal] = React.useState(false);
  const [editRichTextModal, setEditRichTextModal] = React.useState(false);
  const [updateError, setUpdateError] = React.useState(false);
  const fetcher = useFetcher();
  const configuration = useConfiguration();
  const {
    data: { eventInfo },
  } = useRegistrationDataProvider();

  const { url, properties } = configuration.settings.luminate;
  const {
    formId: form_id,
    user: { token },
  } = properties;
  const teamsByInfoData = useQuery(`${TEAMRAISERAPI}/getTeamsByInfo`, {
    fr_id,
    team_id,
  });
  const teamPageInfoData = useQuery(`${TEAMRAISERAPI}/getTeamPageInfo`, {
    fr_id,
    team_id,
  });
  const teamRaiserEventInfoData = useQuery(
    `${TEAMRAISERAPI}/getTeamraisersByInfo`,
    {
      list_filter_column: 'fr_id',
      list_filter_text: fr_id,
      name: '%%%',
    }
  );

  const donationArrayResponse = useQuery(
    `${CRDONATIONAPI}/getDonationFormInfo`,
    {
      form_id,
      fr_id,
    }
  );
  const teamRosterData = reactUseQuery(
    ['teamRoster', { team_id }],
    () =>
      getParticipants({
        fr_id,
        list_ascending: false,
        list_filter_column: 'reg.team_id',
        list_filter_text: team_id,
        list_page_offset: 0,
        list_page_size: 10,
        list_sort_column: 'total',
        team_name: '%%%',
      }),
    { enabled: !!team_id }
  );
  const queries = [teamPageInfoData, teamRaiserEventInfoData, teamsByInfoData];
  const isQueryLoading = queries.some(
    ({ queryResponse }) => queryResponse && queryResponse.loading
  );
  const isQueryError = queries.find(
    ({ queryResponse }) => queryResponse && queryResponse.error
  );

  const hasNodata = queries.some(
    ({ queryResponse }) => queryResponse && !queryResponse?.data
  );

  if (isQueryLoading) return <QueryLoading label={LOADING_LABEL} />;
  if (hasNodata || isQueryError) return <QueryError />;

  const teamInfo = teamsByInfoData?.queryResponse?.data?.team;
  const teamPageInfo = teamPageInfoData?.queryResponse.data?.teamPage;
  const donationLevels =
    donationArrayResponse?.queryResponse?.data?.donationLevels;
  const userSpecifiedAmount = (
    (donationLevels || {}).donationLevel || []
  ).filter((d) => d.userSpecified === 'true');
  const minimumAmount =
    userSpecifiedAmount.length &&
    Number(userSpecifiedAmount[0].amount.formatted.replace('$', ''));
  const donationArray = ((donationLevels || {}).donationLevel || []).map(
    (d) => ({
      name: d.name,
      value:
        d.userSpecified !== 'true'
          ? d.amount.formatted.replace('.00', '')
          : 'Other',
      link: `${url}/Donation2?df_id=${form_id}&${form_id}.donation=form1&PROXY_ID=${team_id}&PROXY_TYPE=20&FR_ID=${fr_id}&set.DonationLevel=${d.level_id}`,
    })
  );
  const thisEvent = teamRaiserEventInfoData.queryResponse.data.teamraiser;
  const eventDate =
    thisEvent.event_date && DayJS(thisEvent.event_date).format('MMMM D, YYYY');
  const eventAddress = `${thisEvent.street_address}\n${thisEvent.city}, ${thisEvent.state} ${thisEvent.zip}`;

  const handleImageModal = () => setEditImageModal((prevState) => !prevState);
  const handleImageCancel = () => {
    handleImageModal();
    setUpdateError();
  };

  const handleGoalModal = () => setEditGoalModal((prevState) => !prevState);
  const handleGoalCancel = () => {
    handleGoalModal();
    setUpdateError();
  };

  const handleRichTextModal = () =>
    setEditRichTextModal((prevState) => !prevState);
  const handleRichTextCancel = () => {
    handleRichTextModal();
    setUpdateError();
  };

  const handleUpdateGoal = (goal) =>
    fetcher
      .get(`${TEAMRAISERAPI}/updateTeamInformation`, {
        auth: token,
        fr_id,
        team_goal: goal,
      })
      .then((res) => {
        if (res.errorResponse) {
          setUpdateError(res.errorResponse.message);
        } else {
          handleGoalCancel();
          teamsByInfoData.refetch();
        }
      });

  const handleUpdateRichText = (richText) =>
    fetcher
      .get(`${TEAMRAISERAPI}/updateTeamPageInfo`, {
        auth: token,
        fr_id,
        rich_text: richText,
      })
      .then((res) => {
        if (res.errorResponse) {
          setUpdateError(res.errorResponse.message);
        } else {
          handleRichTextCancel();
          teamPageInfoData.refetch();
        }
      });

  const context = {
    commands: {
      handleGoalCancel,
      handleGoalModal,
      handleImageCancel,
      handleImageModal,
      handleRichTextCancel,
      handleRichTextModal,
      handleUpdateGoal,
      handleUpdateRichText,
      setUpdateError,
    },
    data: {
      donationArrayData: { donationArray, minimumAmount },
      editGoalModal,
      editImageModal,
      editRichTextModal,
      eventDetails: { eventAddress, eventDate },
      teamInfo,
      eventInfo,
      teamPageInfo,
      teamRosterData,
      updateError,
    },
  };

  return (
    <TeamPageContext.Provider value={context}>
      {children}
    </TeamPageContext.Provider>
  );
});

TeamPageProvider.propTypes = {
  children: node,
};

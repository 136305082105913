import React from 'react';

import cn from 'classnames';
import { bool, node } from 'prop-types';

import styles from './accordions.module.scss';

// TODO: add styles when toggle disabled
const AccordionItem = ({ children, ...rest }) => {
  const containerClass = cn(
    styles.accordionContainer,
    rest.disabled && styles.disabled
  );

  return (
    <div className={containerClass} {...rest}>
      {children}
    </div>
  );
};

AccordionItem.propTypes = {
  children: node,
  disabled: bool,
};

AccordionItem.defaultProps = {
  disabled: false,
};

export default AccordionItem;

import React from 'react';
import { useTranslation } from 'react-i18next';

import styles from './StepHeader.module.scss';

export const StepHeader = () => (
  <div className={styles.container}>
    <div>{useTranslation().t('screens.step_header_text')}:</div>
    <div>St. Jude Memphis Marathon Weekend</div>
  </div>
);

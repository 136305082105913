import React, { useEffect } from 'react';
import { bool } from 'prop-types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  Button,
  ButtonsBar,
  Form,
  InputField,
  Screen,
} from '../../../components';
import {
  useFetcher,
  useQueryString,
  useRegistrationDataProvider,
  useSubmitRegistration,
} from '../../../utils';
import { ROUTE_KEYS } from '../../../app.constants';

import styles from './index.module.scss';

const PER_PAGE = 10;
export const JoinTeam = ({ showSidebar }) => {
  const [currentPage, setCurrentPage] = React.useState(-1);
  const [existingTeams, setExistingTeams] = React.useState();
  const [hasFormErrors, setHasFormErrors] = React.useState(true);
  const [numResults, setNumResults] = React.useState();
  const [hasSearched, setHasSearched] = React.useState(false);

  const { t } = useTranslation('teams');

  const fetcher = useFetcher();
  const {
    queryString: { fr_id },
    redirect,
  } = useQueryString();
  const {
    data: { teamsFormData, transactionId },
    commands: { setTeamsFormData, setJoinTeamId },
  } = useRegistrationDataProvider();
  const { postStep } = useSubmitRegistration();
  const { goBack } = useHistory();

  const getTeamsData = React.useCallback(
    async () =>
      await fetcher
        .get('CRTeamraiserAPI/getTeamsByInfo', {
          fr_id,
          list_page_offset: currentPage,
          list_page_size: PER_PAGE,
          team_name: teamsFormData.teamName,
        })
        .then((response) => {
          setHasSearched(true);
          const { totalNumberResults, team } =
            response && response.getTeamSearchByInfoResponse;
          if (Number(totalNumberResults) > 0) {
            const teams = Array.isArray(team) ? team : [team];
            setExistingTeams(teams);
            setNumResults(Number(totalNumberResults));
          } else {
            setNumResults();
            setExistingTeams();
          }
        }),
    [currentPage, fetcher, teamsFormData, fr_id]
  );

  useEffect(() => {
    setJoinTeamId();
    if (currentPage >= 0) {
      getTeamsData();
    }
  }, [currentPage, getTeamsData, setJoinTeamId]);

  const handleOnFormChange = (fields, hasErrors) => {
    setTeamsFormData(fields);
    setHasFormErrors(hasErrors);
  };

  const handleOnFormSubmit = () => {
    if (currentPage >= 0) return false;
    setCurrentPage((prevState) => prevState + 1);
  };

  const handlePrevStep = () => goBack();

  const handleOnNext = () => {
    if (currentPage >= Math.ceil(numResults / PER_PAGE - 1)) {
      return false;
    }
    setCurrentPage((prevState) => prevState + 1);
  };

  const handleOnPrevious = () => {
    if (currentPage === 0) {
      return false;
    }
    setCurrentPage((prevState) => prevState - 1);
  };

  const handleOnJoin = (teamId) => () =>
    postStep(transactionId, {
      pg: 'tfind',
      fr_id,
      fr_tjoin: `${teamId}`,
      skip_login_page: 'true',
    }).then(() => {
      setJoinTeamId(teamId);
      redirect(`/${ROUTE_KEYS.OPTIONS}`);
    });

  return (
    <Screen className={styles.container} showSidebar={showSidebar}>
      <Form onChange={handleOnFormChange} onSubmit={handleOnFormSubmit}>
        <InputField
          name='teamName'
          label={t('team_name')}
          type='text'
          required
          errorText={() => t('err_team_required')}
        />
        <ButtonsBar
          disabled={hasFormErrors}
          primaryButtonText={t('search_team')}
          handleSecondary={handlePrevStep}
          data-tracking='search-for-team'
        />
      </Form>
      {numResults && (
        <section className={styles.paginationContainer}>
          <div className={styles.headerContainer}>
            <h1>{`${t('show_results')}: ${`${existingTeams.length} ${t(
              'of'
            )} ${numResults}`}`}</h1>
            <div>
              <Button variant='text' onClick={handleOnPrevious}>
                {t('prev')}
              </Button>
              <Button variant='text' onClick={handleOnNext}>
                {t('next')}
              </Button>
            </div>
          </div>
          {existingTeams &&
            existingTeams.map(
              ({
                id,
                name,
                companyName,
                captainFirstName,
                captainLastName,
              }) => {
                const captainName =
                  !!captainFirstName &&
                  captainLastName &&
                  `${captainFirstName} ${captainLastName}`;
                return (
                  <div className={styles.teamContainer} key={id}>
                    <div>
                      <h1>{name}</h1>
                      <h6>
                        {!!captainName && `${t('captain')}: ${captainName}`}
                      </h6>
                      <h6>
                        {companyName && `${t('company')}: ${companyName}`}
                      </h6>
                    </div>
                    <div>
                      <Button
                        xs
                        onClick={handleOnJoin(id)}
                        data-tracking='join-team'
                      >
                        {t('join')}
                      </Button>
                    </div>
                  </div>
                );
              }
            )}
        </section>
      )}

      {hasSearched && !numResults && (
        <section className={styles.paginationContainer}>
          <p>{t('no_results')}</p>
        </section>
      )}
    </Screen>
  );
};

JoinTeam.propTypes = {
  showSidebar: bool,
};

import React from 'react';
import { node } from 'prop-types';
import { ContactPageContext } from './ContactPageContext';
import { useFetcher } from '../../QueryContext';
import { useConfiguration } from '../../../configuration/ConfigurationContext';
import { useAuth } from '../../../security/SecurityContext';

export const ContactPageProvider = React.memo(({ children }) => {
  const {
    settings: { luminate },
  } = useConfiguration();
  const { user: storageUser } = useAuth();
  const {
    user: { token },
  } = luminate.properties;
  const fetcher = useFetcher();

  const getSurveyId = async (surveyName) => {
    const { listSurveysResponse } = await fetcher.get(
      'CRSurveyAPI/listSurveys',
      {
        published_only: true,
        list_filter_column: 'surveyName',
        list_filter_text: surveyName,
        list_page_size: 200,
      }
    );
    const contactUsSurvey = listSurveysResponse.surveys;
    return contactUsSurvey.surveyId;
  };

  const getSurveyQuestions = async (survey_id) => {
    const { getSurveyResponse } = await fetcher.get('CRSurveyAPI/getSurvey', {
      survey_id,
      auth: token || storageUser?.token,
    });
    return (
      getSurveyResponse &&
      getSurveyResponse.survey &&
      getSurveyResponse.survey.surveyQuestions
    );
  };

  const submitSurvey = async (formData, survey_id) => {
    const { submitSurveyResponse } = await fetcher.get(
      'CRSurveyAPI/submitSurvey',
      {
        survey_id,
        auth: token,
        ...formData,
      }
    );
    return submitSurveyResponse;
  };

  const context = {
    commands: {
      getSurveyId,
      getSurveyQuestions,
      submitSurvey,
    },
  };

  return (
    <ContactPageContext.Provider value={context}>
      {children}
    </ContactPageContext.Provider>
  );
});

ContactPageProvider.displayName = 'ContactPageProvider';

ContactPageProvider.propTypes = {
  children: node,
};

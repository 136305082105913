import React from 'react';

const defaultContext = Object.freeze({
  data: [],
  error: undefined,
  loading: false,
});

export const CacheContext = React.createContext(defaultContext);

export const useCacheQueryProvider = () => {
  const context = React.useContext(CacheContext);

  if (context === undefined) {
    throw new Error(
      'useCacheQueryProvider must be within an CacheQueryProvider'
    );
  }

  return context;
};

export const getSentryConfig = ({ hostname = '' }) => {
  const release = process.env.REACT_APP_SENTRY_VERSION || '';
  const config = {
    'secure2.convio.net': {
      environment: 'dev',
      enabled: false,
      release,
      sampleRate: 0,
      tracesSampleRate: 0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
    },
    'fundraising.qa.stjude.org': {
      environment: 'qa',
      enabled: true,
      release,
      sampleRate: 1.0,
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 0.1,
    },
    'fundraising.stjude.org': {
      environment: 'prod',
      enabled: true,
      release,
      sampleRate: 1.0,
      tracesSampleRate: 0.1,
      replaysSessionSampleRate: 0.1,
      replaysOnErrorSampleRate: 1.0,
    },
    default: {
      environment: 'local',
      enabled: false,
      release,
      sampleRate: 0,
      tracesSampleRate: 0,
      replaysSessionSampleRate: 0,
      replaysOnErrorSampleRate: 0,
    },
  };

  return config[hostname] || config.default;
};

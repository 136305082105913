import React from 'react';
import { node } from 'prop-types';
import { useIsFetching, useQueries, useQuery } from 'react-query';
import { QueryLoading } from '@frx/frx-components';
import { EventPageContext } from './EventContext';
import { useConfiguration } from '../../../configuration/ConfigurationContext';
import {
  getTeamMembers,
  getTeamsByInfo,
  getTopParticipants,
  getTopTeams,
} from '../../../services';
import { useQueryString } from '../../../QueryStrings';
import {
  convertToArray,
  getDonationUrl,
  stringToBoolean,
} from '../../../utils';
import { LOADING_LABEL } from '../../../../app.constants';

export const EventPageProvider = ({ children }) => {
  const [companyId, setCompanyId] = React.useState();
  const configuration = useConfiguration();
  const {
    queryString: { fr_id, reg_type },
  } = useQueryString();
  const { properties, url } = configuration.settings.luminate;
  const { formId: form_id } = properties;
  const topTeams = useQuery(['topTeams'], () => getTopTeams());
  const topTeamsMembers = useQueries(
    (topTeams.data || []).map((t) => ({
      queryKey: ['team', t.id],
      queryFn: () => getTeamMembers({ fr_id, team_id: t.id }),
      enabled: !!topTeams,
    }))
  ).map((tm) => {
    const membersArr = convertToArray(tm?.data?.member);
    const teamCaptain = membersArr?.filter((m) =>
      stringToBoolean(m.aTeamCaptain)
    )[0]?.consId;
    if (reg_type !== undefined) {
      return {
        teamName: membersArr && membersArr[0].teamName,
        teamPageURL:
          membersArr && `${membersArr[0].teamPageUrl}&reg_type=${reg_type}`,
        teamMembers: membersArr?.length,
        teamCaptain,
      };
    }
    return {
      teamName: membersArr && membersArr[0].teamName,
      teamPageURL: membersArr && `${membersArr[0].teamPageUrl}`,
      teamMembers: membersArr?.length,
      teamCaptain,
    };
  });
  const teamsByCompany = useQuery(
    ['company', [companyId]],
    () =>
      getTeamsByInfo({
        list_ascending: false,
        list_page_offset: 0,
        list_page_size: 5,
        list_sort_column: 'total',
        team_company_id: companyId,
      }),
    { enabled: !!companyId }
  );
  const isRegTypeHeroes = () => {
    if (reg_type === 'heroes') {
      return true;
    }
    return false;
  };
  const topParticipants = (
    useQuery(['topFiveParticipants'], () => getTopParticipants())?.data || []
  ).map((p) => ({
    ...p,
    donationUrl: getDonationUrl(
      `${url}/Donation2?df_id=${form_id}&${form_id}.donation=form1&PROXY_ID=${p.id}&PROXY_TYPE=20&FR_ID=${fr_id}`
    ),
    /* personalPageUrl: `${url}/TR/Heroes/SJMMW?px=${p.id}&pg=personal&fr_id=${fr_id}&reg_type=${reg_type}`, */
    personalPageUrl: isRegTypeHeroes(reg_type)
      ? `${url}/TR/Heroes/SJMMW?px=${p.id}&pg=personal&fr_id=${fr_id}&reg_type=${reg_type}`
      : `${url}/TR/Heroes/SJMMW?px=${p.id}&pg=personal&fr_id=${fr_id}`,
  }));
  const isFetchingTopTeamsMembers = useIsFetching(['team']);
  const isFetchingTopParticipants = useIsFetching(['topFiveParticipants']);
  const topTeamsWithMembers = ((topTeamsMembers && topTeams.data) || []).map(
    (tt) => {
      const thisTeam = topTeamsMembers?.filter(
        (ttm) => ttm.teamName === tt.name
      )[0];
      return {
        ...tt,
        teamCaptain: thisTeam?.teamCaptain,
        teamMembers: thisTeam?.teamMembers,
        teamPageURL: thisTeam?.teamPageURL,
        amountRaised: tt?.total,
        numMembers: thisTeam?.teamMembers,
      };
    }
  );
  if (
    topTeams.isLoading ||
    isFetchingTopParticipants ||
    isFetchingTopTeamsMembers
  )
    return <QueryLoading label={LOADING_LABEL} />;
  const context = {
    commands: {
      setCompanyId,
    },
    data: {
      teamsByCompany,
      topParticipants,
      topTeamsWithMembers,
    },
  };
  return (
    <EventPageContext.Provider value={context}>
      {children}
    </EventPageContext.Provider>
  );
};
EventPageProvider.propTypes = {
  children: node,
};

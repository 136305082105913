import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import Backend from 'i18next-http-backend';
import { loadSettings } from './utils';
import { LANGUAGE_CODES } from './app.constants';

const {
  luminate: {
    properties: {
      user: { locale },
    },
  },
} = loadSettings(location);
const { EN, ES } = LANGUAGE_CODES;
i18n
  .use(Backend)
  .use(initReactI18next)
  .init({
    supportedLngs: [EN, ES],
    lng: locale,
    fallbackLng: EN,
    debug: false,
    ns: [
      'common',
      'confirmation_screen',
      'login',
      'options',
      'payment_screen',
      'summary',
      'teams',
    ],
    defaultNS: 'common',
    interpolation: {
      escapeValue: false,
    },
    react: {
      wait: true,
      useSuspense: false,
    },
    backend: {
      loadPath: `${process.env.PUBLIC_URL}/locales/{{lng}}/{{ns}}.json`,
    },
  });

export default i18n;

import React from 'react';
import { bool } from 'prop-types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import {
  ButtonsBar,
  ErrorMessage,
  Form,
  InputField,
  Screen,
  SelectField,
} from '../../../components';
import {
  useQueryString,
  useRegistrationDataProvider,
  useSubmitRegistration,
} from '../../../utils';
import { ROUTE_KEYS } from '../../../app.constants';

import styles from './index.module.scss';

export const CreateTeam = ({ showSidebar }) => {
  const [hasFormErrors, setHasFormErrors] = React.useState(true);
  const [errorMessage, setErrorMessage] = React.useState([]);
  const { goBack } = useHistory();

  const { t } = useTranslation(['teams', 'common']);

  console.log('reboot');

  const {
    queryString: { fr_id },
    redirect,
  } = useQueryString();
  const {
    data: { companyList, teamsFormData, transactionId },
    commands: { setTeamsFormData },
  } = useRegistrationDataProvider();
  const { postStep } = useSubmitRegistration();

  const companyItem =
    companyList &&
    (companyList || []).map(({ companyName, companyId }) => ({
      label: companyName,
      value: companyId,
    }));

  const handlePrevStep = () => goBack();

  const handleFormOnChange = (fields, hasErrors) => {
    setHasFormErrors(hasErrors);
    setTeamsFormData(fields);
  };

  const handleFormOnSubmit = () =>
    postStep(transactionId, {
      pg: 'tfind',
      fr_id,
      fr_tm_opt: 'new',
      fr_team_name: teamsFormData.teamName,
      fr_team_goal: `$${teamsFormData.goalAmount}`,
      fr_co_list: `${teamsFormData.companyName}`,
      fr_division_name: '',
      fr_cstm_tm: 't',
      company_id: '-1',
      denySubmit: '',
      'next_step.x': 'Next Step',
    }).then((res) => {
      const errors = new DOMParser()
        .parseFromString(res, 'text/html')
        .querySelectorAll('.field-error-text');
      if (errors.length) {
        console.log(fr_id, ' ', errors[0].textContent.trim());
        if (
          errors[0].textContent.trim() ===
          'There is already a team registered with that name. You may join the team or enter another team name.'
        ) {
          errors[0].innerHTML = `There is already a team registered with that name. You may <a href="SPageServer/teamoptions?fr_id=${fr_id}&pagename=sjmmw_registration&code=Virtual_Marathon_Gen&team=join-team">join</a> the team or enter another team name.`;
        }
        console.log([...errors]);
        setErrorMessage([...errors]);
      } else {
        redirect(`${ROUTE_KEYS.OPTIONS}`);
      }
    });

  return (
    <Screen className={styles.container} showSidebar={showSidebar}>
      <Form onChange={handleFormOnChange} onSubmit={handleFormOnSubmit}>
        {errorMessage &&
          errorMessage.map((e, i) => (
            <ErrorMessage
              errorText={e.innerHTML}
              errorFormat='html'
              key={`${i}`}
            />
          ))}
        <InputField
          defaultValue={teamsFormData && teamsFormData.teamName}
          errorText={() => t('err_team_required')}
          label={t('team_name')}
          name='teamName'
          required
          type='text'
        />
        <InputField
          defaultValue={(teamsFormData && teamsFormData.goalAmount) || '25000'}
          label={`${t('team_fund_goal')} ($):`}
          name='goalAmount'
          type='number'
        />
        <SelectField
          defaultValue={(teamsFormData && teamsFormData.companyName) || ''}
          label={t('compete_fund_challenge')}
          name='companyName'
          options={companyItem || []}
          placeholder={t('common:screens.personal_info.select')}
        />
        <div className='pt-4'>
          <ButtonsBar
            disabled={hasFormErrors}
            handleSecondary={handlePrevStep}
            data-tracking='create-team'
          />
        </div>
      </Form>
    </Screen>
  );
};

CreateTeam.propTypes = {
  showSidebar: bool,
};

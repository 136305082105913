import React from 'react';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import i18n from 'i18next';
import { ROUTE_KEYS, REG_TYPES, LANGUAGE_CODES } from '../../app.constants';
import {
  isEqual,
  useQueryString,
  useRegistrationDataProvider,
  useRegTotalToPay,
} from '../../utils';
import { SummaryItem } from './summary-item/SummaryItem';
import { getUpsellName } from '../../assets';

/**
 * @description
 * This is specifically made it for summary since we have same options in
 * summary and sidebar (things are hardcoded like headerText and value for the above reasons)
 */

export const SummaryOptions = () => {
  const { replace } = useHistory();
  const {
    buildCleanPath,
    queryString: { team, code, reg_type },
  } = useQueryString();
  const {
    data: {
      upsells,
      discountAmount,
      selectedParticipationType: {
        attendance,
        commitment,
        distance,
        fee,
        name: participationName,
      },
    },
  } = useRegistrationDataProvider();

  const { t } = useTranslation(['summary', 'common']);
  const { clearDiscount, newKickStartAmount, discountAmountTotal, total } =
    useRegTotalToPay();
  const isSpanish = isEqual(i18n.language, LANGUAGE_CODES.ES);

  return (
    <>
      {isEqual(commitment, '') && (
        <SummaryItem
          {...{
            headerText: t('participation'),
            headerTextValue: `${participationName}`,
          }}
        />
      )}
      {attendance && (
        <SummaryItem
          {...{
            headerText: t('participation'),
            headerTextValue: t(`part.${attendance}`),
            onEdit: () => {
              clearDiscount();
              replace(
                buildCleanPath(`${ROUTE_KEYS.PARTICIPATION}`, { reg_type })
              );
            },
          }}
        />
      )}
      {distance && (
        <SummaryItem
          {...{
            headerText: t('distance'),
            headerTextValue: t(
              `common:screens.distance.distance_types.${distance
                .toLowerCase()
                .replace(' ', '_')}`
            ),
            onEdit: () => {
              clearDiscount();
              replace(
                buildCleanPath(`${ROUTE_KEYS.PARTICIPATION}`, {
                  attendance,
                  reg_type,
                })
              );
            },
            priceLabel: `${fee}`,
          }}
        />
      )}
      {commitment && commitment !== REG_TYPES.GENERAL && (
        <SummaryItem
          {...{
            headerText: t('entry_level'),
            headerTextValue: t(
              `common:screens.commitment.commitment_types.${commitment
                .toLowerCase()
                .replace(' ', '_')}`
            ),
            onEdit: () => {
              clearDiscount();
              replace(
                buildCleanPath(`${ROUTE_KEYS.PARTICIPATION}`, {
                  attendance,
                  distance: distance.replace(' ', '-'),
                  reg_type,
                })
              );
            },
          }}
        />
      )}
      {team && (
        <SummaryItem
          {...{
            headerText: t('team_status'),
            headerTextValue: t(`${team}`),
            onEdit: () => {
              clearDiscount();
              replace(
                buildCleanPath(`${ROUTE_KEYS.TEAM}`, {
                  code,
                  reg_type,
                })
              );
            },
          }}
        />
      )}
      {newKickStartAmount !== 0 && (
        <SummaryItem
          {...{
            headerText: t('kickstart_donation'),
            onEdit: () =>
              replace(
                buildCleanPath(`${ROUTE_KEYS.OPTIONS}`, {
                  code,
                  team,
                  reg_type,
                })
              ),
            priceLabel: `$${newKickStartAmount.toFixed(2)}`,
          }}
        />
      )}
      {upsells &&
        upsells.map(({ id, name, price }) => {
          const spanishName = getUpsellName(name);
          return (
            <SummaryItem
              key={id}
              {...{
                headerText: isSpanish ? spanishName : name,
                priceLabel: price,
                onEdit: () =>
                  replace(
                    buildCleanPath(`${ROUTE_KEYS.OPTIONS}`, {
                      code,
                      team,
                      reg_type,
                    })
                  ),
              }}
            />
          );
        })}
      {discountAmountTotal > 0 && (
        <SummaryItem
          {...{
            headerText: t('discount'),
            priceLabel: `-${discountAmount}`,
            onEdit: () =>
              replace(
                buildCleanPath(`${ROUTE_KEYS.OPTIONS}`, {
                  code,
                  team,
                  reg_type,
                })
              ),
          }}
        />
      )}
      <SummaryItem
        bold
        {...{
          headerText: 'Total',
          priceLabel: `$${total}`,
        }}
      />
    </>
  );
};

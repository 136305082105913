import React from 'react';
import { array, bool, node, object, string } from 'prop-types';
import { useTranslation } from 'react-i18next';

import { SelectField } from '../../../../components';
import { useRegistrationDataProvider } from '../../../../utils';

export const MultiSingle = ({
  className,
  label,
  name,
  options = [],
  overlay,
  required = false,
  questionId,
}) => {
  const {
    data: { personalFormData },
  } = useRegistrationDataProvider();
  const defaultValue = personalFormData && personalFormData[name];
  const { t } = useTranslation();
  return (
    <SelectField
      key={questionId}
      {...{
        className,
        defaultValue,
        label,
        name,
        options,
        overlay,
        placeholder: t('screens.personal_info.select'),
        required,
      }}
    />
  );
};

MultiSingle.propTypes = {
  className: string,
  label: string,
  name: string.isRequired,
  options: array,
  overlay: node,
  question: object,
  required: bool,
  questionId: string,
};

import { array, object } from 'prop-types';
import React, { Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import { MultiSingle } from './question-types/MultiSingle';
import {
  camelCase,
  getPartTypeFromCode,
  isCountryUSA,
  isEqual,
  useAuth,
  useQueryString,
} from '../../../utils';
import { ShortText } from './question-types/ShortText';
import { QUESTION_KEYS } from './questions';
import { MultiTimeField } from './question-fields/MultiTimeField';
import { SingletTechField } from './question-fields/SingletTechField';

import styles from './RegQuestions.module.scss';
import { PhoneField } from './question-fields/PhoneInputField';
import { CheckboxField } from '../../../components';

export const getSelectionOptions = (
  key = '',
  options = [],
  personalFormData = {}
) => {
  const { QKEY_DISTANCEONE, QKEY_DISTANCETWO, QKEY_DISTANCETWO_VIRTUAL } =
    QUESTION_KEYS;
  switch (key) {
    case QKEY_DISTANCEONE:
      return options.filter(
        (o) =>
          !isEqual(
            o.label,
            personalFormData.qkeyDistancetwo ??
              personalFormData.qkeyDistancetwoVirtual
          )
      );
    case QKEY_DISTANCETWO:
      return options.filter(
        (o) => !isEqual(o.label, personalFormData.qkeyDistanceone)
      );
    case QKEY_DISTANCETWO_VIRTUAL:
      return options.filter(
        (o) => !isEqual(o.label, personalFormData.qkeyDistanceone)
      );
    default:
      return options;
  }
};

export const RegQuestions = ({
  regQuestions,
  errorIndex,
  personalFormData = {},
}) => {
  const {
    user: { firstName, lastName, address },
  } = useAuth();
  const {
    queryString: { code },
  } = useQueryString();
  const {
    BIB_NAME,
    FINISH_TIME_HOUR,
    FINISH_TIME_MINUTES,
    SINGLET_TECH,
    SINGLET_TECH_SHIRT,
    VIRTUAL_ACKNOWLEDGE,
    PLATINUM_PREMIER_WAIVER,
    MOBILE_PHONE,
    EMERGENCY_PHONE,
    COUNTRY_REPRESENTED,
  } = QUESTION_KEYS;
  const timeQuestionsKeys = new Set([FINISH_TIME_HOUR, FINISH_TIME_MINUTES]);
  const timeQuestions = regQuestions.filter(({ key }) =>
    timeQuestionsKeys.has(key)
  );

  const singletTechKeys = new Set([SINGLET_TECH, SINGLET_TECH_SHIRT]);
  const singletTechQuestions = regQuestions.filter(({ key }) =>
    singletTechKeys.has(key)
  );
  const mainQuestion = singletTechQuestions.filter(
    ({ key }) => key === SINGLET_TECH
  );
  const secondaryQuestion = singletTechQuestions.filter(
    ({ key }) => key === SINGLET_TECH_SHIRT
  );

  const { t } = useTranslation();
  const { commitment } = getPartTypeFromCode(code);

  const countryUSA = isCountryUSA(personalFormData && personalFormData.country);

  // console.log(address.state);
  // console.log(personalFormData && personalFormData.state);
  // const removeCounty2 =
  //   personalFormData && personalFormData.state
  //     ? personalFormData && personalFormData.state === 'CO'
  //       ? COUNTRY_REPRESENTED
  //       : address && address.state === 'CO'
  //       ? COUNTRY_REPRESENTED
  //       : ''
  //     : '';

  let removeCountry = '';

  if (personalFormData && personalFormData.state) {
    if (personalFormData && personalFormData.state === 'CO') {
      removeCountry = COUNTRY_REPRESENTED;
    }
  } else if (address && address.state === 'CO') {
    removeCountry = COUNTRY_REPRESENTED;
  }

  return (
    <>
      {regQuestions
        .filter((h) => h.hidden === 'false')
        .map((q, index) => {
          const selectOptions = getSelectionOptions(
            q.key,
            q.questionTypeData?.surveyQuestionData?.availableAnswer,
            personalFormData
          );

          if (
            timeQuestions.length === timeQuestionsKeys.size &&
            q.key === timeQuestions[1].key
          ) {
            return (
              <div
                className={
                  (errorIndex || []).find(
                    (e) => Number(e?.replace().trim()) === index + 1
                  ) && styles.error
                }
                key={q.questionId}
              >
                <MultiTimeField
                  {...{ timeQuestions }}
                  key={q.questionId}
                  finishTimeHourKey={FINISH_TIME_HOUR}
                />
              </div>
            );
          }
          if (
            singletTechQuestions.length === singletTechKeys.size &&
            q.key === singletTechQuestions[1].key
          ) {
            return (
              <div
                className={
                  (errorIndex || []).find(
                    (e) => Number(e?.replace().trim()) === index + 1
                  ) && styles.error
                }
                key={q.questionId}
              >
                <SingletTechField
                  {...{ mainQuestion, secondaryQuestion, key: q.questionId }}
                />
              </div>
            );
          }
          if (
            q.questionType === 'MultiSingle' &&
            q.key !== FINISH_TIME_HOUR &&
            q.key !== FINISH_TIME_MINUTES &&
            q.key !== removeCountry &&
            q.key !== COUNTRY_REPRESENTED
          ) {
            return (
              <div
                className={
                  (errorIndex || []).find(
                    (e) => Number(e?.replace().trim()) === index + 1
                  ) && styles.error
                }
                key={q.questionId}
              >
                <MultiSingle
                  required={q.questionRequired === 'true'}
                  key={q.questionId}
                  label={q.questionText}
                  name={camelCase(q.key)}
                  options={selectOptions}
                />
              </div>
            );
          }
          if (
            q.questionType === 'MultiSingle' &&
            q.key !== FINISH_TIME_HOUR &&
            q.key !== FINISH_TIME_MINUTES &&
            q.key === COUNTRY_REPRESENTED &&
            removeCountry === ''
          ) {
            return (
              <div
                className={
                  (errorIndex || []).find(
                    (e) => Number(e?.replace().trim()) === index + 1
                  ) && styles.error
                }
                key={q.questionId}
              >
                <MultiSingle
                  required={removeCountry !== 'Country_Represented'}
                  key={q.questionId}
                  label={q.questionText}
                  name={camelCase(q.key)}
                  options={selectOptions}
                />
              </div>
            );
          }
          if (q.questionType === 'ShortTextValue') {
            if (q.key === MOBILE_PHONE) {
              return (
                <>
                  <PhoneField
                    className={styles.phone}
                    countryUSA={countryUSA}
                    label={q.questionText}
                    name={camelCase(q.key)}
                  />
                  <div>{t('screens.personal_info.mobile_caption')}</div>
                </>
              );
            }
            if (q.key === EMERGENCY_PHONE) {
              return (
                <>
                  <PhoneField
                    className={styles.phone}
                    countryUSA={countryUSA}
                    label={q.questionText}
                    name={camelCase(q.key)}
                    required
                  />
                </>
              );
            }
            const bibName = q.key === BIB_NAME;
            const bibProps = bibName && {
              errorText: () => `${t('screens.personal_info.bib_error')}`,
              maxLength: 10,
              pattern: '[a-zA-Z\u00C0-\u024F]+',
              defaultValue: firstName?.substring(0, 10),
              autoComplete: 'off',
            };
            const virtualAC =
              q.key === VIRTUAL_ACKNOWLEDGE ||
              q.key === `${commitment.toUpperCase()}_WAIVER` ||
              q.key === PLATINUM_PREMIER_WAIVER;
            const virtualACProps = virtualAC && {
              errorText: () =>
                `${t('screens.personal_info.enter_valid_text')} ${
                  firstName.toUpperCase()[0]
                }${lastName.toUpperCase()[0]}`,
              maxLength: 4,
              minLength: 1,
              pattern: '[A-Z]+',
              placeholder: t('screens.personal_info.enter_init'),
            };
            return (
              <div
                className={
                  (errorIndex || []).find(
                    (e) => Number(e?.replace().trim()) === index + 1
                  ) && styles.error
                }
                key={q.questionId}
              >
                <ShortText
                  required={q.questionRequired === 'true'}
                  key={q.questionId}
                  label={q.questionText}
                  name={camelCase(q.key)}
                  {...bibProps}
                  {...virtualACProps}
                />
              </div>
            );
          }
          if (q.questionType === 'LargeTextValue') {
            return (
              <div
                className={
                  (errorIndex || []).find(
                    (e) => Number(e?.replace().trim()) === index + 1
                  ) && styles.error
                }
                key={q.questionId}
              >
                <ShortText
                  required={q.questionRequired === 'true'}
                  key={q.questionId}
                  label={q.questionText}
                  name={camelCase(q.key)}
                  isTextArea='true'
                  maxLength={255}
                />
              </div>
            );
          }
          if (q.questionType === 'MultiMulti') {
            return (
              <>
                <CheckboxField
                  label={
                    Array.isArray(
                      q.questionTypeData.surveyQuestionData.availableAnswer
                    )
                      ? q.questionTypeData.surveyQuestionData.availableAnswer[0]
                          .label
                      : q.questionTypeData.surveyQuestionData.availableAnswer
                          .label
                  }
                  name={camelCase(q.key)}
                  required={q.questionRequired}
                  className={styles.boldTextCheckbox}
                />
              </>
            );
          }
          return <Fragment key={q.questionId} />;
        })}
    </>
  );
};

RegQuestions.propTypes = {
  regQuestions: array,
  errorIndex: array,
  personalFormData: object,
};

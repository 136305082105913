import React from 'react';

export const QueryContext = React.createContext(undefined);

export const useFetcher = () => {
  const context = React.useContext(QueryContext);

  if (context === undefined)
    throw new Error('useFetcher must be within an QueryProvider');

  return context;
};

import React from 'react';
import * as PropTypes from 'prop-types';

const extractFormDataByName = function (form) {
  let hasErrors = false;

  const fields = {};
  const { elements } = form;

  for (let i = 0; i < elements.length; i++) {
    const element = elements[i];

    if (
      element instanceof HTMLInputElement ||
      (element instanceof HTMLTextAreaElement && element.name)
    ) {
      const isTextValue =
        element.type === 'text' ||
        element.type === 'password' ||
        element.type === 'textarea' ||
        element.type === 'email' ||
        element.type === 'hidden' ||
        element.type === 'date' ||
        element.type === 'tel';

      if (isTextValue) {
        fields[element.name] = element.value;
      } else if (element.type === 'radio' && element.checked) {
        fields[element.name] = element.value;
      } else if (element.type === 'checkbox') {
        fields[element.name] = element.checked;
      } else if (element.type === 'number') {
        fields[element.name] = parseFloat(element.value) || null;
      }
    } else if (element instanceof HTMLSelectElement && element.name) {
      fields[element.name] = element.value;
    }

    if (!element.validity.valid) {
      hasErrors = true;
    }
  }

  return { fields, hasErrors };
};

const Form = (props) => {
  const handleSubmit = function (e) {
    e.preventDefault();

    const { hasErrors } = extractFormDataByName(e.currentTarget);

    if (!hasErrors && props.onSubmit) {
      props.onSubmit();
    }
  };

  const handleChange = (e) => {
    const { fields, hasErrors } = extractFormDataByName(e.currentTarget);

    if (props.onChange) {
      props.onChange(fields, hasErrors);
    }
  };

  const handleReset = (e) => {
    const { fields, hasErrors } = extractFormDataByName(e.currentTarget);
    const resetFields = {};

    // eslint-disable-next-line no-restricted-syntax
    for (const fieldsKey in fields) {
      if (typeof fields[fieldsKey] === 'string') {
        resetFields[fieldsKey] = '';
      } else if (typeof fields[fieldsKey] === 'number') {
        resetFields[fieldsKey] = 0;
      } else if (typeof fields[fieldsKey] === 'boolean') {
        resetFields[fieldsKey] = false;
      }
    }

    props.onChange(resetFields, hasErrors);
  };

  return (
    <form
      {...props}
      onSubmit={handleSubmit}
      onChange={handleChange}
      onReset={handleReset}
    >
      {props.children}
    </form>
  );
};

Form.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  children: PropTypes.node,
};

export default Form;

import React from 'react';

const defaultContext = {
  commands: {
    handleGoalCancel: () => undefined,
    handleGoalModal: () => undefined,
    handleImageCancel: () => undefined,
    handleImageModal: () => undefined,
    handleRichTextCancel: () => undefined,
    handleRichTextModal: () => undefined,
    handleUpdateGoal: () => undefined,
    handleUpdateRichText: () => undefined,
    setUpdateError: () => undefined,
  },
  data: {
    teamInfo: undefined,
    teamPageInfo: undefined,
    editGoalModal: false,
    editImageModal: false,
    editRichTextModal: false,
    donationArrayData: {
      donationArray: [],
      minimumAmount: undefined,
    },
    eventDetails: {
      eventAddress: undefined,
      eventDate: undefined,
    },
    eventInfo: undefined,
    teamRosterData: undefined,
    updateError: undefined,
  },
};
export const TeamPageContext = React.createContext(defaultContext);

export const useTeamPageContext = () => {
  const context = React.useContext(TeamPageContext);

  if (context === undefined) {
    throw new Error('useTeamPageContext must be within an TeamPageProvider');
  }

  return context;
};

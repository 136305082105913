import React from 'react';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { array, func, number, string, bool } from 'prop-types';
import { Button, TabPanel } from '@frx/frx-components';
import {
  convertCentsToDollars,
  useSortColumns,
  useQueryString,
  useConfiguration,
} from '../../utils';
import { ROUTE_KEYS } from '../../app.constants';

import styles from './leaderboards.module.scss';
import { ColumnList, ListCell, ListRow } from '../column-list';

export const TopTeams = ({
  DropdownSelection,
  handleJoinTeam,
  id,
  teams,
  title,
  acceptingRegistrationsVisible,
}) => {
  const { t } = useTranslation(['entry']);
  const { items: sortedTeams, handleSort: handleParticipantSort } =
    useSortColumns(teams);
  const {
    queryString: { fr_id, reg_type },
    redirect,
  } = useQueryString();
  const configuration = useConfiguration();
  const {
    appSearchCriteria: { pagename },
  } = configuration.settings;
  const type = 'allt';
  const handleSeeAllTeams = () => {
    redirect(`/${ROUTE_KEYS.SEARCH}`, { pagename, fr_id, type });
  };

  return (
    <TabPanel id={id} className={cn(styles.topTeamsList)}>
      <h1 className='title is-hidden-mobile'>{title}</h1>
      <DropdownSelection />
      <ColumnList
        data={sortedTeams || []}
        width=''
        header={
          <ListRow
            width=''
            className='is-hidden-mobile'
            variant='headerLeaderboards'
          >
            <ListCell onClick={() => handleParticipantSort('name')}>
              {t('leaderboards.team_name')}
            </ListCell>
            <ListCell onClick={() => handleParticipantSort('amountRaised')}>
              {t('leaderboards.amount_raised')}
            </ListCell>
            <ListCell onClick={() => handleParticipantSort('numMembers')}>
              {t('leaderboards.team_size')}
            </ListCell>
            {!!acceptingRegistrationsVisible && <ListCell />}
          </ListRow>
        }
        renderItem={(team) => (
          <ListRow key={team.id} width=''>
            <ListCell>
              {reg_type !== undefined ? (
                <Button
                  buttonColor='blue'
                  onClick={() =>
                    (location.href = `${team.teamPageURL}&reg_type=${reg_type}`)
                  }
                  variant='text'
                >
                  {team.name}
                </Button>
              ) : (
                <Button
                  buttonColor='blue'
                  onClick={() => (location.href = `${team.teamPageURL}`)}
                  variant='text'
                >
                  {team.name}
                </Button>
              )}
            </ListCell>
            <ListCell>
              {team.total ||
                convertCentsToDollars(team.amountRaised, {
                  formatted: true,
                  rounded: true,
                  trimZeros: true,
                })}
            </ListCell>
            <ListCell>{team.numMembers || team.teamMembers}</ListCell>
            {acceptingRegistrationsVisible ? (
              <ListCell>
                <Button
                  buttonColor='blue'
                  onClick={() =>
                    handleJoinTeam(
                      team.id,
                      team.captainConsId || team.teamCaptain
                    )
                  }
                  variant='primary'
                >
                  {t('leaderboards.join_team')}
                </Button>
              </ListCell>
            ) : (
              <></>
            )}
          </ListRow>
        )}
      />
      {sortedTeams && (
        <div className={styles.action}>
          <Button variant='secondary' onClick={handleSeeAllTeams}>
            See all Teams
          </Button>
        </div>
      )}
    </TabPanel>
  );
};

TopTeams.propTypes = {
  DropdownSelection: func,
  handleJoinTeam: func,
  id: number,
  teams: array,
  title: string,
  acceptingRegistrationsVisible: bool,
};

import React from 'react';
import { string } from 'prop-types';

import styles from './ErrorMessage.module.scss';

export const ErrorMessage = ({ errorText, errorFormat }) => {
  let outputError;
  if (errorFormat === 'html') {
    outputError = <p dangerouslySetInnerHTML={{ __html: errorText }} />;
  } else {
    outputError = <p>{errorText}</p>;
  }
  return <div className={styles.container}>{outputError}</div>;
};

ErrorMessage.propTypes = {
  errorText: string,
  errorFormat: string,
};

import React from 'react';

export const AccordionContext = React.createContext();

export const useAccordionContext = () => {
  const context = React.useContext(AccordionContext);
  if (!context) {
    throw new Error('Accordion Render Failed');
  }
  return context;
};

import { isEqual } from 'lodash';
import {
  encodeAsQueryString,
  isEnglish,
  useAuth,
  useConfiguration,
  useQueryString,
} from '../../utils';
import { LANGUAGE_CODES, MARATHON_HOME_PAGE_URL } from '../../app.constants';

export const useLanguage = () => {
  const {
    settings: {
      luminate: {
        properties: {
          user: { locale },
        },
      },
    },
  } = useConfiguration();
  const { queryString } = useQueryString();
  const { pg } = queryString;
  const { logout } = useAuth();
  const { ES, EN } = LANGUAGE_CODES;
  /* const FCPAGESPATH = '/site/TR/Heroes/SJMMW'; */
  const FCPAGESPATH = ['&pg=personal', '&pg=team'];
  const thanksPage = isEqual(pg, 'rthanks');

  /* const isFCPage = () => FCPAGESPATH.includes(location.pathname); */
  const isFCPage = () =>
    FCPAGESPATH.some((item) => location.search.includes(item));
  const handleLogout = () =>
    logout().then(() => {
      if (thanksPage) {
        location.href = MARATHON_HOME_PAGE_URL;
      } else {
        location.reload();
      }
    });

  const handleLanguage = () =>
    (location.href = `${location.pathname}?${encodeAsQueryString({
      ...queryString,
      s_locale: isEnglish(locale) ? ES : EN,
    })}`);

  return {
    handleLanguage,
    handleLogout,
    isFCPage,
  };
};

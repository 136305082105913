import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import styles from './StepButton.module.scss';

export const StepButton = ({ soldOut, subtitle, title, ...props }) => (
  <button
    {...props}
    className={cn(styles.container, soldOut && styles.soldOutContainer)}
    aria-label={title}
  >
    {title && <div className={styles.title}>{title}</div>}
    {subtitle && <p className={styles.subtitle}>{subtitle}</p>}
  </button>
);

StepButton.propTypes = {
  ...PropTypes.instanceOf(HTMLButtonElement).isRequired,
  soldOut: PropTypes.bool,
  subtitle: PropTypes.string,
  title: PropTypes.string,
};

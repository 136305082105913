import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { useTranslation } from 'react-i18next';
import { InputField } from '../../../../../components';
import { AddressStateField } from './AddressStateField';
import { AddressCountryField } from './AddressCountryField';
import { useAuth } from '../../../../../utils';

import styles from './AddressField.module.scss';

export const AddressField = (props) => {
  const {
    user: { address },
  } = useAuth();
  const { t } = useTranslation();

  const { city, country, state, street1, street2, street3, zip } = address || {
    city: '',
    country: '',
    state: '',
    street1: '',
    street2: '',
    street3: '',
    zip: '',
  };

  return (
    <fieldset className={cn(styles.container, props.className)}>
      <InputField
        label={t('screens.personal_info.address')}
        name='address1'
        className={styles.street1}
        defaultValue={(props.formData && props.formData.address1) || street1}
        required
      />
      {street2 && (
        <InputField
          name='address2'
          className={styles.street1}
          defaultValue={(props.formData && props.formData.address2) || street2}
        />
      )}
      {street3 && (
        <InputField
          name='address3'
          className={styles.street1}
          defaultValue={(props.formData && props.formData.address3) || street3}
        />
      )}
      <InputField
        label={t('screens.personal_info.city')}
        name='city'
        className={styles.city}
        defaultValue={(props.formData && props.formData.city) || city}
        required
      />
      <AddressStateField
        name='state'
        className={styles.state}
        defaultValue={(props.formData && props.formData.state) || state}
        required
      />
      <InputField
        label={t('screens.personal_info.zip')}
        name='zip'
        className={styles.zip}
        defaultValue={(props.formData && props.formData.zip) || zip}
        required
      />
      <AddressCountryField
        name='country'
        className={styles.country}
        defaultValue={(props.formData && props.formData.country) || country}
      />
    </fieldset>
  );
};

AddressField.propTypes = {
  className: PropTypes.string,
  formData: PropTypes.object,
};

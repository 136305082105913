import React from 'react';
import { useHistory } from 'react-router';
import { Icon } from '@frx/frx-components';
import { useTranslation } from 'react-i18next';
import { SummaryOptions } from '..';
import { ROUTE_KEYS } from '../../app.constants';
import { colors } from '../../styles';
import { isEqual } from '../../utils';

import styles from './Sidebar.module.scss';

export const Sidebar = () => {
  const { location } = useHistory();
  const { t } = useTranslation(['common']);

  const isSummaryScreen = isEqual(location.pathname, `/${ROUTE_KEYS.SUMMARY}`);
  const isPaymentScreen = isEqual(location.pathname, `/${ROUTE_KEYS.PAYMENT}`);

  const { swatch1 } = colors.primary;

  return (
    <aside className={styles.sidebarContainer}>
      {!isSummaryScreen && !isPaymentScreen ? (
        <>
          <div className={styles.sticky}>
            <h4>{t('sidebar.your_registration_summary')}:</h4>
            <section>
              <SummaryOptions />
            </section>
          </div>
        </>
      ) : (
        <div className={styles.almostDoneContainer}>
          <Icon color={swatch1} name='person-check' size={80} />
          <h4>{t('sidebar.almost_done_text')}</h4>
          {isSummaryScreen && (
            <span>{t('sidebar.confirm_and_complete_registration_text')}</span>
          )}
          {isPaymentScreen && <span>{t('sidebar.submit_payment_text')}</span>}
        </div>
      )}
    </aside>
  );
};

import { useCallback, useEffect, useRef, useState } from 'react';

import { useFetcher } from './QueryContext';

export const useQueryAll = (queryPath, criteria = {}) => {
  const fetcher = useFetcher();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [data, setData] = useState(null);
  const { current: queryOptions } = useRef({ queryPath, criteria });

  useEffect(() => {
    setLoading(true);

    const promise = fetcher
      .getAll(queryOptions.queryPath, queryOptions.criteria)
      .then((results) => setData(results))
      .catch((error) => setError(error))
      .finally(() => setLoading(false));

    return promise.cancel;
  }, [queryOptions, fetcher]);

  return { data, error, loading };
};

export const useQuery = (queryPath, criteria) => {
  const fetcher = useFetcher();
  const [queryResponse, setQueryResponse] = useState({
    data: null,
    error: null,
    loading: false,
  });
  const { current: queryOptions } = useRef({ criteria });

  const fetch = useCallback(async () => {
    setQueryResponse((prevState) => ({ ...prevState, loading: true }));
    await fetcher
      .get(queryPath, queryOptions.criteria)
      .then((results) => {
        if (results.errorResponse) {
          setQueryResponse((prevState) => ({
            ...prevState,
            error: results.errorResponse.message,
          }));
        } else {
          setQueryResponse((prevState) => ({
            ...prevState,
            data: results[Object.keys(results)[0]],
          }));
        }
      })
      .catch((err) =>
        setQueryResponse((prevState) => ({ ...prevState, error: err }))
      )
      .finally(() =>
        setQueryResponse((prevState) => ({ ...prevState, loading: false }))
      );
  }, [fetcher, queryOptions, queryPath]);

  useEffect(() => fetch(), [fetch]);

  return { queryResponse, refetch: fetch };
};

export const useMutation = (queryPath) => {
  const fetcher = useFetcher();
  const [queryResponse, setQueryResponse] = useState({
    data: null,
    error: null,
    loading: false,
  });
  return [
    async (criteria = {}) => {
      setQueryResponse((prevState) => ({ ...prevState, loading: true }));
      await fetcher
        .get(queryPath, criteria)
        .then((results) => {
          if (results.errorResponse) {
            setQueryResponse((prevState) => ({
              ...prevState,
              error: results.errorResponse.message,
            }));
          } else {
            setQueryResponse((prevState) => ({
              ...prevState,
              data: results[Object.keys(results)[0]],
            }));
          }
        })
        .catch((err) =>
          setQueryResponse((prevState) => ({ ...prevState, error: err }))
        )
        .finally(() =>
          setQueryResponse((prevState) => ({ ...prevState, loading: false }))
        );
    },
    queryResponse,
  ];
};

import React from 'react';

const defaultContext = Object.freeze({});

export const CompanyPageContext = React.createContext(defaultContext);

export const useCompanyPageProvider = () => {
  const context = React.useContext(CompanyPageContext);

  if (context === undefined) {
    throw new Error(
      'useCompanyPageProvider must be within an CacheQueryProvider'
    );
  }

  return context;
};

// This import must be first
import './index.scss';
import 'react-app-polyfill/stable';
import 'abortcontroller-polyfill';
import './polyfills';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import './i18n';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import {
  AppDataProvider,
  ConfigurationProvider,
  loadSettings,
  SecurityProvider,
} from './utils';
import FetchQueryProvider from './utils/queries/FetchQueryProvider';

import App from './App';

import { ScrollToTop } from './components';
import { getSentryConfig } from './environment';
import UnrevealError from './utils/UnrevealError';

const settings = loadSettings();

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
    },
  },
});

const sentryConfig = getSentryConfig(window.location);

Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_PROJECT_DSN,
  enabled: sentryConfig.enabled,
  release: sentryConfig.release,
  environment: sentryConfig.environment,
  attachStacktrace: true,
  integrations: [
    Sentry.reactRouterV5BrowserTracingIntegration({ history: {} }),
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: false,
      blockAllMedia: false,
    }),
  ],
  // This error is from Instagram browser and made sure no user impacted so far
  ignoreErrors: [
    /Can't find variable: _AutofillCallbackHandler/i,
    /Can't find variable: jQuery/i,
    /jQuery is not defined/i,
  ],
  // These domains are external for our app
  denyUrls: [
    /https:\/\/assets\.adobedtm\.com/,
    /https:\/\/cdn\.cookielaw\.org/,
    /https:\/\/maps\.googleapis\.com/,
    /https:\/\/s.go-mpulse\.net/,
  ],
  tracePropagationTargets: [
    'localhost',
    'https://fundraising.stjude.org/',
    'https://fundraising.qa.stjude.org/',
  ],
  sampleRate: sentryConfig.sampleRate,
  tracesSampleRate: sentryConfig.tracesSampleRate,
  replaysSessionSampleRate: sentryConfig.replaysSessionSampleRate,
  replaysOnErrorSampleRate: sentryConfig.replaysOnErrorSampleRate,
});

ReactDOM.render(
  <React.StrictMode>
    <Sentry.ErrorBoundary fallback={(errorData) => <UnrevealError />}>
      <QueryClientProvider client={queryClient}>
        <ConfigurationProvider settings={settings}>
          <SecurityProvider>
            <FetchQueryProvider>
              <BrowserRouter basename={settings.baseUrl}>
                <AppDataProvider>
                  <ScrollToTop />
                  <App />
                </AppDataProvider>
              </BrowserRouter>
            </FetchQueryProvider>
          </SecurityProvider>
        </ConfigurationProvider>
        <ReactQueryDevtools />
      </QueryClientProvider>
    </Sentry.ErrorBoundary>
  </React.StrictMode>,
  document.getElementById('root')
);

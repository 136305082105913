import React from 'react';
import { useTranslation } from 'react-i18next';
import styles from './ContactConfirmation.module.scss';

export const ContactConfirmation = () => {
  const { t } = useTranslation(['contact']);
  return (
    <main className={styles.container}>
      <h1>{t('confirmation')}</h1>
      <h2>{t('representative')}</h2>
    </main>
  );
};

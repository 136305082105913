import React from 'react';

import cn from 'classnames';
import { Icon } from '@frx/frx-components';
import { bool, number, oneOfType, string } from 'prop-types';

import { colors } from '../../styles';
import { useAccordionContext } from './accordion-context';
import styles from './accordions.module.scss';

const Accordion = ({ label, disabled, id, ...rest }) => {
  const { activeTab, onTabChange } = useAccordionContext();
  const isActive = activeTab === id;
  const handleClick = () => onTabChange(isActive ? undefined : id);

  const toggleClass = cn(styles.accordionToggle, disabled && styles.disabled);
  const iconColor = disabled
    ? colors.monochromatic.grey50
    : colors.secondary.swatch4;

  return (
    <div
      tabIndex={0}
      role='menuitem'
      className={toggleClass}
      onClick={handleClick}
      onKeyPress={handleClick}
      {...rest}
    >
      <p
        className='is-6 has-text-weight-bold'
        data-testid={label}
        aria-label={label}
      >
        {label}
      </p>
      <Icon
        className={styles.icon}
        color={iconColor}
        name={isActive ? 'chevron-up' : 'chevron-down'}
        size={16}
      />
    </div>
  );
};

Accordion.propTypes = {
  label: string,
  disabled: bool,
  id: oneOfType([number, string]).isRequired,
};

Accordion.defaultProps = {
  disabled: false,
};

export default Accordion;

import React from 'react';
import kebabCase from 'lodash/kebabCase';
import { useLocation } from 'react-router-dom';
import { bool, func, string } from 'prop-types';
import i18n from 'i18next';
import { Button } from '../button/Button';

import styles from './ButtonsBar.module.scss';

export const ButtonsBar = ({
  disabled,
  handlePrimary,
  handleSecondary,
  primaryButtonText,
  secondaryButtonText,
}) => {
  const location = useLocation();
  const path = location.pathname.split('/')[1];
  return (
    <div className={styles.actions}>
      <Button
        {...{
          onClick: handlePrimary,
          disabled,
          variant: 'primary',
          type: 'submit',
        }}
        data-tracking={`${path}-${kebabCase(primaryButtonText)}`}
      >
        {primaryButtonText}
      </Button>
      <Button
        {...{ onClick: handleSecondary, variant: 'text', type: 'button' }}
        data-tracking={`${path}-${kebabCase(secondaryButtonText)}`}
      >
        {secondaryButtonText}
      </Button>
    </div>
  );
};

ButtonsBar.propTypes = {
  disabled: bool,
  handlePrimary: func,
  handleSecondary: func,
  primaryButtonText: string,
  secondaryButtonText: string,
};

i18n.on('languageChanged', () => {
  ButtonsBar.defaultProps.primaryButtonText = i18n.t(
    'screens.personal_info.next'
  );
  ButtonsBar.defaultProps.secondaryButtonText = i18n.t(
    'screens.personal_info.previous'
  );
});

ButtonsBar.defaultProps = {
  disabled: false,
  handlePrimary: () => undefined,
  handleSecondary: () => undefined,
  primaryButtonText: i18n.t('screens.personal_info.next'),
  secondaryButtonText: i18n.t('screens.personal_info.previous'),
};

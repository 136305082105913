import React, { useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { QueryError, QueryLoading } from '@frx/frx-components';
import { Button, Form, InputField } from '../../../components';
import { ROUTE_KEYS } from '../../../app.constants';
import {
  camelCase,
  useAuth,
  useContactPageContext,
  useQueryString,
} from '../../../utils';

import styles from './Contact.module.scss';

export const Contact = () => {
  const [surveyQuestions, setSurveyQuestions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [errorMsg, setErrorMsg] = useState();
  const [surveyId, setSurveyId] = useState();
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [hasFormErrors, setHasFormErrors] = useState(true);
  const [formData, setFormData] = useState(true);
  const { replace } = useHistory();
  const {
    buildCleanPath,
    queryString: { pagename, fr_id },
  } = useQueryString();
  const { user } = useAuth();
  const {
    commands: { getSurveyId, getSurveyQuestions, submitSurvey },
  } = useContactPageContext();
  const { t } = useTranslation(['contact']);

  // TODO: hardcoding these values for now, revisit
  const surveyName = 'SJMMW Contact us';

  const halfRowFields = ['firstName', 'lastName'];
  const questionLengths = {
    LargeTextValue: 1000,
    ShortTextValue: 40,
  };

  const handleFormChange = (fields, hasErrors) => {
    setFormData(fields);
    setHasFormErrors(hasErrors);
  };

  const handleFormSubmit = () => {
    setIsSubmitting(true);
    try {
      const questionIds = Object.keys(formData);
      const formattedFormData = questionIds.reduce((acc, cv) => {
        const key = `question_${cv}`;
        acc[key] = formData[cv];
        return acc;
      }, {});
      submitSurvey(formattedFormData, surveyId).then((res) => {
        if (res.success === 'true') {
          replace(
            buildCleanPath(`/${ROUTE_KEYS.CONTACT_CONFIRMATION}`, {
              pagename,
              fr_id,
            })
          );
        } else {
          setIsSubmitting(false);
        }
      });
    } catch (err) {
      console.error(err);
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    setIsLoading(true);
    try {
      getSurveyId(surveyName).then((survey_id) => {
        setSurveyId(survey_id);
        getSurveyQuestions(survey_id).then((questions) => {
          setSurveyQuestions(questions);
          setIsLoading(false);
        });
      });
    } catch (err) {
      setErrorMsg(err);
      setSurveyQuestions(null);
      setIsLoading(false);
    }
  }, [getSurveyId, getSurveyQuestions]);

  return (
    <main className={styles.container}>
      <div className={styles.formContainer}>
        {surveyQuestions && surveyQuestions.length && (
          <>
            <h1 className={styles.title}>{t('contact_us')}</h1>
            <h4>{t('please_fill')}</h4>
            <Form onChange={handleFormChange} onSubmit={handleFormSubmit}>
              {surveyQuestions
                .sort((a, b) =>
                  Number(a.questionOrderNumber) < Number(b.questionOrderNumber)
                    ? -1
                    : 1
                )
                .map((q) => {
                  const isCaption = q.questionType === 'Caption';
                  const isTextArea = q.questionType === 'LargeTextValue';
                  const maxLength =
                    q.questionType !== 'Caption' &&
                    questionLengths[q.questionType];
                  const defaultValue =
                    (user && user[camelCase(q.questionText)]) || '';
                  const classname = halfRowFields.includes(
                    camelCase(q.questionText)
                  )
                    ? styles[camelCase(q.questionText)]
                    : styles.question;
                  const mask = q.questionText === 'Phone' && 'phone';
                  if (isCaption)
                    return (
                      <h4 className={styles.caption} key={q.questionId}>
                        {q.questionText.replace(/<[^>]*>?/gm, '')}
                      </h4>
                    );
                  if (!isCaption)
                    return (
                      <InputField
                        className={classname}
                        defaultValue={defaultValue}
                        errorText={() => 'Please fill out this field'}
                        isTextArea={isTextArea}
                        key={q.questionId}
                        label={q.questionText}
                        mask={mask}
                        maxLength={maxLength}
                        name={q.questionId}
                        required={q.questionRequired === 'true'}
                      />
                    );
                  return <></>;
                })}
              <div className={styles.buttonContainer}>
                <Button
                  disabled={hasFormErrors || isSubmitting}
                  variant='primary'
                  type='submit'
                >
                  {t('submit')}
                </Button>
              </div>
            </Form>
          </>
        )}
        {isLoading && <QueryLoading />}
        {!surveyQuestions && <QueryError error={errorMsg} />}
      </div>
    </main>
  );
};

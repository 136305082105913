import React from 'react';
import { node } from 'prop-types';

import styles from './Figure.module.scss';

export const Figure = ({ children, ...rest }) => (
  <figure className='image'>
    <img {...rest} src={rest.src} alt={rest.alt} />
    {children && <figcaption className={styles.caption}>{children}</figcaption>}
  </figure>
);

Figure.propTypes = {
  children: node,
};

import React from 'react';
import { node } from 'prop-types';
import { QueryError, QueryLoading } from '@frx/frx-components';
import DayJS from 'dayjs';
import { PersonalPageContext } from './PersonalContext';
import { useQueryString } from '../../../QueryStrings';
import { useConfiguration } from '../../../configuration/ConfigurationContext';
import { useQuery } from '../../QueryHooks';
import { useTopDonors } from './personal';
import { isEmpty, isEqual } from '../../../utils';
import { useFetcher } from '../../QueryContext';
import { LOADING_LABEL } from '../../../../app.constants';
import {
  getBadgeSrc,
  heroImage1_thumbnail,
  heroImage2_thumbnail,
  heroImage3_thumbnail,
  heroImage4_thumbnail,
  heroImage5_thumbnail,
  heroImage6_thumbnail,
  heroImage7_thumbnail,
} from '../../../../assets';
import { useRegistrationDataProvider } from '../../../AppDataContext';

const CONTENTAPI = 'CRContentAPI';
const DEFAULTHEROIMAGE = 'hero-image-1';
const HEROIMAGEBACKGROUNDKEY = 'PERSONAL_BACKGROUND';
const TEAMRAISERAPI = 'CRTeamraiserAPI';
const CRDONATIONAPI = 'CRDonationAPI';

export const PersonalPageProvider = React.memo(({ children }) => {
  const [editHeroImageModal, setEditHeroImageModal] = React.useState(false);
  const [heroImageIndex, setHeroImageIndex] = React.useState();
  const [editImageModal, setEditImageModal] = React.useState(false);
  const [editGoalModal, setEditGoalModal] = React.useState(false);
  const [editRichTextModal, setEditRichTextModal] = React.useState(false);
  const [updateError, setUpdateError] = React.useState(false);
  const {
    queryString: { fr_id, px: cons_id },
  } = useQueryString();

  const {
    data: { eventInfo },
  } = useRegistrationDataProvider();

  const fetcher = useFetcher();
  const configuration = useConfiguration();
  const { apiKey, url, properties } = configuration.settings.luminate;
  const {
    fivePlusDonations,
    formId: form_id,
    halfwayToGoal,
    kickStartDonor,
    user: { consId, token },
  } = properties;
  const defaultOptions = {
    fr_id,
    cons_id,
  };

  const topDonors = useTopDonors({
    pathUrl: `${url}/SPageServer?pagename=api_top_donors&pgwrap=n&fr_id=${fr_id}&cons_id=${cons_id}&limit=5`,
  });

  const screenNameData = useQuery(`${TEAMRAISERAPI}/getParticipants`, {
    first_name: '%%%',
    fr_id,
    list_filter_column: 'reg.cons_id',
    list_filter_text: cons_id,
  });

  const getSurveyResponses = useQuery(`${TEAMRAISERAPI}/getSurveyResponses`, {
    auth: token,
    fr_id,
  });

  const getHeroImage = useQuery(`${CONTENTAPI}/getTagInfo`, {
    auth: token,
    content: JSON.stringify({
      // eslint-disable-next-line
      backgroundImage: `[[S48:${fr_id}-${cons_id}:question:PersonalBackground]]​`,
    }),
  });

  const getTeamraisersByInfoResponse = useQuery(
    `${TEAMRAISERAPI}/getTeamraisersByInfo`,
    {
      list_filter_column: 'fr_id',
      list_filter_text: fr_id,
      name: '%%%',
    }
  );

  const personalAvatarData = useQuery(
    `${TEAMRAISERAPI}/getPersonalPhotos`,
    defaultOptions
  );

  const participantProgressData = useQuery(
    `${TEAMRAISERAPI}/getParticipantProgress`,
    defaultOptions
  );

  const personalPageInfoData = useQuery(
    `${TEAMRAISERAPI}/getPersonalPageInfo`,
    defaultOptions
  );

  const donationArrayResponse = useQuery(
    `${CRDONATIONAPI}/getDonationFormInfo`,
    {
      form_id,
      fr_id,
    }
  );

  const queries = [
    getTeamraisersByInfoResponse,
    participantProgressData,
    personalAvatarData,
    personalPageInfoData,
    screenNameData,
  ];

  const isQueryLoading = [...queries].some(
    ({ queryResponse }) => queryResponse && queryResponse.loading
  );
  const isQueryError = queries.find(
    ({ queryResponse }) => queryResponse && queryResponse.error
  );

  if (isQueryLoading) return <QueryLoading label={LOADING_LABEL} />;
  if (
    (!screenNameData.queryResponse.data &&
      !topDonors.data &&
      !donationArrayResponse?.queryResponse?.data) ||
    isQueryError
  )
    return <QueryError {...{ error: isQueryError }} />;

  const requiredResponses = (
    getSurveyResponses?.queryResponse?.data?.responses || []
  ).filter((q) => q.questionRequired === 'true');
  const getHeroImagePreview = getHeroImage?.queryResponse?.data?.preview;
  const heroImageBackground =
    getHeroImagePreview && JSON.parse(getHeroImagePreview).backgroundImage;
  const achievements = [
    {
      badgeLabel: '5+ Donations',
      badgeSrc: getBadgeSrc('fivePlusDonations', fivePlusDonations === 'true'),
    },
    {
      badgeLabel: 'Halfway to Goal',
      badgeSrc: getBadgeSrc('halfwayToGoal', halfwayToGoal === 'true'),
    },
    {
      badgeLabel: 'Kickstart Donor',
      badgeSrc: getBadgeSrc('kickStartDonor', kickStartDonor === 'true'),
    },
  ];
  const thisEvent = getTeamraisersByInfoResponse.queryResponse.data.teamraiser;
  const eventDate =
    thisEvent.event_date && DayJS(thisEvent.event_date).format('MMMM D, YYYY');
  const eventAddress = `${thisEvent.street_address}\n${thisEvent.city}, ${thisEvent.state} ${thisEvent.zip}`;
  const donationLevels =
    donationArrayResponse?.queryResponse?.data?.donationLevels;
  const userSpecifiedAmount = (
    (donationLevels || {}).donationLevel || []
  ).filter((d) => d.userSpecified === 'true');
  const minimumAmount =
    userSpecifiedAmount.length &&
    Number(userSpecifiedAmount[0].amount.formatted.replace('$', ''));
  const donationArray = ((donationLevels || {}).donationLevel || []).map(
    (d) => ({
      name: d.name,
      value:
        d.userSpecified !== 'true'
          ? d.amount.formatted.replace('.00', '')
          : 'Other',
      link: `${url}/Donation2?df_id=${form_id}&${form_id}.donation=form1&PROXY_ID=${cons_id}&PROXY_TYPE=20&FR_ID=${fr_id}&set.DonationLevel=${d.level_id}`,
    })
  );
  const { participant } = screenNameData.queryResponse.data;
  const [profileImage] = personalAvatarData.queryResponse.data.photoItem;
  const participantProgress =
    participantProgressData.queryResponse.data.personalProgress;
  const personalPageInfo = personalPageInfoData.queryResponse.data.personalPage;
  const ownPage = isEqual(cons_id, consId);
  const heroImagesArr = [
    heroImage1_thumbnail,
    heroImage2_thumbnail,
    heroImage3_thumbnail,
    heroImage4_thumbnail,
    heroImage5_thumbnail,
    heroImage6_thumbnail,
    heroImage7_thumbnail,
  ];

  const handleImageModal = () => setEditImageModal((prevState) => !prevState);

  const handleImageCancel = () => {
    handleImageModal();
    setUpdateError();
  };

  const handleGoalModal = () => setEditGoalModal((prevState) => !prevState);
  const handleGoalCancel = () => {
    handleGoalModal();
    setUpdateError();
  };

  const handleRichTextModal = () =>
    setEditRichTextModal((prevState) => !prevState);
  const handleRichTextCancel = () => {
    handleRichTextModal();
    setUpdateError();
  };

  const handleUpdateImage = async (blob) => {
    const formData = new FormData();

    formData.append('v', '1.0');
    formData.append('api_key', apiKey);
    formData.append('response_format', 'json');
    formData.append('method', 'uploadPersonalPhoto');

    formData.append('fr_id', fr_id);
    formData.append('graphic_upload_file', blob, 'photo.jpg');
    formData.append('graphic_upload_upload', true);
    formData.append('auth', token);
    formData.append('suppress_response_codes', true);

    const fetchOptions = {
      method: 'POST',
      credentials: 'include',
      body: formData,
    };

    const uploadPersonalPhotoResponseText = await fetch(
      `${url}/${TEAMRAISERAPI}`,
      fetchOptions
    ).then((res) => res.text());

    let parsedResponse;
    try {
      parsedResponse = JSON.parse(uploadPersonalPhotoResponseText);
    } catch (err) {
      parsedResponse = uploadPersonalPhotoResponseText;
    }

    if (parsedResponse.errorResponse) {
      setUpdateError(parsedResponse.errorResponse.message);
    } else {
      handleImageCancel();
      personalAvatarData.refetch();
    }
  };

  const handleUpdateGoal = (goal) =>
    fetcher
      .get(`${TEAMRAISERAPI}/updateRegistration`, {
        auth: token,
        fr_id,
        goal,
      })
      .then((res) => {
        if (res.errorResponse) {
          setUpdateError(res.errorResponse.message);
        } else {
          handleGoalCancel();
          participantProgressData.refetch();
        }
      });

  const handleUpdateRichText = (richText) =>
    fetcher
      .get(`${TEAMRAISERAPI}/updatePersonalPageInfo`, {
        auth: token,
        fr_id,
        rich_text: richText,
      })
      .then((res) => {
        if (res.errorResponse) {
          setUpdateError(res.errorResponse.message);
        } else {
          handleRichTextCancel();
          personalPageInfoData.refetch();
        }
      });

  const handleHeroImageModalShow = () =>
    setEditHeroImageModal((prevState) => !prevState);
  const handleChangeHeroImageIndex = (idx) => setHeroImageIndex(idx);
  const handleSetHeroImageModal = (heroImage) => {
    const questionsForPosting = requiredResponses.reduce((acc, cv) => {
      const key = `question_key_${cv.key}`;
      const value = isEmpty(cv.responseValue) ? '' : cv.responseValue;
      acc[key] = value;
      return acc;
    }, {});
    return fetcher
      .get(`${TEAMRAISERAPI}/updateSurveyResponses`, {
        auth: token,
        fr_id,
        ...questionsForPosting,
        [`question_key_${HEROIMAGEBACKGROUNDKEY}`]: heroImage,
      })
      .then((res) => {
        if (res.errorResponse) {
          console.log('res', res);
          setUpdateError(res.errorResponse.message);
        } else {
          handleHeroImageModalShow();
          getHeroImage.refetch();
        }
      });
  };

  const context = {
    commands: {
      handleChangeHeroImageIndex,
      handleGoalCancel,
      handleGoalModal,
      handleHeroImageModalShow,
      handleImageCancel,
      handleImageModal,
      handleRichTextCancel,
      handleRichTextModal,
      handleSetHeroImageModal,
      handleUpdateGoal,
      handleUpdateImage,
      handleUpdateRichText,
      setHeroImageIndex,
    },
    data: {
      achievements,
      donationArrayData: { donationArray, minimumAmount },
      editGoalModal,
      editHeroImageModal,
      editImageModal,
      editRichTextModal,
      eventInfo,
      eventDetails: { eventAddress, eventDate },
      heroImageBackground:
        isEmpty(heroImageBackground) ||
        isEqual(heroImageBackground, 'User Provided No Response')
          ? DEFAULTHEROIMAGE
          : heroImageBackground,
      heroImageIndex,
      heroImagesArr,
      ownPage,
      participant,
      participantProgress,
      personalPageInfo,
      profileImage,
      topDonors: topDonors.data,
      updateError,
    },
  };

  return (
    <PersonalPageContext.Provider value={context}>
      {children}
    </PersonalPageContext.Provider>
  );
});

PersonalPageProvider.propTypes = {
  children: node,
};

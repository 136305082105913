import React from 'react';
import { node, string } from 'prop-types';
import cn from 'classnames';

import styles from './Tooltip.module.scss';

export const Tooltip = ({ children, className, content, ...rest }) => {
  const [isVisible, setIsVisible] = React.useState(false);

  const handleMouseEnter = () => setIsVisible(true);
  const handleMouseLeave = () => setIsVisible(false);

  /** I have added a position by default as top for now, going further we can improve to different placements */
  const toolTipStyles = cn(className, styles.toolTip, styles.top);

  return (
    <div
      {...rest}
      {...{
        className: styles.container,
        onMouseEnter: handleMouseEnter,
        onMouseLeave: handleMouseLeave,
      }}
    >
      {children}
      {isVisible && <div className={toolTipStyles}>{content}</div>}
    </div>
  );
};

Tooltip.propTypes = {
  children: node,
  className: string,
  content: string,
};

import React, { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Modal } from '@frx/frx-components';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import {
  Button,
  CheckboxField,
  ErrorMessage,
  Form,
  InputField,
  Screen,
} from '../../components';
import { AddressCountryField } from '../personal/questions';
import {
  encodeAsQueryString,
  isEqual,
  isLocalHost,
  setEvent,
  setPage,
  useAuth,
  useQueryString,
  useSubmitRegistration,
} from '../../utils';
import { ROUTE_KEYS } from '../../app.constants';

import styles from './Login.module.scss';

const COUNTRIES = {
  CA: 'Canada',
  US: 'United States',
};

export const Login = () => {
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [forgotLoginMsg, setForgotLoginMsg] = useState();
  const [credentials, setCredentials] = useState({ country: COUNTRIES.US });
  const [passwordError, setPasswordError] = useState();
  const [screen, setScreen] = useState('login');

  const {
    forgotLoginInfo,
    login,
    loginErrorMessage,
    setLoginErrorMessage,
    setSignupErrorMessage,
    signupErrorMessage,
    signup,
  } = useAuth();
  const [hasFormErrors, setHasFormErrors] = useState(true);
  const { queryString, buildCleanPath } = useQueryString();
  const { startTransaction } = useSubmitRegistration();
  const { t } = useTranslation(['login']);
  const history = useHistory();

  const passwordRef = useRef();
  const passwordConfirmRef = useRef();

  useEffect(() => {
    setPage({ section: screen, screen });
    setEvent({ eventName: 'spa-change' });
  }, [screen]);

  const handleSwitchScreeen = (to) => {
    if (loginErrorMessage) setLoginErrorMessage();
    if (passwordError) setPasswordError();
    if (signupErrorMessage) setSignupErrorMessage();
    setScreen(to);
  };

  const isFundraisingRedirect = () =>
    queryString && queryString.redirect && queryString.redirect.includes('TR');

  const handleLogin = () => {
    setIsSubmitting(true);
    login(credentials.userName, credentials.password).then(
      async (loginResponse) => {
        setIsSubmitting(false);
        if (loginResponse && !loginResponse.code) {
          const { redirect, ...others } = queryString;
          if (!isLocalHost) {
            if (isFundraisingRedirect()) {
              location.href = `/site/TR/Heroes/SJMMW?${encodeAsQueryString(
                others
              )}`;
            } else {
              await startTransaction().then((res) => {
                if (res) {
                  const pgValue = new URL(res.url).searchParams.get('pg');
                  if (isEqual('rthanks', pgValue)) {
                    window.location.href = `${res.url}`;
                  } else {
                    history.replace(
                      buildCleanPath(redirect || `/${ROUTE_KEYS.TEAM}`, others)
                    );
                  }
                }
              });
            }
          } else {
            history.replace(
              buildCleanPath(redirect || `/${ROUTE_KEYS.TEAM}`, others)
            );
          }
        }
      }
    );
  };

  const handleCreateAccount = () => {
    setIsSubmitting(true);

    if (passwordError) setPasswordError();
    if (signupErrorMessage) setSignupErrorMessage();

    const password1 = passwordRef.current && passwordRef.current.value;
    const password2 =
      passwordConfirmRef.current && passwordConfirmRef.current.value;

    if (password1 !== password2) {
      scroll(0, 0);
      setPasswordError(t('password_error'));
    } else {
      signup(credentials).then((res) => {
        if (res) {
          handleLogin();
        } else {
          scroll(0, 0);
        }
      });
    }

    setIsSubmitting(false);
  };

  const handleChange = (fields, hasErrors) => {
    setHasFormErrors(hasErrors);
    setCredentials(fields);
  };

  const handleFocusInput = () => {
    if (loginErrorMessage) setLoginErrorMessage();
  };

  const handleRecoverCredentials = () => {
    setIsSubmitting(true);
    forgotLoginInfo(credentials.recoverEmail).then(() => {
      setForgotLoginMsg(
        `${t('login.errors.recover_credentials')} ${credentials.recoverEmail}`
      );
      setIsSubmitting(false);
    });
  };

  const handleShowModal = () => {
    setForgotLoginMsg();
    setIsModalVisible((prevState) => !prevState);
    setCredentials({ country: COUNTRIES.US });
  };

  const isUS = isEqual(credentials.country, COUNTRIES.US);
  const isCA = isEqual(credentials.country, COUNTRIES.CA);

  const checkboxUSA = isUS && t('checkbox_usa');
  const checkboxCanada = isCA && t('checkbox_can');

  return (
    <Screen className={styles.container}>
      {screen === 'login' && (
        <>
          <h1>{t('login.header')}:</h1>
          <Form onSubmit={handleLogin} onChange={handleChange}>
            {loginErrorMessage && (
              <div className={styles.errorMessageContainer}>
                <ErrorMessage
                  {...{ errorText: loginErrorMessage.replace('_', '') }}
                />
              </div>
            )}
            <InputField
              className={styles.username}
              errorText={() => t('login.username_input.error')}
              label={t('login.username_input.label')}
              name='userName'
              onFocus={handleFocusInput}
              required
              type='text'
            />
            <InputField
              className={styles.password}
              errorText={() => t('login.password_input.error')}
              label={t('login.password_input.label')}
              name='password'
              onFocus={handleFocusInput}
              required
              type='password'
            />
            <div className={styles.actions}>
              <Button
                className={styles.loginButton}
                type='submit'
                data-tracking='login'
                disabled={hasFormErrors}
                isLoading={isSubmitting}
                variant='primary'
              >
                {t('login.login_button')}
              </Button>
              {!isFundraisingRedirect() && (
                <Button
                  className={styles.createAccountButton}
                  type='button'
                  data-tracking='switch-to-create-account'
                  onClick={() => handleSwitchScreeen('signup')}
                >
                  {t('login.create_account_button')}
                </Button>
              )}
            </div>
          </Form>
          <div className={cn(styles.actions, styles.modalActions)}>
            <Button
              className={styles.loginButton}
              onClick={handleShowModal}
              variant='text'
            >
              {t('login.forgot_login_button')}
            </Button>
            <Modal
              onClosePress={handleShowModal}
              title={t('login.forgot_login_modal.header')}
              visible={isModalVisible}
            >
              <p>{t('login.forgot_login_modal.paragraph')}</p>
              <Form onSubmit={handleRecoverCredentials} onChange={handleChange}>
                <InputField
                  className={styles.recoverEmail}
                  errorText={() =>
                    t('login.forgot_login_modal.email_input.error')
                  }
                  label={t('login.forgot_login_modal.email_input.label')}
                  name='recoverEmail'
                  required
                  type='email'
                />
                {forgotLoginMsg && (
                  <div className={styles.errorMessageContainer}>
                    <ErrorMessage errorText={forgotLoginMsg} />
                  </div>
                )}
                <Button
                  type='submit'
                  className={styles.submitRecoverCredentials}
                  disabled={hasFormErrors}
                  isLoading={isSubmitting}
                  variant='primary'
                >
                  {t('login.forgot_login_modal.submit_button')}
                </Button>
              </Form>
            </Modal>
          </div>
        </>
      )}
      {screen === 'signup' && (
        <>
          <h1>{t('signup.header')}:</h1>
          <h2 className={styles.returningUserText}>
            {t('signup.returning.header')}
          </h2>
          <Button
            className={styles.loginWithExistingAccount}
            data-tracking='switch-to-login'
            onClick={() => handleSwitchScreeen('login')}
            variant='text'
          >
            {t('signup.returning.button')}
          </Button>
          <Form onSubmit={handleCreateAccount} onChange={handleChange}>
            {signupErrorMessage && (
              <div className={styles.errorMessageContainer}>
                <ErrorMessage {...{ errorText: signupErrorMessage }} />
              </div>
            )}
            {passwordError && (
              <div className={styles.errorMessageContainer}>
                <ErrorMessage {...{ errorText: passwordError }} />
              </div>
            )}
            <InputField
              className={styles.firstName}
              errorText={() => t('signup.firstname_input.error')}
              label={t('signup.firstname_input.label')}
              name='firstName'
              // TODO: not allow only spaces
              pattern='[^()[\]{}<>*\u0026\^%$#@!^0-9*]+'
              required
              type='text'
            />
            <InputField
              className={styles.lastName}
              errorText={() => t('signup.lastname_input.error')}
              label={t('signup.lastname_input.label')}
              name='lastName'
              // TODO: not allow only spaces
              pattern='[^()[\]{}<>*\u0026\^%$#@!^0-9*]+'
              required
              type='text'
            />
            <InputField
              className={styles.email}
              errorText={() => t('signup.email_input.error')}
              label={t('signup.email_input.label')}
              name='email'
              required
              type='email'
            />
            <InputField
              className={styles.username}
              errorText={() => t('signup.username_input.error')}
              label={t('signup.username_input.label')}
              maxLength='60'
              minLength='5'
              name='userName'
              pattern='^[a-zA-Z0-9 #._-]*$'
              required
              type='text'
            />
            <InputField
              className={styles.password}
              errorText={() => t('signup.password_input.error')}
              label={t('signup.password_input.label')}
              minLength='5'
              name='password'
              ref={passwordRef}
              required
              type='password'
            />
            <InputField
              className={styles.passwordConfirmation}
              label={t('signup.passwordconfirmation_input.label')}
              minLength='5'
              name='passwordConfirmation'
              ref={passwordConfirmRef}
              required
              type='password'
            />
            <AddressCountryField
              className={styles.country}
              defaultValue={COUNTRIES.US}
              name='country'
              required
            />
            {(isCA || isUS) && (
              <CheckboxField
                className={styles.checkbox}
                defaultChecked={false}
                label={checkboxCanada || checkboxUSA}
                name='emailOptIn'
              />
            )}
            <div className={styles.actions}>
              <Button
                type='submit'
                className={styles.createAccountButton}
                data-tracking='create-account'
                disabled={hasFormErrors}
                isLoading={isSubmitting}
                variant='primary'
              >
                {t('signup.submit_button')}
              </Button>
            </div>
          </Form>
        </>
      )}
    </Screen>
  );
};

import React from 'react';
import {
  bool,
  element,
  instanceOf,
  node,
  oneOf,
  shape,
  string,
} from 'prop-types';
import cn from 'classnames';
import { Icon } from '@frx/frx-components';
import { Tooltip } from '../tool-tip/Tooltip';
import { colors } from '../../styles';

import styles from './Label.module.scss';

export const Label = ({
  children,
  className,
  error,
  hasTooltip,
  hintText,
  icon,
  isOptional,
  required = false,
  text,
  toolTipContent,
  ...rest
}) => (
  <div className={cn(styles.container, error && styles.error)}>
    {text && (
      <div className={styles.labelContainer}>
        {required && (
          <Icon color={colors.additional.errorRed} name='asterisk' size={8} />
        )}
        <label
          {...rest}
          {...{ className: cn(error && styles.error, className) }}
        >
          {text}
        </label>
        {icon && <Icon color={icon.color} name={icon.name} size={icon.size} />}
        {React.isValidElement(rest.overlay) && rest.overlay}
        {hasTooltip && (
          <>
            <Tooltip content={toolTipContent}>
              <Icon
                className={styles.toolTipAlign}
                color={colors.secondary.swatch4}
                name='info'
              />
            </Tooltip>
            &nbsp;
          </>
        )}
        {isOptional && (
          <span>
            <small>(optional)</small>
          </span>
        )}
      </div>
    )}
    {hintText && <p>{hintText}</p>}
    {children}
  </div>
);

Label.propTypes = {
  children: node,
  className: string,
  error: bool,
  hasTooltip: bool,
  hintText: string,
  icon: shape({
    ...instanceOf(Icon.propTypes).isRequired,
    placement: oneOf(['left', 'right']),
  }),
  isOptional: bool,
  overlay: element,
  required: bool,
  text: string,
  toolTipContent: string,
};

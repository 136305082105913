import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button, TabPanel } from '@frx/frx-components';
import { array, func, number, bool } from 'prop-types';
import cn from 'classnames';
import {
  convertCentsToDollars,
  useSortColumns,
  useQueryString,
  useConfiguration,
  getDonationUrl,
} from '../../utils';

import styles from './leaderboards.module.scss';
import { ColumnList, ListCell, ListRow } from '../column-list';
import { ROUTE_KEYS } from '../../app.constants';

export const TopParticipants = ({
  DropdownSelection,
  id,
  participants,
  acceptingDonationsVisible,
}) => {
  const { t } = useTranslation(['entry']);
  const { items: sortedParticipants, handleSort: handleParticipantSort } =
    useSortColumns(participants);
  const {
    queryString: { fr_id },
    redirect,
  } = useQueryString();
  const configuration = useConfiguration();
  const {
    appSearchCriteria: { pagename },
  } = configuration.settings;
  const type = 'allp';
  const handleSeeAllParticipants = () => {
    redirect(`/${ROUTE_KEYS.SEARCH}`, { pagename, fr_id, type });
  };

  return (
    <>
      <TabPanel id={id} className={cn(styles.topParticipantsList)}>
        <h1 className='title is-hidden-mobile'>
          {t('leaderboards.top_individuals')}
        </h1>
        <DropdownSelection />
        <ColumnList
          data={sortedParticipants || []}
          header={
            <ListRow
              className='is-hidden-mobile'
              variant='headerLeaderboards'
              // cellcolor='gray'
            >
              <ListCell onClick={() => handleParticipantSort('name')}>
                {t('leaderboards.participant_name')}
              </ListCell>
              <ListCell onClick={() => handleParticipantSort('total')}>
                {t('leaderboards.amount')}
              </ListCell>
              {acceptingDonationsVisible && (
                <ListCell>{t('leaderboards.donate')}</ListCell>
              )}
            </ListRow>
          }
          renderItem={(participant) => (
            <ListRow key={participant.consId || participant.id}>
              <ListCell>
                <Button
                  buttonColor='blue'
                  onClick={() => (location.href = participant.personalPageUrl)}
                  variant='text'
                >
                  {participant.participantFullName || participant.name}
                </Button>
              </ListCell>
              <ListCell>
                {participant.total ||
                  convertCentsToDollars(participant.amountRaised, {
                    formatted: true,
                    rounded: true,
                    trimZeros: true,
                  })}
              </ListCell>
              {acceptingDonationsVisible && (
                <ListCell>
                  <Button
                    buttonColor='red'
                    onClick={() =>
                      (location.href = getDonationUrl(participant.donationUrl))
                    }
                    variant='primary'
                  >
                    {t('leaderboards.donate')}
                  </Button>
                </ListCell>
              )}
            </ListRow>
          )}
        />
        {sortedParticipants && (
          <div className={styles.action}>
            <Button variant='secondary' onClick={handleSeeAllParticipants}>
              See all Participants
            </Button>
          </div>
        )}
      </TabPanel>
    </>
  );
};

TopParticipants.propTypes = {
  DropdownSelection: func,
  id: number,
  participants: array,
  acceptingDonationsVisible: bool,
};

// const partSearchInputLength = flag => flag ? 3 : 0;
// const hasFirstName = isEmpty(participantToSearch?.firstName);
// const hasLastName = isEmpty(participantToSearch?.lastName);
// <Form onSubmit={handlePartSearchSubmit} onChange={handlePartSearchChange}>
//   <InputField
//     defaultValue={participantToSearch?.firstName}
//     errorText={() => 'Must be atleast 3 characters'}
//     label='First Name'
//     minLength={partSearchInputLength(hasLastName)}
//     name='firstName'
//     required={hasLastName}
//     type='text'
//   />
//   <InputField
//     defaultValue={participantToSearch?.lastName}
//     errorText={() => 'Must be atleast 3 characters'}
//     label='Last Name'
//     minLength={partSearchInputLength(hasFirstName)}
//     name='lastName'
//     required={hasFirstName}
//     type='text'
//   />
//   <Button disabled={hasParticipantErrors} variant='primary'>Search</Button>
// </Form>

import React from 'react';
import { sortBy } from 'lodash';
import { bool } from 'prop-types';
import { useHistory } from 'react-router';
import { useTranslation } from 'react-i18next';
import { useMutation } from 'react-query';
import {
  DonationArray,
  ErrorMessage,
  Figure,
  Form,
  RegistrationProgress,
  Screen,
  StepHeader,
  Upsells,
} from '../../components';
import { ButtonsBar, InputField } from '../../components/forms';
import {
  convertToNumber,
  isEqual,
  useFetcher,
  useQueryString,
  useRegistrationDataProvider,
  useSubmitRegistration,
  useUpsells,
} from '../../utils';
import { optionsHeroTowel, optionsHeroHat, optionsHero } from '../../assets';
import { REG_TYPES, ROUTE_KEYS } from '../../app.constants';

import styles from './Options.module.scss';

export const Options = ({ showSidebar }) => {
  const [isFundraisingGoalInvalid, setIsFundraisingGoalInvalid] =
    React.useState(false);
  const [isOtherAmountInvalid, setIsOtherAmountInvalid] = React.useState(false);
  const [inValidDiscountError, setInvalidDiscountError] = React.useState();
  const [showErrorMsg, setShowErrorMsg] = React.useState();

  const { postStep } = useSubmitRegistration();

  const { t } = useTranslation(['options']);
  const {
    queryString: { fr_id },
    redirect,
  } = useQueryString();
  const {
    data: { selectedParticipationType, transactionId, bringTeamId },
  } = useRegistrationDataProvider();
  const {
    commitment,
    id,
    soldOut,
    upsellId: upsellIds,
    minimumGoal,
  } = selectedParticipationType;
  const { goBack } = useHistory();
  const {
    commands: {
      setAgeConfig,
      setDiscountAmount,
      setDiscountCode,
      setFundraisingGoal,
      setKickStartAmount,
      setKickStartFieldValue,
      setUpsells,
    },
    data: {
      discountCode,
      fundraisingGoal,
      kickStartAmount,
      kickStartFieldValue,
      upsells,
    },
  } = useRegistrationDataProvider();

  const fetcher = useFetcher();

  const { mutate: validateDiscountCode } = useMutation(
    'validDiscountCode',
    (options) => fetcher.get('CRTeamraiserAPI/validateDiscount', { ...options })
  );

  const otherKickStartAmountRef = React.useRef();

  const { data: upSellsData } = useUpsells(upsellIds);

  const donationAmounts = [
    '$60',
    '$125',
    '$250',
    '$500',
    `${t('other')}`,
    `${t('not_now')}`,
  ];
  const isGeneralCommitment = isEqual(commitment, REG_TYPES.GENERAL);
  const isSoldOut = soldOut;
  const handleDiscountCodeChange = (fields, hasErrors) => {
    if (!hasErrors) setDiscountCode(fields.discountCodeField);
  };

  const handleCloseArray = () => {
    setIsOtherAmountInvalid(false);
    setKickStartAmount();
    setKickStartFieldValue();
  };

  const handleSelectAmount = (e) => {
    if (e.type === 'click') {
      const selected = String(e.currentTarget.innerText).trim();
      console.log('form change', selected);
      if (selected === String(kickStartAmount).trim()) {
        setKickStartAmount();
      } else {
        setKickStartAmount(selected);
      }
    } else {
      console.log('x', e.kickStartAmountField);
      const selected =
        e.kickStartAmountField === null
          ? '$0'
          : `$ ${String(e.kickStartAmountField)}`;
      console.log('form change', selected);
      setKickStartAmount(selected);
    }
  };

  const handleOnFundraisingFormChange = (fields, hasErrors) => {
    setIsFundraisingGoalInvalid(hasErrors);
    const fgv = String(
      !fields.fundraisingGoalField ? 0 : fields.fundraisingGoalField
    );
    if (!hasErrors) setFundraisingGoal(fgv);
  };

  const handleUpsellClick = (e, upsellName, upsellPrice, upsellId) => {
    if (!e.target.checked) {
      setUpsells(upsells.filter((x) => x.id !== upsellId));
    } else {
      setUpsells([
        ...(upsells || []),
        { name: upsellName, price: upsellPrice, id: upsellId },
      ]);
    }
  };

  const handleKickStart = () => {
    const otherKickStartAmountFieldValue =
      otherKickStartAmountRef?.current?.value;
    if (otherKickStartAmountFieldValue)
      setKickStartFieldValue(`$${otherKickStartAmountFieldValue}`);

    if (isEqual(t('not_now'), kickStartAmount))
      setKickStartFieldValue(undefined);
  };

  const handlePost = async () => {
    const upsellId =
      upsells &&
      upsells.reduce((acc, cv) => {
        acc[`addons_component${cv.id}`] = cv.id;
        return acc;
      }, {});
    let donationalLevelFormData = {};
    if (kickStartAmount && !isEqual(kickStartAmount, `${t('other')}`)) {
      donationalLevelFormData = {
        [`donation_level_form_${fr_id}`]: isEqual(
          kickStartAmount,
          `${t('not_now')}`
        )
          ? '$0.00'
          : `$${convertToNumber(kickStartAmount).toFixed(2)}`,
      };
    } else if (otherKickStartAmountRef?.current?.value) {
      donationalLevelFormData = {
        [`donation_level_form_${fr_id}`]: '-1',
      };
    }
    return postStep(transactionId, {
      pg: 'ptype',
      fr_id,
      extra_person: '',
      company_id: '-1',
      fr_part_radio: id,
      [`discount_id_${id}`]: '',
      [`part_type_goal_${id}`]: minimumGoal,
      [`part_type_for_fundraising_${id}`]: 'T',
      discount_code: discountCode,
      fr_goal: !isGeneralCommitment ? minimumGoal : `$${fundraisingGoal}`,
      ...donationalLevelFormData,
      [`donation_level_form_input_${fr_id}`]: otherKickStartAmountRef?.current
        ?.value
        ? `${otherKickStartAmountRef?.current?.value}`
        : '',
      donation_level_custom_idsubmitted: 'true',
      donation_level_custom_id_donation_levels_submit: '',
      addons_componentsubmit: 'true',
      ...upsellId,
      ...(bringTeamId && { fr_team_captain: bringTeamId && 'on' }),
      fr_part_co_list: '',
      'next_step.x': 'Next Step',
      denySubmit: '',
    }).then((res) => {
      const errorMessage = new DOMParser()
        .parseFromString(res, 'text/html')
        .querySelectorAll('.field-error-text');
      if (errorMessage.length) {
        setShowErrorMsg([...errorMessage]);
      } else {
        handleKickStart();
        redirect(`${ROUTE_KEYS.PERSONAL}`);
      }
    });
  };

  const handleOnNext = async () => {
    if (discountCode.length) {
      validateDiscountCode(
        {
          fr_id,
          participation_id: id,
          discount_code: discountCode,
        },
        {
          onSuccess: (d) => {
            const discountFromRes = d?.validateDiscountResponse.isValid;
            if (discountFromRes === 'false') {
              scroll(0, 0);
              setInvalidDiscountError(d?.validateDiscountResponse.errorMessage);
            }
            if (discountFromRes === 'true') {
              setDiscountAmount(
                d?.validateDiscountResponse?.discount?.discountAmount
              );
              setDiscountCode(discountCode);
              handlePost();
            }
          },
        }
      );
    } else {
      await handlePost();
    }
  };

  const handlePrevStep = () => goBack();

  React.useEffect(() => {
    if (
      kickStartAmount &&
      ![t('not_now'), t('other')].includes(kickStartAmount)
    )
      setKickStartFieldValue();
    if (kickStartFieldValue) setKickStartAmount();
    if (!discountCode) setDiscountCode('');
    setDiscountAmount('0');
    if (isGeneralCommitment && !fundraisingGoal) setFundraisingGoal('250.00');
    setAgeConfig();
    // eslint-disable-next-line
  }, []);

  return (
    <Screen className={styles.container} showSidebar={showSidebar}>
      <StepHeader />
      <RegistrationProgress value={80} />
      <h2>{t('choose_options')}</h2>
      {showErrorMsg &&
        showErrorMsg.map((error) => (
          <ErrorMessage
            className={styles.spanred}
            errorText={error?.textContent}
          />
        ))}
      {inValidDiscountError && (
        <ErrorMessage errorText={inValidDiscountError} />
      )}
      <h2>{isSoldOut ? t('enter_entry_code') : t('enter_discount_code')}</h2>
      <Form onChange={handleDiscountCodeChange}>
        <InputField
          defaultValue={discountCode}
          errorText={() => t('disc_input_err')}
          name='discountCodeField'
          pattern='[0-9a-zA-Z_\u00C0-\u024F]+'
        />
      </Form>
      {isGeneralCommitment ? (
        <div>
          <p className='has-text-weight-bold'>{t('additional_don_gen_top')}</p>
          <div className={styles.optionsImageTowel}>
            <Figure src={optionsHeroTowel} alt='Options Hero Towel' />
          </div>
          <div className={styles.optionsImageHat}>
            <Figure src={optionsHeroHat} alt='Options Hero Hat' />
          </div>
          <Figure>{t('additional_don_gen_bottom')}</Figure>
        </div>
      ) : (
        <div>
          <p className='has-text-weight-bold'>
            {isGeneralCommitment ? t('become_hero_text') : t('as_hero_text')}
          </p>
          <Figure src={optionsHero} alt='Options Hero'>
            {/* {!isGeneralCommitment ? t('additional_don') : t('runners_fundraise')} */}
            {t('additional_don')}
          </Figure>
        </div>
      )}
      <div className={styles.text}>
        {isGeneralCommitment && (
          <Form onChange={handleOnFundraisingFormChange}>
            <h2>{t('fund_goal')}</h2>
            <InputField
              defaultValue={fundraisingGoal}
              errorText={(amount) => {
                if (amount.rangeUnderflow) return t('fund_goal_min');
                if (amount.rangeOverflow) return t('fund_goal_max');
                if (
                  amount.badInput ||
                  amount.patternMismatch ||
                  amount.stepMismatch
                )
                  return t('amount_format');
              }}
              iconProps={{ name: 'dollar-sign', placement: 'left' }}
              inputMode='decimal'
              max={999999.99}
              min={0}
              name='fundraisingGoalField'
              pattern='^((USD)?\$)?-?(?:\d+|\d{1,3}(?:,\d{3})*)(?:\.\d{1,2})?$'
              step='0.01'
              type='number'
            />
          </Form>
        )}
      </div>
      <DonationArray
        donationAmounts={donationAmounts}
        donationsContainerStyle={styles.donationsContainer}
        handleCloseArray={handleCloseArray}
        handleSelectAmount={handleSelectAmount}
        kickStartAmount={kickStartAmount || kickStartFieldValue}
        otherKickStartAmountRef={otherKickStartAmountRef}
        setIsOtherAmountInvalid={setIsOtherAmountInvalid}
      />
      <h2>{t('exclusive_merch')}</h2>
      {upSellsData && (
        <Upsells
          data={sortBy(upSellsData, 'order')}
          handleUpsellClick={handleUpsellClick}
        />
      )}
      <ButtonsBar
        handlePrimary={handleOnNext}
        disabled={
          (isSoldOut && !discountCode?.length) ||
          isOtherAmountInvalid ||
          isFundraisingGoalInvalid
        }
        handleSecondary={handlePrevStep}
      />
    </Screen>
  );
};

Options.propTypes = {
  showSidebar: bool,
};

import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  RegistrationProgress,
  Screen,
  StepButton,
  StepHeader,
} from '../../components';
import {
  isEqual,
  useAuth,
  useConfiguration,
  useQueryString,
  useRegistrationDataProvider,
  useSubmitRegistration,
} from '../../utils';
import { ROUTE_KEYS } from '../../app.constants';

import styles from './Teams.module.scss';

const Teams = ({ showSidebar }) => {
  const {
    settings: {
      luminate: {
        properties: {
          user: { prevTeamInfo },
        },
      },
    },
  } = useConfiguration();
  const { user } = useAuth();
  const { startTransaction, postStep } = useSubmitRegistration();
  const {
    commands: {
      setBringTeamId,
      setDiscountAmount,
      setDiscountCode,
      setJoinTeamId,
      setKickStartAmount,
      setKickStartFieldValue,
      setUpsells,
      setFundraisingGoal,
      setTransactionId,
    },
  } = useRegistrationDataProvider();

  const { t } = useTranslation(['teams']);

  const {
    redirect,
    queryString: { fr_id },
  } = useQueryString();

  const prevTeamFromStorage = user && user.prevTeamInfo;
  const previousTeamName =
    (prevTeamFromStorage && prevTeamFromStorage.teamName) ||
    prevTeamInfo?.teamName;
  const previousTeamId =
    (prevTeamFromStorage && prevTeamFromStorage.teamId) || prevTeamInfo?.teamId;
  const currentTeamFromStorage = user && user.currentTeamInfo;
  const currentTeamId =
    currentTeamFromStorage && currentTeamFromStorage.currentTeamId;
  const currentTeamName =
    currentTeamFromStorage && currentTeamFromStorage.currentTeamName;

  const handleOptions = async (team) =>
    await startTransaction(team)
      .then((res) => {
        /** TODO: this conditation is redudant. We need to wait until
         * figure out the best way for thank you page on login */
        if (res) {
          const pgValue = new URL(res.url).searchParams.get('pg');
          if (isEqual('rthanks', pgValue)) {
            return (window.location.href = `${res.url}`);
          }
          return res.text();
        }
      })
      .then((data) => {
        /** This is to get the uniqueid that generated from the post action */
        const urlFromString = new DOMParser()
          .parseFromString(data, 'text/html')
          .querySelector('form');
        const newUrl = urlFromString.action.split('/');
        const uniqId = newUrl[newUrl.length - 1];
        setTransactionId(uniqId);
        return uniqId;
      });

  const handleRunAsIndividual = (team) => async () =>
    await handleOptions(team).then((id) => {
      if (id) {
        redirect(ROUTE_KEYS.OPTIONS, { team });
      }
    });

  const handleTeamOptions = (team) => async () =>
    await handleOptions(team).then((id) => {
      if (id) {
        redirect(ROUTE_KEYS.TEAM_OPTIONS, { team });
      }
    });

  const handleReturningTeam = () =>
    handleOptions('returning-team').then((id) => {
      if (id) {
        postStep(id, {
          pg: 'tfind',
          fr_id,
          fr_treform: `${previousTeamId}`,
          skip_login_page: 'true',
        }).then(() => {
          setBringTeamId(previousTeamId);
          redirect(`/${ROUTE_KEYS.OPTIONS}`);
        });
      }
    });

  const handleRejoinTeam = () => {
    handleOptions('returning-team').then((id) => {
      if (id) {
        postStep(id, {
          pg: 'tfind',
          fr_id,
          fr_tjoin: `${currentTeamId}`,
          skip_login_page: 'true',
        }).then(() => {
          setJoinTeamId(currentTeamId);
          redirect(`/${ROUTE_KEYS.OPTIONS}`);
        });
      }
    });
  };

  React.useEffect(() => {
    setBringTeamId();
    setKickStartAmount();
    setKickStartFieldValue();
    setUpsells();
    setDiscountAmount();
    setDiscountCode();
    setFundraisingGoal();
  }, [
    setBringTeamId,
    setKickStartAmount,
    setKickStartFieldValue,
    setUpsells,
    setDiscountAmount,
    setDiscountCode,
    setFundraisingGoal,
  ]);

  return (
    <Screen className={styles.container} showSidebar={showSidebar}>
      <StepHeader />
      <RegistrationProgress value={60} />
      {previousTeamName && (
        <div className={styles.prevTeamsContainer}>
          {currentTeamName ? (
            <>
              <h2>{t('re_join_team_text')}</h2>
              <Button
                className={styles.existingTeamButton}
                variant='text'
                onClick={handleRejoinTeam}
                data-tracking='rejoin-team'
              >{`${currentTeamName}?`}</Button>
            </>
          ) : (
            <>
              <h2>{t('bring_back_team_text')}</h2>
              <Button
                className={styles.existingTeamButton}
                variant='text'
                onClick={handleReturningTeam}
                data-tracking='return-team'
              >{`${previousTeamName}?`}</Button>
            </>
          )}
        </div>
      )}
      <h2>{t('team_status')}:</h2>
      <div className={styles.buttons}>
        <StepButton
          title={t('run_as_individual')}
          subtitle={t('run_on_your_own')}
          data-tracking='individual-registration'
          onClick={handleRunAsIndividual('individual')}
        />
        <StepButton
          title={t('form_team')}
          subtitle={t('create_team_text')}
          data-tracking='form-team-registration'
          onClick={handleTeamOptions('create-team')}
        />
        <StepButton
          title={t('join_team')}
          subtitle={t('join_team_text')}
          data-tracking='join-team-registration'
          onClick={handleTeamOptions('join-team')}
        />
      </div>
    </Screen>
  );
};

export default Teams;

Teams.propTypes = {
  showSidebar: bool,
};

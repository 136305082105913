export const heroes_promocodes = [
  'Virtual-5K-Bronze',
  'Virtual-5K-Silver',
  'Virtual-5K-Gold',
  /* 'Virtual-5K-Platinum',
  'Virtual-5K-Platinum_Premier', */
  'Virtual-10K-Bronze',
  'Virtual-10K-Silver',
  'Virtual-10K-Gold',
  /* 'Virtual-10K-Platinum',
  'Virtual-10K-Platinum_Premier', */
  'Virtual-Half_Marathon-Bronze',
  'Virtual-Half_Marathon-Silver',
  'Virtual-Half_Marathon-Gold',
  /* 'Virtual-Half_Marathon-Platinum',
  'Virtual-Half_Marathon-Platinum_Premier', */
  'Virtual-Marathon-Bronze',
  'Virtual-Marathon-Silver',
  'Virtual-Marathon-Gold',
  /* 'Virtual-Marathon-Platinum',
  'Virtual-Marathon-Platinum_Premier', */
  'Virtual-2_race-Bronze',
  'Virtual-2_race-Silver',
  'Virtual-2_race-Gold',
  /* 'Virtual-2_race-Platinum',
  'Virtual-2_race-Platinum_Premier', */
  /* 'Virtual-4_race-Bronze',
  'Virtual-4_race-Silver',
  'Virtual-4_race-Gold',
  'Virtual-4_race-Platinum',
  'Virtual-4_race-Platinum_Premier', */
  'InPerson-5K-Bronze',
  'InPerson-5K-Silver',
  'InPerson-5K-Gold',
  'InPerson-5K-Platinum',
  'InPerson-5K-Platinum_Premier',
  'InPerson-10K-Bronze',
  'InPerson-10K-Silver',
  'InPerson-10K-Gold',
  'InPerson-10K-Platinum',
  'InPerson-10K-Platinum_Premier',
  'InPerson-Half_Marathon-Bronze',
  'InPerson-Half_Marathon-Silver',
  'InPerson-Half_Marathon-Gold',
  'InPerson-Half_Marathon-Platinum',
  'InPerson-Half_Marathon-Platinum_Premier',
  'InPerson-Marathon-Bronze',
  'InPerson-Marathon-Silver',
  'InPerson-Marathon-Gold',
  'InPerson-Marathon-Platinum',
  'InPerson-Marathon-Platinum_Premier',
  'InPerson-2_race-Bronze',
  'InPerson-2_race-Silver',
  'InPerson-2_race-Gold',
  'InPerson-2_race-Platinum',
  'InPerson-2_race-Platinum_Premier',
  /* 'InPerson-4_race-Bronze',
  'InPerson-4_race-Silver',
  'InPerson-4_race-Gold',
  'InPerson-4_race-Platinum',
  'InPerson-4_race-Platinum_Premier', */
];

export const gen_promocodes = [
  'Virtual-Marathon-Gen',
  'Virtual-Half_Marathon-Gen',
  'Virtual-10K-Gen',
  'Virtual-5K-Gen',
  'Virtual-2_Race-Gen',
  /* 'Virtual-4_Race-Gen', */
  'InPerson-Marathon-Gen',
  'InPerson-Half_Marathon-Gen',
  'InPerson-5K-Gen',
  'InPerson-10K-Gen',
  'InPerson-2_Race-Gen',
  /* 'InPerson-4_Race-Gen', */
];

import React from 'react';
import ReactQuill from 'react-quill';
import cn from 'classnames';
import {
  convertCentsToDollars,
  FundraisingProgressBar,
  Hero,
  OverflowText,
  PageTitle,
  SocialShare,
} from '@frx/frx-components';
import { isEmpty, isEqual } from 'lodash';
import {
  Button,
  ColumnList,
  EditModal,
  ErrorMessage,
  InputField,
  Label,
} from '../../../components';
import {
  heroMarathonParticipantSearch,
  heroMarathonCommunityChallenge,
  heroMarathonPatientFamily,
  heroMarathonCorporateChallenge,
} from '../../../assets';
import {
  useCompanyPageProvider,
  useConfiguration,
  useSortColumns,
  useQueryString,
} from '../../../utils';
import { QUILL_MODULES } from '../../../app.constants';

import 'react-quill/dist/quill.snow.css';
import styles from './Company.module.scss';
import { ListCell, ListRow } from '../../../components/column-list';

const renderError = ({ updateError }) =>
  updateError && <ErrorMessage {...{ errorText: updateError }} />;

export const Company = () => {
  /* hooks */
  const [richText, setRichText] = React.useState();

  const {
    queryString: { reg_type },
  } = useQueryString();

  /* data */
  const {
    commands: {
      handleGoalModal,
      handleGoalModalCancel,
      handleRichTextModal,
      handleRichTextModalCancel,
      handleTitleModal,
      handleTitleModalCancel,
      handleUpdateCompanyTitle,
      handleUpdateGoal,
      handleUpdateRichText,
    },
    data: {
      companyInfo,
      companyPageInfo,
      teamsByInfo,
      goalModal,
      errorMessage,
      richTextModal,
      titleModal,
    },
  } = useCompanyPageProvider();
  const {
    settings: {
      luminate: {
        properties: { user },
      },
    },
  } = useConfiguration();
  const { items: teams, handleSort } = useSortColumns(teamsByInfo || []);
  const goalRef = React.useRef();
  const titleRef = React.useRef();
  const ownPage =
    !isNaN(user?.consId) &&
    isEqual(Number(companyInfo?.coordinatorId), Number(user?.consId));

  React.useEffect(() => {
    if (!isEmpty(companyPageInfo?.richText))
      setRichText(companyPageInfo?.richText);
  }, [companyPageInfo]);

  /* Hero image selection */
  let companyHeroImage = heroMarathonParticipantSearch;
  // eslint-disable-next-line default-case
  switch (companyInfo?.companyName) {
    case 'Community Challenge':
      companyHeroImage = heroMarathonCommunityChallenge;
      break;
    case 'Corporate Challenge':
      companyHeroImage = heroMarathonCorporateChallenge;
      break;
    case 'Patient Family Teams':
      companyHeroImage = heroMarathonPatientFamily;
      break;
  }

  /* functions */
  const handleGoalUpdate = () => handleUpdateGoal(goalRef.current.value * 100);
  const handleTitleUpdate = () =>
    handleUpdateCompanyTitle(titleRef.current.value);
  const handleRichTextChange = (html) => setRichText(html);
  const handleStoryUpdate = () => handleUpdateRichText(richText);

  const handleRichTextCancel = () => {
    handleRichTextModalCancel();
    setRichText(companyPageInfo?.richText);
  };

  return (
    <main className='company-page'>
      <Hero
        background={companyHeroImage}
        backgroundPosition='bottom'
        className={styles.heroComponent}
        setHeight='50rem'
      />

      <section className={cn('section', styles.section)}>
        <div className='container'>
          <div className={cn(styles.componentContainer, styles.titleContainer)}>
            {companyInfo && (
              <div className={styles.title}>
                <PageTitle title={companyInfo.companyName} />
                {ownPage && (
                  <EditModal
                    handleModal={handleTitleModal}
                    onPrimaryAction={handleTitleUpdate}
                    onSecondaryAction={handleTitleModalCancel}
                    title='Title'
                    visible={titleModal}
                  >
                    {renderError({ updateError: errorMessage })}
                    <InputField
                      defaultValue={companyInfo.companyName}
                      errorText={() => 'Enter valid name'}
                      label='Company name'
                      ref={titleRef}
                      required
                      type='text'
                    />
                  </EditModal>
                )}
              </div>
            )}
            <SocialShare
              description='Help support the kids of St. Jude'
              title='Share'
            />
          </div>

          <div className={cn(styles.componentContainer, styles.statsContainer)}>
            <ul className={cn(styles.statsList)}>
              <li>
                {/* <span>logo</span> */}
                <span className={cn(styles.statNumber)}>
                  {companyInfo?.teamCount}
                </span>
                <span className={cn(styles.statLabel)}>Teams</span>
              </li>
              <li>
                {/* <span>logo</span> */}
                <span className={cn(styles.statNumber)}>
                  {companyInfo?.participantCount}
                </span>
                <span className={cn(styles.statLabel)}>Participants</span>
              </li>
            </ul>
          </div>

          {companyInfo && (
            <>
              <div
                className={cn(
                  styles.componentContainer,
                  styles.progressContainer
                )}
              >
                <FundraisingProgressBar
                  amountRaised={convertCentsToDollars(
                    companyInfo.amountRaised,
                    {
                      divider: true,
                      formatted: true,
                      trimZeros: true,
                    }
                  )}
                  goal={convertCentsToDollars(companyInfo.goal, {
                    divider: true,
                    formatted: true,
                    trimZeros: true,
                  })}
                  percentage={Math.min(
                    Math.floor(
                      companyInfo.goal !== 0
                        ? (Number(companyInfo.amountRaised) /
                            Number(companyInfo.goal)) *
                            100
                        : 100
                    ),
                    100
                  )}
                >
                  {ownPage && (
                    <EditModal
                      className={styles.editGoal}
                      handleModal={handleGoalModal}
                      onPrimaryAction={handleGoalUpdate}
                      onSecondaryAction={handleGoalModalCancel}
                      title='Goal'
                      visible={goalModal}
                    >
                      {renderError({ updateError: errorMessage })}
                      <InputField
                        defaultValue={convertCentsToDollars(companyInfo.goal)}
                        errorText={() => 'Enter valid number'}
                        iconProps={{ name: 'dollar-sign', placement: 'left' }}
                        label='Goal'
                        max={999999999}
                        minLength={1}
                        ref={goalRef}
                        required
                        type='number'
                      />
                    </EditModal>
                  )}
                </FundraisingProgressBar>
              </div>
            </>
          )}

          <div className={cn('box', styles.box, styles.componentContainer)}>
            <Label className={styles.label} text='Fundraising Leaderboards' />
            <div className={styles.tabPanelContainer}>
              <ColumnList
                data={teams}
                header={
                  <ListRow className='is-hidden-touch' variant='header'>
                    <ListCell onClick={() => handleSort('name')}>
                      Team Name
                    </ListCell>
                    <ListCell onClick={() => handleSort('eventName')}>
                      Event Name
                    </ListCell>
                    <ListCell onClick={() => handleSort('amountRaised')}>
                      <span className='is-hidden-desktop'>Raised: </span>Amount
                      Raised
                    </ListCell>
                    <ListCell onClick={() => handleSort('numMembers')}>
                      <span className='is-hidden-desktop'>Team Size: </span>Team
                      Size
                    </ListCell>
                  </ListRow>
                }
                renderItem={(team) => (
                  <ListRow className={cn(styles.teamRow)} key={team.id}>
                    <ListCell>
                      {reg_type !== undefined ? (
                        <Button
                          className={styles.teamNameColumn}
                          onClick={() => {
                            location.href = `${team.teamPageURL}&reg_type=${reg_type}`;
                          }}
                          variant='text'
                        >
                          {team.name}
                        </Button>
                      ) : (
                        <Button
                          className={styles.teamNameColumn}
                          onClick={() => {
                            location.href = `${team.teamPageURL}`;
                          }}
                          variant='text'
                        >
                          {team.name}
                        </Button>
                      )}
                    </ListCell>
                    <ListCell>{team.eventName}</ListCell>
                    <ListCell>
                      <span className='is-hidden-desktop has-text-weight-bold'>
                        Raised:{' '}
                      </span>
                      {convertCentsToDollars(team.amountRaised, {
                        formatted: true,
                        rounded: true,
                      })}
                    </ListCell>
                    <ListCell>
                      <span className='is-hidden-desktop has-text-weight-bold'>
                        Team Size:{' '}
                      </span>
                      {team.numMembers}
                    </ListCell>
                  </ListRow>
                )}
              />
            </div>
          </div>

          <div className={cn(styles.componentContainer, styles.storyContainer)}>
            {ownPage && (
              <EditModal
                className={styles.editStory}
                handleModal={handleRichTextModal}
                onPrimaryAction={handleStoryUpdate}
                onSecondaryAction={handleRichTextCancel}
                title='Story'
                visible={richTextModal}
              >
                {renderError({ updateError: errorMessage })}
                <ReactQuill
                  id='richText'
                  modules={QUILL_MODULES}
                  name='richText'
                  onChange={handleRichTextChange}
                  theme='snow'
                  value={richText}
                />
              </EditModal>
            )}

            {richText && <OverflowText content={richText} />}
          </div>
        </div>
      </section>
    </main>
  );
};

import React from 'react';
import { bool } from 'prop-types';
import { useTranslation } from 'react-i18next';
import {
  Button,
  DateInput,
  ErrorMessage,
  Form,
  InputField,
  Screen,
  StepHeader,
} from '../../components';
import { AddressField } from '../personal/questions';
import {
  getCreditCardExpirationOptions,
  getCurrentYear,
  getMonthShortName,
  isEmpty,
  isEqual,
  useAuth,
  useConfiguration,
  usePayment,
  useQueryString,
  useRegistrationDataProvider,
  useRegTotalToPay,
} from '../../utils';
import { colors } from '../../styles';
import {
  ERROR_MESSAGES,
  PAYMENT_TYPES,
  THANK_YOU_SURVEY_BASE_URL,
} from '../../app.constants';

import styles from './Payment.module.scss';

export const Payment = ({ showSidebar }) => {
  const [addressEdit, setAddressEdit] = React.useState(false);
  const [paymentType, setPaymentType] = React.useState(PAYMENT_TYPES.CREDIT);
  const [hasFormErrors, setHasFormErrors] = React.useState(true);
  const { t } = useTranslation(['payment_screen']);

  const {
    queryString: { fr_id, team },
  } = useQueryString();
  const {
    user: { firstName, lastName },
  } = useAuth();
  const {
    commands: { setPaymentFormData },
    data: {
      ageConfig,
      paymentFormData,
      personalFormData,
      selectedParticipationType,
      transactionId,
    },
  } = useRegistrationDataProvider();
  const { total } = useRegTotalToPay();
  const {
    settings: {
      luminate: { url },
    },
  } = useConfiguration();
  const { creditErrorMessage, handlePayment, isLoading } = usePayment();
  const hiddenFormInput = React.useRef();

  const isCreditType = isEqual(paymentType, PAYMENT_TYPES.CREDIT);
  const isPaypalType = isEqual(paymentType, PAYMENT_TYPES.PAYPAL);
  const { CREDIT_CARD_INVALID } = ERROR_MESSAGES;

  const handleOnFormChange = (fields, hasErrors) => {
    setPaymentFormData(fields);
    setHasFormErrors(hasErrors);
  };

  const handlePaymentType = (type) => () => setPaymentType(type);
  const handleOnEditOrCancel = () => setAddressEdit((prevState) => !prevState);

  const handlePaymentOnSubmit = () =>
    handlePayment({
      isCreditType,
      isPaypalType,
      paymentFormData,
      hiddenFormInput,
    });

  const {
    primary: { lightGrey },
    secondary: { swatch4 },
    additional: { white },
  } = colors;
  const iconInputCard = {
    name: 'credit-card',
    size: 32,
    placement: 'right',
    color: lightGrey,
  };

  const checkEmptyPaymentForm = (address) => {
    if (isEmpty(address)) return null;
    for (const key of Object.keys(address)) {
      if (isEmpty(address[key])) {
        address[key] = personalFormData[key];
      }
    }
    return address;
  };

  let billingInfo;

  if (personalFormData) {
    if (addressEdit) {
      billingInfo = (
        <>
          <div className={styles.billingName}>
            <InputField
              name='billingFirstName'
              label={t('payment_screen:first_name_label_text')}
              defaultValue={paymentFormData?.billingFirstName || firstName}
              required
            />
            <InputField
              name='billingLastName'
              label={t('payment_screen:last_name_label_text')}
              defaultValue={paymentFormData?.billingLastName || lastName}
              required
            />
          </div>
          <AddressField
            formData={
              checkEmptyPaymentForm(paymentFormData) ?? personalFormData
            }
          />
        </>
      );
    } else {
      billingInfo = (
        <ul>
          <li>
            {paymentFormData?.billingFirstName || firstName}{' '}
            {paymentFormData?.billingLastName || lastName}
          </li>
          <li>{paymentFormData?.address1 || personalFormData?.address1}</li>
          <li>{paymentFormData?.city || personalFormData.city}</li>
          <li>{paymentFormData?.state || personalFormData.state}</li>
          <li>{paymentFormData?.zip || personalFormData.zip}</li>
        </ul>
      );
    }
  }

  // build TY Survey Link
  const THANK_YOU_SURVEY_URL = `${THANK_YOU_SURVEY_BASE_URL}RegType=sjmmw&RaceType=${encodeURIComponent(
    selectedParticipationType?.distance
  )}&FundRaiseType=${encodeURIComponent(
    selectedParticipationType?.commitment
  )}&RegLevel=${team}&Fname=${encodeURIComponent(
    firstName
  )}&Lname=${encodeURIComponent(lastName)}&Age=${encodeURIComponent(
    ageConfig?.ageOnRaceDay
  )}&Gender=${encodeURIComponent(
    personalFormData?.gender
  )}&City=${encodeURIComponent(
    personalFormData?.city
  )}&State=${encodeURIComponent(
    personalFormData?.state
  )}&Zip=${encodeURIComponent(
    personalFormData?.zip
  )}&Phone=${encodeURIComponent(
    personalFormData?.homePhone.replace(/\s+/g, '')
  )}&TimesParticipated=${encodeURIComponent(
    personalFormData?.timesParticipatedSjmmw
  )}&Boston=${encodeURIComponent(
    personalFormData?.qkeyBoston
  )}&SJPatientFamily=${encodeURIComponent(personalFormData?.isPatientFam)}`;

  // save and/or updates the survey link inside session storage
  window.localStorage.setItem('THANK_YOU_SURVEY_URL', THANK_YOU_SURVEY_URL);

  console.log({ isLoading });

  return (
    <Screen className={styles.container} showSidebar={showSidebar}>
      <StepHeader />
      <section>
        <h3>Total:&emsp;${total}</h3>
        <div className={styles.creditButtonGroup}>
          <Button
            isfullWidth
            selected={isCreditType}
            onClick={handlePaymentType(PAYMENT_TYPES.CREDIT)}
            iconProps={{
              color: isCreditType ? white : swatch4,
              name: 'credit-card',
              position: 'top',
              size: 40,
            }}
          >
            {t('payment_screen:credit_card_text')}
          </Button>
          <Button
            isfullWidth
            selected={isPaypalType}
            iconProps={{
              color: isPaypalType ? white : swatch4,
              name: 'paypal',
              position: 'top',
              size: 40,
              viewBox: '0 0 576 512',
            }}
            onClick={handlePaymentType(PAYMENT_TYPES.PAYPAL)}
          >
            PayPal
          </Button>
        </div>
        {isPaypalType && (
          <form
            name='hiddenForm'
            action={`${url}/TRR/Heroes/Heroes/${transactionId}?pg=paymentForm&fr_id=${fr_id}`}
            ref={hiddenFormInput}
            method='POST'
          >
            <input
              name='responsive_payment_typepay_typeradio'
              type='hidden'
              value={PAYMENT_TYPES.PAYPAL}
            />
            <input
              name='responsive_payment_typepay_typeradiosubmit'
              type='hidden'
              value='true'
            />
            <input
              name='responsive_payment_typesubmit'
              type='hidden'
              value='true'
            />
            <input name='billing_title' type='hidden' value='' />
            <input name='billing_titlesubmit' type='hidden' value='true' />
            {personalFormData && paymentFormData && (
              <>
                <input
                  name='billing_first_namename'
                  defaultValue={firstName}
                  value={paymentFormData.firstName}
                  type='hidden'
                />
                <input
                  name='billing_first_namesubmit'
                  type='hidden'
                  value='true'
                />
                <input
                  name='billing_last_namename'
                  defaultValue={lastName}
                  value={personalFormData.lastName}
                  type='hidden'
                />
                <input
                  name='billing_last_namesubmit'
                  type='hidden'
                  value='true'
                />
                <input name='billing_suffix' type='hidden' value='' />
                <input name='billing_suffixsubmit' type='hidden' value='true' />
                <input
                  name='billing_addr_street1name'
                  defaultValue={personalFormData.address1}
                  type='hidden'
                />
                <input
                  name='billing_addr_street1submit'
                  type='hidden'
                  value='true'
                />
                <input
                  name='billing_addr_street2name'
                  defaultValue={personalFormData.address2}
                  type='hidden'
                />
                <input
                  name='billing_addr_street2submit'
                  type='hidden'
                  value='true'
                />
                <input
                  name='billing_addr_cityname'
                  defaultValue={personalFormData.city}
                  value={paymentFormData.city}
                  type='hidden'
                />
                <input
                  name='billing_addr_citysubmit'
                  type='hidden'
                  value='true'
                />
                <input
                  name='billing_addr_state'
                  defaultValue={personalFormData.state}
                  value={paymentFormData.state}
                  type='hidden'
                />
                <input
                  name='billing_addr_statesubmit'
                  type='hidden'
                  value='true'
                />
                <input
                  name='billing_addr_zipname'
                  defaultValue={personalFormData.zip}
                  value={paymentFormData.zip}
                  type='hidden'
                />
                <input
                  name='billing_addr_zipsubmit'
                  type='hidden'
                  value='true'
                />
                <input
                  name='billing_addr_country'
                  defaultValue={personalFormData.country}
                  value={paymentFormData.country}
                  type='hidden'
                />
                <input
                  name='billing_addr_countrysubmit'
                  type='hidden'
                  value='true'
                />
              </>
            )}
            <input name='btn_next' type='hidden' value='Process Payment' />
          </form>
        )}
        <Form onChange={handleOnFormChange}>
          {creditErrorMessage &&
            [creditErrorMessage, CREDIT_CARD_INVALID].map((err) => (
              <ErrorMessage key={err} errorText={err} />
            ))}
          {isCreditType && (
            <>
              <InputField
                errorText={() => t('payment_screen:credit_card_invalid_text')}
                className={styles.cardNumber}
                iconProps={iconInputCard}
                label={t('payment_screen:credit_card_number_text')}
                maxLength={16}
                minLength={15}
                name='cardNumber'
                required
              />
              <DateInput
                className={styles.expiration}
                columnConfig={getCreditCardExpirationOptions()}
                hasDayField={false}
                label={t('payment_screen:expiration_date_text')}
                month={getMonthShortName(new Date().getMonth() + 1)}
                required
                year={String(getCurrentYear())}
              />
              {/* TODO: CVV verbage needs to be updated */}
              <InputField
                className={styles.cvv}
                hasTooltip
                iconProps={iconInputCard}
                label='CVV'
                maxLength={4}
                minLength={3}
                name='cvvNumber'
                required
                toolTipContent={t('payment_screen:ccv_required_text')}
              />
            </>
          )}
          <div className={styles.billingInfo}>
            <h3>{t('payment_screen:billing_information_text')}</h3>
            {billingInfo}
            <Button
              className={styles.edit}
              onClick={handleOnEditOrCancel}
              variant='text'
            >
              {!addressEdit && t('payment_screen:edit_button_text')}
            </Button>
          </div>
          <Button
            className={styles.action}
            disabled={(hasFormErrors && !isPaypalType) || isLoading}
            isfullWidth
            isLoading={isLoading}
            onClick={handlePaymentOnSubmit}
            variant='primary'
          >
            {t('payment_screen:pay_button_text')} ${total}
          </Button>
        </Form>
      </section>
    </Screen>
  );
};

Payment.propTypes = {
  showSidebar: bool,
};

import { useLocation } from 'react-router';
import { useHistory } from 'react-router-dom';

import { useConfiguration } from './configuration/ConfigurationContext';

export const decodeQueryString = (search) =>
  search
    .replace(/^\?/, '')
    .split('&')
    .reduce((current, result) => {
      const [k, v] = result.split('=');
      return k ? { ...current, [k]: v } : current;
    }, {});

export const removeChars = (input, charsToRemove) =>
  charsToRemove.reduce((acc, char) => acc.split(char).join(''), input);

const remoteFileInclusionChars = ['../', 'file://', 'http://', 'https://'];

const sanitizeXssI = (input) => {
  const htmlEntities = {
    '<': '&lt;',
    '>': '&gt;',
    '&': '&amp;',
    '"': '&quot;',
    "'": '&#x27;',
    '`': '&#x60;',
    '=': '&#x3D;',
  };
  return input.replace(/[<>&"'`=]/g, (match) => htmlEntities[match]);
};

const sanitizeSqlI = (input) => input.replace(/['"\\;%]/g, '\\$&');

export const sanitize = (url) => {
  const urlParams = new URLSearchParams(url);
  const sanitizedParams = [...urlParams.entries()].reduce(
    (acc, [name, value]) => {
      const sanitizedValue = sanitizeSqlI(
        sanitizeXssI(removeChars(value, remoteFileInclusionChars))
      );
      acc.set(name, sanitizedValue);
      return acc;
    },
    new URLSearchParams()
  );
  return sanitizedParams.toString();
};
export const encodeAsQueryString = (values) =>
  Object.entries(values)
    .map(([key, value]) => (value ? `${key}=${value}` : ''))
    .join('&');

export const useQueryString = () => {
  const history = useHistory();
  const location = useLocation();
  const { settings } = useConfiguration();

  return {
    buildCleanPath: (pathname, searchCriteria = {}) => {
      const search = encodeAsQueryString({
        ...settings.appSearchCriteria,
        ...searchCriteria,
      });
      return { pathname, search };
    },
    buildPath: (pathname, newSearchCriteria = {}) => {
      const existingSearchCriteria = decodeQueryString(location.search);
      const search = encodeAsQueryString({
        ...existingSearchCriteria,
        ...newSearchCriteria,
      });
      return { pathname, search };
    },
    redirect: (pathname, searchCriteria = {}) => {
      // TODO: refactor this to use existing buildPath
      const existingSearchCriteria = decodeQueryString(location.search);
      const search = encodeAsQueryString({
        ...existingSearchCriteria,
        ...searchCriteria,
      });
      history.push(`${pathname}?${search}`);
    },
    queryString: decodeQueryString(sanitize(location.search)),
  };
};

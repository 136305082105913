import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';

import { useLocation } from 'react-router';
import styles from './Screen.module.scss';
import { Sidebar } from '..';

const Screen = ({ showSidebar, ...props }) => {
  const { pathname } = useLocation();

  useEffect(() => scroll(0, 0), [pathname]);

  return (
    <main className={cn(styles.container, props.className)}>
      {showSidebar && <Sidebar />}
      <article>{props.children}</article>
    </main>
  );
};

Screen.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  showSidebar: PropTypes.bool,
};

export default Screen;

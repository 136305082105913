import React from 'react';
import { bool, func, node, string } from 'prop-types';
import cn from 'classnames';
import { Modal } from '@frx/frx-components';
import { Button } from '../button/Button';
import { colors } from '../../styles';

import styles from './EditModal.module.scss';

export const EditModal = ({
  children,
  className,
  handleModal = () => undefined,
  onPrimaryAction = () => undefined,
  onSecondaryAction = () => undefined,
  title = '',
  visible = false,
}) => {
  const handleSave = () => onPrimaryAction();

  const handleCancel = () => onSecondaryAction();

  return (
    <>
      <Button
        className={cn(
          className,
          styles.editButton,
          'button',
          'is-small',
          'is-link',
          'is-rounded'
        )}
        iconProps={{
          color: colors.additional.white,
          name: 'edit-pencil',
          position: 'right',
          size: 12,
        }}
        onClick={handleModal}
        variant='primary'
      >
        Edit {title}
      </Button>
      <Modal
        actions={[
          <Button key={`${title} save`} onClick={handleSave} variant='primary'>
            Save
          </Button>,
          <Button key={`${title} cancel`} onClick={handleCancel}>
            Cancel
          </Button>,
        ]}
        onClosePress={handleCancel}
        title={`Edit ${title}`}
        visible={visible}
      >
        {children}
      </Modal>
    </>
  );
};

EditModal.propTypes = {
  children: node,
  className: string,
  handleModal: func,
  onPrimaryAction: func,
  onSecondaryAction: func,
  title: string,
  visible: bool,
};

import { LUMINATE_API_SECTIONS } from '../../app.constants';
import { loadSettings } from '../configuration/ConfigurationSettings';
import { createApiFetcher } from '../security/SecurityProvider';
import {
  convertCentsToDollars,
  convertToArray,
  getDonationUrl,
} from '../utils';

const settings = loadSettings(window.location);
const fetcher = createApiFetcher({ settings });
const { fr_id } = settings.appSearchCriteria;
const {
  url,
  properties: { formId },
} = settings.luminate;

const fetchTeamRaiserApi = async (methodName, params = {}) =>
  await fetcher(`${LUMINATE_API_SECTIONS.CRTEAMRAISERAPI}/${methodName}`, {
    ...params,
    fr_id,
    suppress_response_codes: true,
  });

/** queryFns */
export const getCompaniesByInfo = async (options = {}) =>
  await fetchTeamRaiserApi('getCompaniesByInfo', options);
export const getCompanyPageInfo = async (options = {}) =>
  await fetchTeamRaiserApi('getCompanyPageInfo', options);
export const getCompanyPhoto = async (options = {}) =>
  await fetchTeamRaiserApi('getCompanyPhoto', options);
export const getTeamsByInfo = async (options = {}) =>
  await fetchTeamRaiserApi('getTeamsByInfo', options).then((res) => {
    if (!res.code) return { ...res, team: convertToArray(res.team) };
    return res;
  });
export const getParticipants = async (options = {}) =>
  await fetchTeamRaiserApi('getParticipants', options).then((res) => {
    if (!res.code)
      return { ...res, participant: convertToArray(res.participant) };
    return res;
  });
export const getTopTeams = async (options = {}) =>
  await fetchTeamRaiserApi('getTopTeamsData', options).then((res) => {
    if (!res.code) {
      // if no value
      if (res.teamraiserData === undefined || res.teamraiserData === null) {
        return [];
      }
      // if one value
      if (res.teamraiserData !== Array) {
        return convertToArray(res.teamraiserData).slice(0, 5);
      }
      return res.teamraiserData.slice(0, 5);
    }
    return res;
  });
export const getTeamMembers = async (options = {}) =>
  await fetchTeamRaiserApi('getTeamMembers', options).then((res) => res);
export const getTopParticipants = async (options = {}) =>
  await fetchTeamRaiserApi('getTopParticipantsData', options).then((res) => {
    if (!res.code) {
      // if no value
      if (res.teamraiserData === undefined || res.teamraiserData === null) {
        return [];
      }
      // if one value
      if (res.teamraiserData !== Array) {
        return convertToArray(res.teamraiserData).slice(0, 5);
      }
      return res.teamraiserData.slice(0, 5);
    }
    return res;
  });
export const getEventInfo = async () =>
  await fetchTeamRaiserApi('getTeamraisersByInfo', {
    list_filter_column: 'fr_id',
    list_filter_text: fr_id,
    name: '%%%',
  }).then((res) => res);

export const getTeamRoster = async (options = {}) =>
  await fetchTeamRaiserApi('getTeamRoster', options).then((res) => {
    if (!res.code)
      return {
        ...res,
        teamMember: convertToArray(res.teamMember).map((member) => ({
          ...member,
          participantName: `${member.firstName} ${member.lastName}`,
          amountRaised: convertCentsToDollars(member.amount, {
            formatted: true,
            rounded: true,
          }),
          personalPageUrl: `${url}/TR/Heroes/SJMMW?px=${member.consId}&pg=personal&fr_id=${fr_id}`,
          donationUrl: getDonationUrl(
            `${url}/Donation2?df_id=${formId}&FR_ID=${fr_id}&PROXY_ID=${member.consId}&PROXY_TYPE=20`
          ),
        })),
      };
    return res;
  });

export const getTeamRaiserConfig = async () =>
  await fetchTeamRaiserApi('getTeamraiserConfig', {
    fr_id,
  });

/** mutationFns */
export const updateCompanyRichText = async (options = {}) =>
  await fetchTeamRaiserApi('updateCompanyPageInfo', options);

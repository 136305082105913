import React from 'react';

import { func, node, number, oneOfType, string } from 'prop-types';

import { AccordionContext } from './accordion-context';

const Accordions = ({ activeTab, children, onTabChange }) => (
  <AccordionContext.Provider value={{ activeTab, onTabChange }}>
    {children}
  </AccordionContext.Provider>
);

Accordions.propTypes = {
  activeTab: oneOfType([number, string]).isRequired,
  children: node,
  onTabChange: func,
};

Accordions.defaultProps = {
  onTabChange: () => undefined,
};

export default Accordions;

import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cn from 'classnames';
import { Icon } from '@frx/frx-components';
/* import { Button } from '../button/Button'; */
import { useLanguage } from './layout';
import {
  useAuth,
  useConfiguration,
  useQueryString,
  encodeAsQueryString,
  useRegistrationDataProvider,
  /* isEnglish, */
} from '../../utils';
import { MARATHON_HOME_PAGE_URL, ROUTE_KEYS } from '../../app.constants';

import { colors } from '../../styles';

import styles from './Header.module.scss';

const Header = () => {
  const { user } = useAuth();
  const {
    settings: {
      appSearchCriteria: { pagename },
      luminate: {
        properties: {
          user: { consId, registered, firstName, lastName /* , locale */ },
        },
      },
    },
  } = useConfiguration();

  const {
    data: { eventInfo },
  } = useRegistrationDataProvider();
  const { buildCleanPath, buildPath, queryString } = useQueryString();
  const { t } = useTranslation();
  const { fr_id, reg_type } = queryString;
  const {
    secondary: { swatch4 },
  } = colors;

  const [isMobileMenuVisible, setIsMobileMenuVisible] = useState(false);

  /* const { handleLogout, handleLanguage, isFCPage } = useLanguage(); */
  const { handleLogout } = useLanguage();
  const handleRoute = (pathname, search) => buildCleanPath(pathname, search);
  const handleMobileMenu = () =>
    setIsMobileMenuVisible((prevState) => !prevState);

  const mobileMenuClass = isMobileMenuVisible && 'is-active';

  return (
    <>
      <header className={styles.container}>
        <nav
          className='navbar is-spaced has-shadow'
          role='navigation'
          aria-label='main navigation'
        >
          <div className={cn('navbar-brand', styles.navbarBrand)}>
            <div className={cn('navbar-wrap', styles.navbarWrap)}>
              <a
                href={MARATHON_HOME_PAGE_URL}
                className={cn('navbar-item', styles.navbarItem)}
              >
                <div className={styles.sjLogo} />
              </a>
              <a
                href={MARATHON_HOME_PAGE_URL}
                className={cn('navbar-item', styles.navbarItem)}
              >
                <h1 className={styles.programName}>
                  <strong>St. Jude</strong> Memphis
                  <br />
                  Marathon Weekend
                </h1>
              </a>
            </div>

            <div
              className={cn(
                'navbar-wrap',
                'is-hidden-tablet',
                styles.navbarWrap
              )}
            >
              <button
                id='navbarBurger'
                onClick={handleMobileMenu}
                className={cn(
                  'navbar-button',
                  styles.navbarButton,
                  mobileMenuClass
                )}
              >
                <div className={styles.navbarMenuText}>
                  {isMobileMenuVisible ? 'Close' : 'Menu'}
                </div>
                <div
                  className={cn(
                    'navbar-burger',
                    styles.navbarBurger,
                    mobileMenuClass
                  )}
                >
                  <span aria-hidden='true' />
                  <span aria-hidden='true' />
                  <span aria-hidden='true' />
                </div>
              </button>
            </div>
          </div>

          <div
            id='navMenu'
            data-menu='main'
            className={cn('navbar-menu', styles.navbarMenu, mobileMenuClass)}
          >
            <div className={cn('navbar-end', styles.navbarEnd)}>
              {/* {!isFCPage() && (
                <Button
                  onClick={handleLanguage}
                  className={cn('navbar-item', styles.navbarItem)}
                >
                  {isEnglish(locale) ? 'Español' : 'English'}
                </Button>
              )} */}
              <Link
                to={() =>
                  handleRoute(`/${ROUTE_KEYS.SEARCH}`, {
                    pagename,
                    fr_id,
                    reg_type,
                  })
                }
                className={cn('navbar-item', styles.navbarItem)}
              >
                {t('header.find_participant_link_text')}
                <Icon
                  name='search'
                  color={swatch4}
                  className={styles.searchIcon}
                />
              </Link>

              {
                // if logged in and registered
                consId && registered ? (
                  <>
                    {eventInfo?.accepting_donations === 'true' && (
                      <a
                        className={cn('navbar-item', styles.navbarItem)}
                        href={`TRC?${encodeAsQueryString({
                          fr_id,
                          reg_type,
                          pg: 'center',
                        })}`}
                      >
                        {t('header.fundraising_center_link_text')}
                      </a>
                    )}
                    <a
                      className={cn('navbar-item', styles.navbarItem)}
                      href={`TR/Heroes/SJMMW?${encodeAsQueryString({
                        px: (user && user.consId) || consId,
                        fr_id,
                        reg_type,
                        pg: 'personal',
                      })}`}
                    >
                      {t('header.personal_page_link_text')}
                    </a>
                  </>
                ) : (
                  eventInfo?.accepting_registrations === 'true' && (
                    <Link
                      to={() =>
                        handleRoute(`/${ROUTE_KEYS.PARTICIPATION}`, {
                          pagename,
                          fr_id,
                          reg_type,
                        })
                      }
                      className={cn('navbar-item', styles.navbarItem)}
                    >
                      {t('header.register_link_text')}
                    </Link>
                  )
                )
              }

              <Link
                to={() =>
                  handleRoute(`/${ROUTE_KEYS.CONTACT}`, {
                    pagename,
                    fr_id,
                    reg_type,
                  })
                }
                className={cn('navbar-item', styles.navbarItem)}
              >
                {t('header.contact_us_link_text')}
              </Link>

              {(user || consId) && (
                <>
                  <button
                    className={cn('navbar-item', styles.navbarItem)}
                    onClick={handleLogout}
                  >
                    {t('header.log_out_link_text')}
                  </button>
                  <strong
                    className={cn(
                      'navbar-item',
                      styles.navbarItem,
                      styles.userItem
                    )}
                  >
                    {firstName || (user && user.firstName)}{' '}
                    {lastName || (user && user.lastName)}
                  </strong>
                </>
              )}

              {!user && !consId && (
                <Link
                  to={(location) =>
                    buildPath(`/${ROUTE_KEYS.LOGIN}`, {
                      pagename,
                      redirect: location.pathname,
                    })
                  }
                  className={cn('navbar-item', styles.navbarItem)}
                >
                  {t('header.log_in_link_text')}
                </Link>
              )}
            </div>
          </div>
        </nav>
      </header>
    </>
  );
};

export default Header;

import React from 'react';
import PropTypes from 'prop-types';
import cn from 'classnames';
import { Label } from '../label/Label';
import styles from './SelectField.module.scss';

const SelectField = (props) => {
  const { options, ...rest } = props;
  const [isError, setIsError] = React.useState(false);
  const [isTouched, setIsTouched] = React.useState(false);
  const [errorMessage, setErrorMessage] = React.useState('');

  const handleChange = (e) => {
    const input = e.currentTarget;
    const { onChange, errorText } = props;
    if (!isError && onChange) {
      onChange(input);
    }
    if (errorText && !input.validity.valid) {
      setErrorMessage(errorText(input.validity));
    }
    setIsError(!input.validity.valid);
  };

  const handleBlur = () => setIsTouched(true);

  return (
    <div className={cn(styles.container, props.className)}>
      <Label
        {...{
          error: isTouched && isError,
          htmlFor: props.name,
          isOptional: props.isOptional,
          overlay: props.overlay,
          required: props.required,
          text: props.label,
        }}
      >
        <div className={styles.selectContainer}>
          <select
            {...rest}
            className={cn(isTouched && isError && styles.error)}
            id={props.name}
            onBlur={handleBlur}
            onChange={handleChange}
            aria-label={props['aria-label']}
            value={props.value}
          >
            {props.placeholder && <option value=''>{props.placeholder}</option>}
            {options.map((option) => (
              <option value={option.value} key={option.value}>
                {option.label}
              </option>
            ))}
          </select>
          {isTouched && isError && (
            <small data-testid='errorMessage' htmlFor={props.name}>
              {errorMessage}
            </small>
          )}
        </div>
      </Label>
    </div>
  );
};
SelectField.propTypes = {
  ...PropTypes.instanceOf(HTMLInputElement).isRequired,
  isOptional: PropTypes.bool,
  label: PropTypes.string,
  options: PropTypes.array,
  transform: PropTypes.func,
};
export default SelectField;
